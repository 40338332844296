import React from 'react'
import { Formik } from 'formik'
import { gql, useQuery, useMutation } from '@apollo/client'

import Spin from '../../components/Spin'
import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

// CHANGE
const GET = gql`
  query ($id: ID!) {
    exam(id: $id) {
      id
      name
      modality
      specialty
      bodyPart
      examinationTree
      hasReconstruction
    }
  }
`

// CHANGE
const UPDATE = gql`
  mutation ($id: ID!, $name: String!, $modality: String, $specialty: String, $bodyPart: String, $examinationTree: String, $hasReconstruction: Boolean) {
    updateExam(id: $id, name: $name, modality: $modality, specialty: $specialty, bodyPart: $bodyPart, examinationTree: $examinationTree, hasReconstruction: $hasReconstruction) {
      id
      name
      modality
      specialty
      bodyPart
      examinationTree
      hasReconstruction
    }
  }
`

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  modality: yup.string().required(),
  specialty: yup.string().required(),
})

function Edit({ data: { exam }, onRequestClose }) {

  const { message } = useMessageContext()
  const [updateExam] = useMutation(UPDATE)

  const initialValues = {
    name: exam.name,
    modality: exam.modality,
    specialty: exam.specialty,
    bodyPart: exam.bodyPart ? exam.bodyPart : '',
    examinationTree: exam.examinationTree ? exam.examinationTree : '',
    hasReconstruction: !!exam.hasReconstruction,
  }

  const handleSubmit = async (variables, actions) => {
    try {
      await updateExam({ variables: { id: exam.id, ...variables } })
      message(`Exame alterado com sucesso!`)
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Editar Exame</SubTitle></Flex>
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
}

const EditQuery = ({ id, onRequestClose }) => {
  const { loading, error, data } = useQuery(GET, { variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  return <Edit data={data} onRequestClose={onRequestClose} />
}

export default EditQuery
