import React from 'react'
import styled, { css } from 'styled-components/macro'
import { width, height, lineHeight } from 'styled-system'
import EmptyList from './EmptyList'

const ArrowUpIcon = () => <svg viewBox='0 0 24 24' height={16} fill='currentColor'><path d='M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z' /></svg>
const ArrowDownIcon = () => <svg viewBox='0 0 24 24' height={16} fill='currentColor'><path d='M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z' /></svg>

export function SortLabel({ headerField, currentField, label, currentAscOrDesc, onChange }) {
  if (headerField === currentField) {
    return (
      <div css={css`display: inline-flex; align-items: center; cursor: pointer; text-decoration: underline; text-underline-position: under;`} onClick={() => onChange(`${headerField} ${currentAscOrDesc === 'asc' ? 'desc' : 'asc'}`)}>
        <div css={css`user-select: none;`}>{label}</div>
        <div css={css`display: inline-flex;`}>{(currentAscOrDesc.toLowerCase() === 'desc') ? (<ArrowUpIcon />) : (<ArrowDownIcon />)}</div>
      </div>
    )
  }
  return <div css={css`display: flex; cursor: pointer; display: inline-flex; text-decoration: underline; text-underline-position: under;`} onClick={() => onChange(`${headerField} asc`)}>{label}</div>
}

export function TableContainerComponent({ className, count, showTotal = false, children }) {

  if (count !== null && count === 0) return <EmptyList />

  return (
    <>
      {(showTotal && count !== null && count > 0) && <div style={{ marginBottom: 4 }}>{`Total: ${count}`}</div>}
      <div className={className}>
        {children}
      </div>
    </>
  )
}

export const TableContainer = styled(TableContainerComponent)`
  overflow-x: scroll;
  border: none;
  @media (min-width: 768px) {
    border: 1px solid ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
  }
`

export const Table = styled.div`
  display: block;
  &:last-child {
    border-bottom: none;
  }
  @media (min-width: 768px) {
    width: 100%;
    display: table;
    color: inherit;
  }
`

export const TableRow = styled.div`
  display: block;
  border-bottom: 1px solid ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
  padding-top: 16px;
  @media (min-width: 768px) {
    padding-top: 0;
    display: table-row;
    border-bottom: none;
    box-shadow: inset 0 -1px 0 0 ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
    ${props => props.active ? `background-color: ${props.theme && props.theme.colors && props.theme.colors.grey100 ? props.theme.colors.grey100 : 'hsla(0, 0%, 96%, 1)'};` : ''}
    /* &:hover {
      background-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey100 ? props.theme.colors.grey100 : 'hsla(0, 0%, 96%, 1)'};
    }
    &.active {
      background-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey100 ? props.theme.colors.grey100 : 'hsla(0, 0%, 96%, 1)'};
    } */
  }
`

export const TableHeaderRow = styled(TableRow)`
  border-bottom: none;
  @media (min-width: 768px) {
    font-weight: 500;
    color: inherit;
    background-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey100 ? props.theme.colors.grey100 : 'hsla(0, 0%, 96%, 1)'};
  }
`

export const TableCell = styled.div`
  display: block;
  margin-bottom: 10px;
  line-height: 20px;
  @media (max-width: 767px) {
    &:before {
      margin-bottom: 3px;
      content: attr(data-title);
      min-width: 98px;
      font-size: 10px;
      line-height: 1;
      font-weight: bold;
      text-transform: uppercase;
      color: ${props => props.theme && props.theme.colors && props.theme.colors.grey400};
      display: block;
    }
  }
  @media (min-width: 768px) {
    display: table-cell;
    vertical-align: middle;
    font-size: inherit;
    padding-right: 16px;
    &:first-child {
      height: 40px;
      padding-left: 16px;
    }
  }
  ${width}
  ${height}
  ${lineHeight}
`

export const TableHeaderCell = styled(TableCell)`
  margin-bottom: 0;
  @media (max-width: 767px) {
    display: none;
  }
`

export default Table
