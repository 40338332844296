import React from 'react'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { gql, useMutation } from '@apollo/client'

import { Box } from '../../components/FlexBox'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'
import { useAppContext } from '../../contexts/AppContext'

import Form from './Form'

const SIGN_IN = gql`
  mutation($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      me {
        id
        name
        email
        role
      }
    }
  }
`

const validationSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
})

function Login() {

  const history = useHistory()
  const { message } = useMessageContext()
  const { setItemState } = useAppContext()
  const [signIn] = useMutation(SIGN_IN)

  const initialValues = { email: '', password: '' }

  const handleSubmit = async (variables, { setSubmitting }) => {
    localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN)

    try {
      const result = await signIn({ variables })
      if (result.data.signIn.token) {
        localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, result.data.signIn.token)
        setItemState({ me: result.data.signIn.me })
        if (result.data.signIn.me.role && result.data.signIn.me.role === 'READONLY') {
          history.push(`/solicitacoes`)
        } else {
          history.push(`/estudos`)
        }
      }
    } catch(error) {
      setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  return (
    <Box pt={5}>
      <Box mx='auto' maxWidth={300}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>
      </Box>
    </Box>
  )
}

export default Login
