import React from 'react'
import { css } from 'styled-components/macro'
import { useApolloClient, gql, useSubscription, useLazyQuery } from '@apollo/client'
import { NavLink } from 'react-router-dom'

import AutoReload from '../components/AutoReload'
import { Flex, Box } from '../components/FlexBox'
import Speedometer from '../components/Speedometer'
import PopOver from '../components/PopOver'
import Tooltip from '../components/Tooltip'
import ButtonNavLink from '../components/ButtonNavLink'
import ButtonIcon from '../components/ButtonIcon'
import { Menu, MenuItem } from '../components/Menu'
import { ProfileIcon, ExpandMoreIcon, GenericIcon } from '../components/Icons'
import { useAppContext } from '../contexts/AppContext'

import { handleLogout } from '../index'

import WrapperLayout from './WrapperLayout'

const MainLayout = ({ children }) => {
  const client = useApolloClient()
  const { state } = useAppContext()

  const [getMyNotReadMessages, { loading, data, refetch }] = useLazyQuery(gql`query { myNotReadMessages { id } }`)

  React.useEffect(() => {
    getMyNotReadMessages()
  }, [getMyNotReadMessages])

  useSubscription(
    gql`subscription { messageCreated }`,
    {
      // variables: {},
      onSubscriptionData: ({ client, subscriptionData }) => {
        setTimeout(() => { refetch() }, 100)
      }
    }
  )

  return (
    <WrapperLayout>
      <AutoReload url='/index.html' delay={30000} />

      <Flex css={css`position: fixed; top: 0px; right: 0px; bottom: 0px; left: 0px; flex-direction: column;`}>
        <Box css={css`box-sizing: border-box; min-height: 48px; background-color: ${props => props.theme && props.theme.colors && props.theme.colors.primary ? props.theme.colors.primary : 'hsl(0 0% 43% / 1)'}; color: white; overflow: auto;`}>

          <Flex height='100%' justifyContent='space-between' alignItems='center'>

            <Flex flex={1} ml={2} alignItems='center'>
              <Box mr={3} css={css`position: relative; box-sizing: content-box; width: 40px; height: 40px; border-radius: 50%; overflow: hidden;`}>
                <img src='/bmk.png' alt='' draggable={false} css={css`width: 100%; height: 100%;`} />
              </Box>

              <Flex alignItems='center' flex={1}>
                {state && state.me && state.me.role && state.me.role !== 'READONLY' && <ButtonNavLink to='/estudos' tabIndex={-1}>Estudos</ButtonNavLink>}
                <ButtonNavLink to='/solicitacoes' tabIndex={-1}>Solicitações</ButtonNavLink>
                {state && state.me && state.me.role && state.me.role.includes('ADMIN') && <ButtonNavLink to='/exames' tabIndex={-1}>Exames</ButtonNavLink>}
                {state && state.me && state.me.role && state.me.role.includes('ADMIN') && <ButtonNavLink to='/unidades' tabIndex={-1}>Unidades</ButtonNavLink>}
                {state && state.me && state.me.role && state.me.role.includes('ADMIN') && <ButtonNavLink to='/usuarios' tabIndex={-1}>Usuários</ButtonNavLink>}
                {state && state.me && state.me.role && state.me.role.includes('ADMIN') && <ButtonNavLink to='/setores' tabIndex={-1}>Setores</ButtonNavLink>}
                {state && state.me && state.me.role && state.me.role.includes('ADMIN') && <ButtonNavLink to='/todas-mensagens' tabIndex={-1}>Todas mensagens</ButtonNavLink>}
                {/* {state && state.me && state.me.role && state.me.role.includes('ADMIN') && <ButtonNavLink to='/log' tabIndex={-1}>Log</ButtonNavLink>} */}

                {state && state.me && state.me.role && state.me.role.includes('ADMIN') && <Box><NavLink to='/minhas-mensagens'><ButtonIcon css={css`color: white;`} path='M12,3C17.5,3 22,6.58 22,11C22,15.42 17.5,19 12,19C10.76,19 9.57,18.82 8.47,18.5C5.55,21 2,21 2,21C4.33,18.67 4.7,17.1 4.75,16.5C3.05,15.07 2,13.13 2,11C2,6.58 6.5,3 12,3Z' /></NavLink></Box>}

                {state && state.me && state.me.role && state.me.role.includes('ADMIN') && <Box><NavLink to='/solicitacoes?msg=inbox'>{data && data.myNotReadMessages && data.myNotReadMessages.length}</NavLink></Box>}

                {/*state && state.me && state.me.role && state.me.role.includes('ADMIN') && <Box><NavLink to='/solicitacoes?msg=inbox'><ButtonIcon css={css`color: white;`} path='M19,15H15A3,3 0 0,1 12,18A3,3 0 0,1 9,15H5V5H19M19,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z' /></NavLink></Box>*/}
                {/*state && state.me && state.me.role && state.me.role.includes('ADMIN') && <Box><NavLink to='/solicitacoes?msg=outbox'><ButtonIcon css={css`color: white;`} path='M2,21L23,12L2,3V10L17,12L2,14V21Z' /></NavLink></Box>*/}

                {/*state && state.me && state.me.role && state.me.role.includes('ADMIN') && <Box ml={4}><Tooltip tooltip='Notificações'><NavLink to='/notificacoes'><ButtonIcon css={css`color: white;`} path='M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z' /></NavLink></Tooltip></Box>*/}

                {state && state.me && state.me.role && state.me.role.includes('ADMIN') &&
                  <PopOver withArrow={false} render={() => (
                    <Menu>
                      <MenuItem to='/log'>Log</MenuItem>
                      <MenuItem to='/importar-exportar'>Importar/Exportar</MenuItem>
                      <MenuItem to='/relatorios'>Relatórios</MenuItem>
                    </Menu>
                  )}>
                    <Flex cursor='pointer' ml={4}>
                      {state && state.me && state.me.avatar ? (
                        <Box css={css`user-select: none; position: relative; box-sizing: content-box; width: 32px; height: 32px; border-radius: 50%; overflow: hidden;`}>
                          <img src={state.me.avatar} alt='' draggable={false} css={css`width: 100%; height: 100%;`} />
                        </Box>
                      ) : (
                        <Flex css={css`user-select: none;`}><GenericIcon height={32} path='M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z' /></Flex>
                      )}
                      <Flex><ExpandMoreIcon /></Flex>
                    </Flex>
                  </PopOver>
                }

              </Flex>

            </Flex>

            <Flex alignItems='center'>

              <Box mr={2}>

                  <Flex alignItems='center'>
                    {state && state.me && state.me.name && <Box mr={2}>{state.me.name}</Box>}

                    <PopOver withArrow={false} render={() => (
                      <Menu>
                        <MenuItem onClick={() => handleLogout(client)}>Sair</MenuItem>
                      </Menu>
                    )}>
                      <Flex cursor='pointer'>
                        {state && state.me && state.me.avatar ? (
                          <Box css={css`user-select: none; position: relative; box-sizing: content-box; width: 32px; height: 32px; border-radius: 50%; overflow: hidden;`}>
                            <img src={state.me.avatar} alt='' draggable={false} css={css`width: 100%; height: 100%;`} />
                          </Box>
                        ) : (
                          <Flex css={css`user-select: none;`}><ProfileIcon height={32} /></Flex>
                        )}
                        <Flex><ExpandMoreIcon /></Flex>
                      </Flex>
                    </PopOver>

                  </Flex>

              </Box>

              <Flex mr={2}><Speedometer /></Flex>

              <Box mr={2} opacity={0.5} css={css`font-size: 12px;`}>v{process.env.REACT_APP_VERSION}</Box>

            </Flex>
          </Flex>


        </Box>
        <Box css={css`min-height: calc(100vh - 48px); width: 100%; overflow: auto;`}>
          {children}
        </Box>
      </Flex>
    </WrapperLayout>
  )
}

export default MainLayout
