import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LinkButton = ({ children, to, className, ...rest }) => (
  <Link to={to} className={className} {...rest}>
    {children}
  </Link>
)

const StyledLinkButton = styled(LinkButton)`
  align-items: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  font-family: 'Inter', Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  /* font-size: 0.875rem; */
  font-size: inherit;
  letter-spacing: 0.25px;
  background-color: ${props => props.theme && props.theme.colors && props.theme.colors.primary ? props.theme.colors.primary : 'hsl(0 0% 43% / 1)'};
  border-radius: 4px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  font-weight: 600;
  height: 36px;
  min-width: 80px;
  outline: none;
  padding: 0 16px;
  user-select: none;
  &::before {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: hsl(0 0% 100% / 0.2);
    border-radius: 4px;
    transform: scale(0);
    transition-property: transform, opacity;
  }
  &:hover::before {
    opacity: 1;
    outline: 1px solid transparent;
    transform: scale(1);
  }
  /* &:active::before {
    background: hsl(0 0% 100% / 0.5);
  } */
  &:focus {
    outline: 3px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
  }
`

export default StyledLinkButton
