import React from 'react'
import { Formik } from 'formik'
import { gql, useMutation } from '@apollo/client'

import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'

import NewPendencyForm from './NewPendencyForm'

// OrderId, description, text, by
const CREATE = gql`
  mutation ($OrderId: ID!, $description: String!, $text: String) {
    createPendency(OrderId: $OrderId, description: $description, text: $text)
  }
`

const validationSchema = yup.object().shape({
  description: yup.string().required(),
  text: yup.string().required(),
})

function NewPendency({ id, onRequestClose }) {

  const { message } = useMessageContext()
  const [createPendency] = useMutation(CREATE)

  const initialValues = { description: '', text: '' }

  const handleSubmit = async (variables, actions) => {
    console.log('variables', variables)
    try {
      await createPendency({ variables: { OrderId: id, ...variables } })
      message(`Pendência adicionada com sucesso!`)
      // actions.resetForm()
      onRequestClose()
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }

  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Nova Pendência</SubTitle></Flex>
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <NewPendencyForm {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
}

export default NewPendency
