import React from 'react'
import { Formik } from 'formik'
import { gql, useQuery, useMutation } from '@apollo/client'

import Spin from '../../components/Spin'
import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

const GET = gql`
  query ($id: ID!) {
    study(id: $id) {
      id
      PatientName
    }
  }
`

const UPDATE = gql`
  mutation ($id: ID!, $PatientName: String!) {
    updateStudy(id: $id, PatientName: $PatientName) {
      id
      PatientName
    }
  }
`

const validationSchema = yup.object().shape({
  PatientName: yup.string().required(),
})

function Edit({ data, onRequestClose }) {

  const { message } = useMessageContext()
  const [updateStudy] = useMutation(UPDATE)

  const initialValues = data.study

  const handleSubmit = async (variables, actions) => {
    try {
      await updateStudy({ variables: { id: data.study.id, ...variables } })
      message(`Estudo alterado com sucesso!`)
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Editar Estudo</SubTitle></Flex>
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
}

const EditQuery = ({ id, onRequestClose }) => {
  const { loading, error, data } = useQuery(GET, { variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  return <Edit data={data} onRequestClose={onRequestClose} />
}

export default EditQuery
