
import React, { useState, useEffect, forwardRef } from 'react'
import styled, { css } from 'styled-components/macro'

import FormErrorBox from './FormErrorBox'

const maskValue = (value = '') => {
  value = value.toString()
  value = value.replace(/\D/g, '')

  if (!value) return ''

  if (value.length === 1 && /\d/.test(value)) return `${value}`
  if (value.length === 2 && /\d{2}/.test(value)) return `${value}`
  if (value.length === 3 && /\d{3}/.test(value)) return `${value.substr(0, 2)}/${value.substr(2, 1)}`
  if (value.length === 4 && /\d{4}/.test(value)) return `${value.substr(0, 2)}/${value.substr(2, 2)}`
  if (value.length === 5 && /\d{5}/.test(value)) return `${value.substr(0, 2)}/${value.substr(2, 2)}/${value.substr(4, 1)}`
  if (value.length === 6 && /\d{6}/.test(value)) return `${value.substr(0, 2)}/${value.substr(2, 2)}/${value.substr(4, 2)}`
  if (value.length === 7 && /\d{7}/.test(value)) return `${value.substr(0, 2)}/${value.substr(2, 2)}/${value.substr(4, 3)}`
  if (value.length === 8 && /\d{8}/.test(value)) return `${value.substr(0, 2)}/${value.substr(2, 2)}/${value.substr(4, 4)}`

  return value
}

const unmaskValue = (maskedValue = '') => {
  if (!maskedValue) return ''
  let arrayDate = ['', '', '']
  if (maskedValue.length === 10) {
    arrayDate = maskedValue.split('/')
    return `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`
  } else {
    return ''
  }
}

const initialMaskedValue = ''

const InputDate = forwardRef(({ form, field, defaultValue, ...rest }, ref) => {

  const { value, onChange, ...restField } = field

// function InputDate({ onChange = () => {}, value, defaultValue, ...rest }) {

  const [maskedValue, setMaskedValue] = useState(initialMaskedValue)

  useEffect(
    () => {
      let param = value || defaultValue
      if (param && (param.length === 8 || param.length === 10)) {
        param = param.replace(/\D/g, '')
        param = `${param.substr(6, 2)}${param.substr(4, 2)}${param.substr(0, 4)}`
      }
      setMaskedValue(maskValue(param))
    },
    [defaultValue, value]
  )

  const handleChange = event => {
    const { target } = event
    const { value: inputValue = 0 } = target

    const value = unmaskValue(inputValue)
    const maskedValue = maskValue(inputValue)

    setMaskedValue(maskedValue)

    form.setFieldValue(field.name, value)
  }

  return (
    <>
      <input ref={ref} type='tel' maxLength={10} value={maskedValue} onChange={handleChange} {...restField} {...rest} />
      <FormErrorBox fieldName={field.name} errors={form.errors} touched={form.touched} />
    </>
  )

})

const StyledInputDate = styled(InputDate)`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  padding: 8px 12px;
  border: 1px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'hsl(0 0% 86% / 1)'};
  ${props => props.width && css`width: ${props.width};`}
  ${props => props.form.errors[props.field.name] && props.form.touched[props.field.name] && css`border-color: ${props.theme && props.theme.colors && props.theme.colors.error ? props.theme.colors.error : 'hsl(6 66% 47% / 1)'} !important;`}
  &:focus {
    outline: 3px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey100) ? props.theme.colors.grey100 : 'hsl(0 0% 96% / 1)'};
  }
`

export default StyledInputDate
