import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAppContext } from '../contexts/AppContext'
import WithMe from '../components/WithMe'
import ForceRedirect from '../components/ForceRedirect'

function PrivateRoute({ children, me = null, ...rest }) {

  const { setItemState, state } = useAppContext()

  React.useEffect(() => {
    if (me) setItemState({ me })
  }, [])

  return (
    <Route {...rest} render={
      ({ location }) => {
        if (me) return <ForceRedirect to={{ pathname: location.pathname }} />
        if (state.me) return children
        return <Redirect to={{ pathname: '/login', state: { from: location } }} />
      }
    } />
  )

}

function PrivateRouteWithToken({ children, ...rest }) {
  const tokenQueryString = (new URLSearchParams(window.location.search)).get('token')

  React.useEffect(() => {
    if (tokenQueryString) localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, tokenQueryString)
  }, [])

  if (tokenQueryString) return <WithMe>{({ data }) => <PrivateRoute {...rest} me={data.me}>{children}</PrivateRoute>}</WithMe>
  return <PrivateRoute {...rest}>{children}</PrivateRoute>
}

export default PrivateRouteWithToken
