import React from 'react'
import styled from 'styled-components'

const Button = ({ className, path }) => {
  return (
    <div role='button' tabIndex='1' className={className}>
      <svg viewBox='0 0 24 24' height={24} fill='currentColor'>
        <path d={path} />
      </svg>
    </div>
  )
}

const StyledButton = styled(Button)`
  align-items: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  font-family: 'Inter', Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  /* font-size: 0.875rem; */
  font-size: inherit;
  letter-spacing: 0.25px;
  background: none;
  border-radius: 4px;
  box-sizing: border-box;
  color: #5f6368;
  cursor: pointer;
  font-weight: 500;
  height: 36px;
  min-width: 24px;
  outline: none;
  padding: 0 8px;
  user-select: none;
  &::before {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: hsla(174, 100%, 25%, 1);
    border-radius: 4px;
    transform: scale(0);
    transition-property: transform, opacity;
  }
  &:hover::before {
    opacity: 0.8;
    outline: 1px solid transparent;
    transform: scale(1);
  }
`

export default StyledButton
