import React, { useState, useRef } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { GlobalHotKeys } from 'react-hotkeys'
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params'
import  { DateTime } from 'luxon'
import { gql, useQuery } from '@apollo/client'

import Spin from '../../components/Spin'
import Error from '../../components/Error'
import Modal from '../../components/Modal'
import { Flex, Box } from '../../components/FlexBox'
import Title from '../../components/Title'
import { today } from '../../utils'
import DateRangePicker from '../../components/DateRangePicker'
import Input from '../../components/Input'
import Select from '../../components/Select'
import useDebounce from '../../hooks/useDebounce'
import useIgnoreMount from '../../hooks/useIgnoreMount'
import { useAppContext } from '../../contexts/AppContext'

import List from './List'
import New from './New'
import Edit from './Edit'
import NewOrder from './NewOrder'

const BASE_URL = 'estudos'

const LIST_UNITS = gql`
  query {
    units {
      shortname
      name
    }
  }
`

function Studies({ data: { units } }) {
  const history = useHistory()

  const { state: { me } } = useAppContext()

  const newMatch = useRouteMatch(`/${BASE_URL}/novo`)
  const editMatch = useRouteMatch(`/${BASE_URL}/:id/editar`)
  const newOrderMatch = useRouteMatch(`/${BASE_URL}/nova-solicitacao/:ids`)

  const inputSearchRef = useRef(null)

  const [{ p: page, f: from, t: to, q, m: modality, u: shortname }, setQuery] = useQueryParams({ p: withDefault(NumberParam, 1), f: withDefault(StringParam, DateTime.fromJSDate(today()).toISODate()), t: withDefault(StringParam, DateTime.fromJSDate(today()).toISODate()), q: StringParam, m: StringParam, u: StringParam })

  const [search, setSearch] = useState(q || '')
  const debouncedName = useDebounce(search.trim(), 500)

  useIgnoreMount(debouncedName, newDebouncedName => {
    setQuery({ p: 1, q: newDebouncedName ? newDebouncedName : undefined })
  })

  const handleSearchKey = e => {
    e.preventDefault()
    inputSearchRef.current.focus()
  }

  const handleChangeRange = ({ from, to }) => {
    setQuery({ f: DateTime.fromJSDate(from).toISODate(), t: DateTime.fromJSDate(to).toISODate() })
  }

  return (
    <>
      <GlobalHotKeys keyMap={{ SEARCH: ['f'] }} handlers={{ SEARCH: handleSearchKey }} />
      <Box p={4}>

        <Flex mb={4} alignItems='center' justifyContent='space-between'>
          <Box>
            <Title>Estudos</Title>
          </Box>
        </Flex>

        <Box>
          <Flex>
            <Box width={200} mb={3} mr={3}>
              <DateRangePicker from={DateTime.fromISO(from).toJSDate()} to={DateTime.fromISO(to).toJSDate()} onChange={handleChangeRange} />
            </Box>
            <Box mr={3}>
              <Input width='300px' ref={inputSearchRef} type='search' placeholder='Buscar por paciente...' onChange={e => setSearch(e.target.value)} value={search} />
            </Box>
            <Box mr={3}>
              <Select placeholder='Mod.' onChange={e => setQuery({ m: e.target.value ? e.target.value : undefined })} value={modality}>
                <option value='CT'>TC</option>
                <option value='MR'>RM</option>
                <option value='MG'>MG</option>
              </Select>
            </Box>
            {me.role.includes('ADMIN') &&
              <Box>
                <Select placeholder='Unidade' onChange={e => setQuery({ u: e.target.value ? e.target.value : undefined })} value={shortname}>
                  {units.map(item => (<option key={item.shortname} value={item.shortname}>{item.name}</option>))}
                </Select>
              </Box>
            }
          </Flex>

          <List from={from} to={to} page={page} search={search} modality={modality} shortname={shortname} />
        </Box>

      </Box>

      <Modal shown={!!newMatch} onCancel={() => history.push(`/${BASE_URL}${window.location.search}`)}>
        {({ onRequestClose }) => (
          <New onRequestClose={onRequestClose} />
        )}
      </Modal>

      <Modal shown={!!editMatch} onCancel={() => history.push(`/${BASE_URL}${window.location.search}`)}>
        {({ onRequestClose }) => (
          <Edit id={editMatch && editMatch.params && editMatch.params.id} onRequestClose={onRequestClose} />
        )}
      </Modal>

      {!!newOrderMatch && <Modal width={1200} shown={!!newOrderMatch} onCancel={() => history.push(`/${BASE_URL}${window.location.search}`)}>
        {({ onRequestClose }) => (
          <NewOrder ids={newOrderMatch && newOrderMatch.params && newOrderMatch.params.ids} onRequestClose={onRequestClose} />
        )}
      </Modal>}

    </>
  )
}

const StudiesQuery = () => {
  const { loading, error, data } = useQuery(LIST_UNITS)
  if (loading) return <Spin />
  if (error) return <Error error={error} />
  return <Studies data={data} />
}

export default StudiesQuery
