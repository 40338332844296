import React, { useState } from 'react'
import { useCombobox } from 'downshift'
import styled, { css } from 'styled-components/macro'

import { Flex, Box } from '../../components/FlexBox'
import Avatar from '../../components/Avatar'
import useFocusOnLoad from '../../hooks/useFocusOnLoad'

const DownshiftMenu = styled(Box)`
  height: 279px;
  overflow-y: auto;
  width: 400px;
  margin: 0;
  list-style: none;
  padding: 0;
  border-radius: 0 0 8px 8px;
`

const DownshiftMenuItem = styled(Flex)`
  padding: 0 16px;
  align-items: center;
  cursor: pointer;
  min-height: 64px;
  ${props => props.selected && `& .avatar{ border: 5px solid hsl(0 0% 0% / 0.3); }`}
  ${props => props.highlighted && `background-color: hsla(180, 19%, 66%, 1);`}
`

// const menuCss = css`
//   height: 279px;
//   overflow-y: auto;
//   width: 400px;
//   margin: 0;
//   list-style: none;
//   padding: 0;
//   border-radius: 0 0 8px 8px;
// `

function Assign({ onSelect, items, selectedId }) {

  useFocusOnLoad('user', true)

  const [inputItems, setInputItems] = useState(items)

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    setHighlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    itemToString: item => item ? item.name : '',
    items: inputItems,
    initialIsOpen: true,
    isOpen: true,
    initialSelectedItem: selectedId ? { ...items.find(item => item.id === selectedId) } : null,
    initialInputValue: selectedId ? { ...items.find(item => item.id === selectedId) }['name'] : '',
    onSelectedItemChange: ({ selectedItem }) => {
      onSelect(selectedItem)
    },
    onInputValueChange: ({ inputValue }) => {
      setInputItems(items.filter(item => item.name.toLowerCase().startsWith(inputValue.toLowerCase())))
      setHighlightedIndex(0)
    },
  })

  React.useEffect(() => {
    if (selectedId) {
      setInputItems(inputItems.filter(item => item.name.toLowerCase().startsWith({ ...items.find(item => item.id === selectedId) }['name'].toLowerCase())))
      setHighlightedIndex(0)
    }
  }, [])

  return (
    <Box css={css`box-shadow: hsla(0, 0%, 0%, 0.31) 7px 7px 16px 0px; border-radius: 8px;`}>
      {/* <label {...getLabelProps()}>Choose an element:</label> */}

      <Flex css={css`border-radius: 8px 8px 0 0; justify-content: space-between; align-items: center; background: hsla(180, 19%, 66%, 1); border-bottom: 1px solid hsla(180, 19%, 56%, 1);`} {...getComboboxProps()}>
        <Flex css={css`padding-left: 8px;`}>
          <label {...getLabelProps()}><svg viewBox='0 0 24 24' width='24' height='24'><path fill='currentColor' d='M15.009 13.805h-.636l-.22-.219a5.184 5.184 0 0 0 1.256-3.386 5.207 5.207 0 1 0-5.207 5.208 5.183 5.183 0 0 0 3.385-1.255l.221.22v.635l4.004 3.999 1.194-1.195-3.997-4.007zm-4.808 0a3.605 3.605 0 1 1 0-7.21 3.605 3.605 0 0 1 0 7.21z' /></svg></label>
        </Flex>
        <Flex css={css`flex: 1;`}>
          <input {...getInputProps()} name='user' placeholder='Buscar médico' css={css`width: calc(100% - 23px); padding: 10px 12px; border: none; appearance: none; outline: none; background: transparent; font-size: 15px; color: inherit;`} />
        </Flex>
        {/* <Flex css={css`padding-right: 8px; cursor: pointer;`} onClick={() => onRequestClose()}>
          <svg viewBox='0 0 24 24' width='24' height='24'><path fill='currentColor' d='M17.25 7.8L16.2 6.75l-4.2 4.2-4.2-4.2L6.75 7.8l4.2 4.2-4.2 4.2 1.05 1.05 4.2-4.2 4.2 4.2 1.05-1.05-4.2-4.2 4.2-4.2z' /></svg>
          <button type='button' {...getToggleButtonProps()} aria-label='toggle menu'>&#8595;</button>
        </Flex> */}
      </Flex>
      <DownshiftMenu {...getMenuProps()} bg='grey200'>
        {isOpen &&
          inputItems.map((item, index) => (
            <DownshiftMenuItem highlighted={highlightedIndex === index} selected={item.id === selectedId}  key={`${item.id}${index}`} {...getItemProps({ item: item.name, index })}>
              <Flex css={css`width: 80px; justify-content: center;`}>

                <Avatar imageUrl={item.avatar} customCss={css`margin-right: 16px;`} />
                {/* <div className='avatar' css={css`position: relative; box-sizing: content-box; width: 48px; height: 48px; border-radius: 50%; overflow: hidden; margin-right: 16px;`}>
                  <img src={item.image} alt='' draggable='false' css={css`width: 100%; height: 100%;`} />
                </div> */}

                {/* <div css={css`position: relative; width: 48px; height: 48px; border-radius: 50%; overflow: hidden; margin-right: 16px;`}>
                  <div css={css`position: absolute; top: 0; z-index: 1; display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;`}>
                    <svg viewBox='0 0 212 212' width='212' height='212'><path fill="#DFE5E7" d='M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z' /><path fill='#FFF' d='M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z' /></svg>
                  </div>
                </div> */}
              </Flex>
              <Flex>{item.name}</Flex>
            </DownshiftMenuItem>
          ))}
      </DownshiftMenu>
    </Box>
  )
}

function AssignQuery({ doctors, onSelect, selectedId }) {
  return (
    <Assign items={doctors} onSelect={onSelect} selectedId={selectedId} />
  )
}

export default AssignQuery
