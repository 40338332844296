import React, { useState, useRef } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { GlobalHotKeys } from 'react-hotkeys'
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params'
import  { DateTime } from 'luxon'
import { gql, useQuery } from '@apollo/client'

import Spin from '../../components/Spin'
import Error from '../../components/Error'
import Modal from '../../components/Modal'
import { Flex, Box } from '../../components/FlexBox'
import Title from '../../components/Title'
import { today } from '../../utils'
import DateRangePicker from '../../components/DateRangePicker'
import Input from '../../components/Input'
import Select from '../../components/Select'
import useDebounce from '../../hooks/useDebounce'
import useIgnoreMount from '../../hooks/useIgnoreMount'
import { useAppContext } from '../../contexts/AppContext'

import List from './List'
import Edit from './Edit'
import Show from './Show'

const BASE_URL = 'solicitacoes'

const LIST_UNITS = gql`
  query {
    doctors {
      id
      name
      avatar
    }
    groups {
      id
      name
      avatar
    }
    units {
      id
      name
    }
  }
`

function Orders({ data: { units, doctors, groups } }) {

  const doctorsAndGroups = [
    ...groups.map(item => ({ id: `g${item.id}`, name: item.name, avatar: item.avatar, isGroup: true })),
    ...doctors,
  ]

  const history = useHistory()

  const { state: { me } } = useAppContext()

  const editMatch = useRouteMatch(`/${BASE_URL}/:id/editar`)
  const showMatch = useRouteMatch({ path: `/${BASE_URL}/:id`, exact: true })

  const inputSearchRef = useRef(null)

  const [{ p: page, f: from, t: to, q, o: order, m: modality, s: specialty, u: unit, pd: pendency, r: reconstruction, a: assignedTo, status, msg }, setQuery] = useQueryParams({ p: withDefault(NumberParam, 1), f: withDefault(StringParam, DateTime.fromJSDate(today()).toISODate()), t: withDefault(StringParam, DateTime.fromJSDate(today()).toISODate()), q: StringParam, o: StringParam, m: StringParam, s: StringParam, u: NumberParam, pd: StringParam, r: StringParam, a: StringParam, status: StringParam, msg: StringParam })

  const [search, setSearch] = useState(q || '')
  const debouncedName = useDebounce(search.trim(), 500)

  // React.useEffect(() => {
  //   if (msg) {
  //     setQuery({ p: undefined, f: undefined, t: undefined, m: undefined, s: undefined, u: undefined, pd: undefined, a: undefined, status: undefined })
  //   }
  // }, [msg])

  useIgnoreMount(debouncedName, newDebouncedName => {
    setQuery({ p: undefined, q: newDebouncedName ? newDebouncedName : undefined })
  })

  const handleSearchKey = e => {
    e.preventDefault()
    inputSearchRef.current.focus()
  }

  const handleChangeRange = ({ from, to }) => {
    setQuery({ p: undefined, f: DateTime.fromJSDate(from).toISODate(), t: DateTime.fromJSDate(to).toISODate() })
  }

  let title = 'Solicitações'
  if (msg === 'inbox') { title = 'Solicitações com mensagens recebidas' }
  if (msg === 'outbox') { title = 'Solicitações com mensagens enviadas' }

  return (
    <>
      <GlobalHotKeys keyMap={{ SEARCH: ['f'] }} handlers={{ SEARCH: handleSearchKey }} />
      <Box p={4} mb={6} minHeight='calc(100vh - 48px)'>

        <Flex mb={4} alignItems='center' justifyContent='space-between'>
          <Box>
            <Title>{title}</Title>
          </Box>
        </Flex>

        <Box>
          {!msg &&
            <Box>
              <Flex mb={3}>
                {me.role !== 'READONLY' && (
                  <Box width={200} mr={3}>
                    <DateRangePicker from={DateTime.fromISO(from).toJSDate()} to={DateTime.fromISO(to).toJSDate()} onChange={handleChangeRange} />
                  </Box>
                )}
                <Box mr={3}>
                  <Input width='300px' ref={inputSearchRef} type='search' placeholder='Buscar por paciente (em qualquer período)' onChange={e => setSearch(e.target.value)} value={search} />
                </Box>
                {me.role !== 'READONLY' && (
                  <>
                    <Box mr={3}>
                      <Select placeholder='Mod.' onChange={e => setQuery({ p: undefined, m: e.target.value ? e.target.value : undefined })} value={modality}>
                        <option value='TC'>TC</option>
                        <option value='RM'>RM</option>
                        <option value='MG'>MG</option>
                      </Select>
                    </Box>
                    <Box mr={3}>
                      <Select placeholder='Especialidade' onChange={e => setQuery({ p: undefined, s: e.target.value ? e.target.value : undefined })} value={specialty}>
                        <option value='CABEÇA E PESCOÇO'>CABEÇA E PESCOÇO</option>
                        <option value='CARDIOLOGIA'>CARDIOLOGIA</option>
                        <option value='COLUNA'>COLUNA</option>
                        <option value='MEDICINA INTERNA'>MEDICINA INTERNA</option>
                        <option value='MÚSCULO ESQUELÉTICO'>MÚSCULO ESQUELÉTICO</option>
                        <option value='NEURO'>NEURO</option>
                        <option value='RADIOLOGIA DENTÁRIA'>RADIOLOGIA DENTÁRIA</option>
                        <option value='SAÚDE DA MULHER'>	SAÚDE DA MULHER</option>
                        <option value='TÓRAX'>TÓRAX</option>
                        <option value='VASCULAR'>VASCULAR</option>
                      </Select>
                    </Box>
                  </>
                )}
                {me.role.includes('ADMIN') &&
                  <Box>
                    <Select placeholder='Unidade' onChange={e => setQuery({ p: undefined, u: e.target.value ? e.target.value : undefined })} value={unit}>
                      {units.map(item => (<option key={item.id} value={item.id}>{item.name}</option>))}
                    </Select>
                  </Box>
                }
              </Flex>
              <Flex mb={3}>
                {me.role !== 'READONLY' && (
                  <>
                    <Box mr={3}>
                      <Select placeholder='Assinados e não assinados.' onChange={e => setQuery({ p: undefined, status: e.target.value ? e.target.value : undefined })} value={status}>
                        <option value='not-signed'>Não assinados</option>
                        <option value='signed'>Assinados (neste período)</option>
                      </Select>
                    </Box>
                    <Box mr={3}>
                      <Select placeholder='Pendentes e não pendentes' onChange={e => setQuery({ p: undefined, pd: e.target.value ? e.target.value : undefined })} value={pendency}>
                        <option value='p'>Pendentes</option>
                        <option value='np'>Não pendentes</option>
                        <option value='cp'>Com pendências (inclusive encerradas)</option>
                        <option value='pe'>Pendências encerradas</option>
                      </Select>
                    </Box>
                    <Box mr={3}>
                      <Select placeholder='Reconstrução' onChange={e => setQuery({ p: undefined, r: e.target.value ? e.target.value : undefined })} value={pendency}>
                        <option value='ar'>A reconstruir e reconstruídos</option>
                        <option value='nr'>A reconstruir</option>
                        <option value='r'>Reconstruído</option>
                      </Select>
                    </Box>
                  </>
                )}
                {me.role.includes('ADMIN') &&
                  <Box>
                    <Select placeholder='Atribuídas a...' onChange={e => setQuery({ p: undefined, a: e.target.value ? e.target.value : undefined })} value={assignedTo}>
                      <option value={0}>Sem atribuição</option>
                      {doctorsAndGroups.map(item => (<option key={item.id} value={item.id}>{item.name}</option>))}
                    </Select>
                  </Box>
                }
              </Flex>
            </Box>
          }
          <List doctors={doctorsAndGroups} messages={msg} from={from} to={to} page={page} search={search} orderBy={order} modality={modality} specialty={specialty} unit={unit} pendency={pendency} reconstruction={reconstruction} status={status} assignedTo={assignedTo} />
        </Box>

      </Box>

      <Modal shown={!!showMatch} onCancel={() => history.push(`/${BASE_URL}${window.location.search}`)}>
        {({ onRequestClose }) => (
          <Show id={showMatch && showMatch.params && showMatch.params.id} onRequestClose={onRequestClose} />
        )}
      </Modal>

      <Modal shown={!!editMatch} onCancel={() => history.push(`/${BASE_URL}${window.location.search}`)}>
        {({ onRequestClose }) => (
          <Edit id={editMatch && editMatch.params && editMatch.params.id} onRequestClose={onRequestClose} />
        )}
      </Modal>

    </>
  )
}

const OrdersQuery = () => {
  const { loading, error, data } = useQuery(LIST_UNITS)
  if (loading) return <Spin />
  if (error) return <Error error={error} />
  return <Orders data={data} />
}

export default OrdersQuery
