import React from 'react'
import  { DateTime } from 'luxon'
import { gql, useApolloClient, useQuery } from '@apollo/client'
import { Flex, Box } from '../../components/FlexBox'
import Button from '../../components/ButtonWithLoading'
import Spin from '../../components/Spin'
import Error from '../../components/Error'
import Title from '../../components/Title'
import DateRangePicker from '../../components/DateRangePicker'
import Select from '../../components/Select'
import { today } from '../../utils'

const BASE_API = process.env.REACT_APP_BASE_API

function Reports({ data: { units } }) {
  const client = useApolloClient()

  const [from, setFrom] = React.useState(DateTime.fromJSDate(today()).toISODate())
  const [to, setTo] = React.useState(DateTime.fromJSDate(today()).toISODate())
  const [unit, setUnit] = React.useState('')
  const [dateField, setDateField] = React.useState('createdAt')

  const [loading, setLoading] = React.useState(false)

  const handleDownload = async () => {
    setLoading(true)
    const response = await client.query({ query: gql`query($from: Date, $to: Date, $unit: ID, $dateField: String) { reportExcel(from: $from, to: $to, unit: $unit, dateField: $dateField) }`, fetchPolicy: 'network-only', variables: { from, to, unit, dateField }})
    const file = response.data.reportExcel
    setLoading(false)
    window.location.href = `${BASE_API}/${file}`
  }

  const handleChangeRange = ({ from, to }) => {
    setFrom(DateTime.fromJSDate(from).toISODate())
    setTo(DateTime.fromJSDate(to).toISODate())
  }

  return (
    <>
      <Box p={4}>

        <Flex mb={4} alignItems='center' justifyContent='space-between'>
          <Box>
            <Title>Relatórios</Title>
          </Box>
        </Flex>

        <Flex mb={3}>

          <Box width={200} mr={3}>
            <DateRangePicker from={DateTime.fromISO(from).toJSDate()} to={DateTime.fromISO(to).toJSDate()} onChange={handleChangeRange} />
          </Box>

          <Box mr={3}>
            <Select onChange={e => { setDateField(e.target.value) }} value={dateField}>
              <option value='createdAt'>Por Data da Solicitação</option>
              <option value='examinedAt'>PorData da Realização do exame</option>
              <option value='signedAt'>Por Data da Assinatura</option>
              <option value='availableAt'>Por Data da Liberação</option>
            </Select>
          </Box>

          <Box mr={3}>
            <Select placeholder='Unidade' onChange={e => { setUnit(e.target.value) }} value={unit}>
              {units.map(item => (<option key={item.id} value={item.id}>{item.name}</option>))}
            </Select>
          </Box>

        </Flex>

        <Box mb={3}>
          <Button loading={loading} onClick={handleDownload}>Download Excel</Button>
        </Box>

      </Box>
    </>
  )
}

const ReportsQuery = () => {
  const { loading, error, data } = useQuery(gql`{ units { id, name } }`)
  if (loading) return <Spin />
  if (error) return <Error error={error} />
  return <Reports data={data} />
}

export default ReportsQuery
