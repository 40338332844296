import React from 'react'

import { gql, useApolloClient } from '@apollo/client'

import { Flex, Box } from '../../components/FlexBox'
import Button from '../../components/ButtonWithLoading'
import Title from '../../components/Title'

const BASE_API = process.env.REACT_APP_BASE_API

function ImportExport() {
  const client = useApolloClient()

  const [exportPortfolioLoading, setExportPortfolioLoading] = React.useState(false)
  const [exportPortfolioUnitLoading, setExportPortfolioUnitLoading] = React.useState(false)

  const handleExportPortfolio = async () => {
    setExportPortfolioLoading(true)
    const response = await client.query({ query: gql`{ portfoliosExcel }`, fetchPolicy: 'network-only', variables: {  }})
    const file = response.data.portfoliosExcel
    console.log('file', file)
    setExportPortfolioLoading(false)
    window.location.href = `${BASE_API}/${file}`
  }

  const handleExportPortfolioUnit = async () => {
    setExportPortfolioUnitLoading(true)
    const response = await client.query({ query: gql`{ portfoliosUnitsExcel }`, fetchPolicy: 'network-only', variables: {  }})
    const file = response.data.portfoliosUnitsExcel
    setExportPortfolioUnitLoading(false)
    window.location.href = `${BASE_API}/${file}`
  }

  return (
    <>
      <Box p={4}>

        <Flex mb={4} alignItems='center' justifyContent='space-between'>
          <Box>
            <Title>Importar / Exportar</Title>
          </Box>
        </Flex>

        <Box mb={3}>
          <Button loading={exportPortfolioLoading} onClick={handleExportPortfolio}>Exportar Portfólio</Button>
        </Box>

        <Box mb={3}>
          <Button loading={exportPortfolioUnitLoading} onClick={handleExportPortfolioUnit}>Exportar Portfólio por Unidade</Button>
        </Box>

      </Box>
    </>
  )
}

export default ImportExport
