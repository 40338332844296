import * as yup from 'yup'

yup.setLocale({
  mixed: {
    default: 'Campo inválido',
    required: 'Campo obrigatório',
  },
  string: {
    email: 'Preencha um e-mail válido',
    min: params => `Deve ter pelo menos ${params.min} caracteres`,
    max: params => `Deve ter no máximo ${params.max} caracteres`,
  },
  number: {
    min: params => `Valor inválido (deve ser maior ou igual a ${params.min})`,
    max: params => `Valor inválido (deve ser menor ou igual a ${params.max})`,
    integer: 'O campo deve ser inteiro'
  }
})

export default yup
