import { age } from '../utils'

function FormatAge({ children, format = 'YYYY-MM-DD' }) {
  if (children && children !== '') {
    return age(children, format)
  }
  return ''
}

export default FormatAge
