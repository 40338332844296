import EXIF from 'exif-js'
const moment = require('moment')

export function age(date, format = 'YYYY-MM-DD') {
  const birthdate = moment(date, format, true)
  const now = moment()
  if (now.diff(birthdate, 'days') < 0) return ''
  let years = now.diff(birthdate, 'years')
  birthdate.add(years, 'years')
  let months = now.diff(birthdate, 'months')
  birthdate.add(months, 'months')
  let days = now.diff(birthdate, 'days')
  let result = null
  if (!years) years = 0
  if (!months) months = 0
  if (!days) days = 0
  if (!result && years === 0 && months === 0 && days === 0) result = ''
  if (!result && years === 0 && months === 0) result = `${days}D`
  if (!result && years >= 3 && years <= 12) result = `${years}A ${months}M`
  if (!result && years > 12) result = `${years}A`
  if (!result && years === 0) result = `${months}M ${days}D`
  if (!result && years < 3) result = `${years}A ${months}M ${days}D`
  return (result || '')
}

export function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max)
}

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export function fuzzySearchFor(list, term, field) {
  if (!term) return list
  const cleanFilter = cleanUpText(term)
  return list
    .filter(item => item[field] != null)
    .map(item => ({ item: item, score: typeaheadSimilarity(cleanUpText(item[field]), cleanFilter) }))
    .filter(pair => pair.score >= cleanFilter.length)
    .sort((a, b) => b.score - a.score)
    .map(pair => pair.item)
}

export function searchFor(list, term, field) {
  if (!term) return list
  let search = term.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^A-Za-z0-9\s]/g, '')
  if (search) return list.filter(item => item[field].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(search))
  return list
}

export function orderBy(items, field, ascOrDesc, type = 'string') {
  if (type.toLowerCase() === 'string') {
    if (ascOrDesc.toLowerCase() === 'asc') {
      // return [...items].sort((a, b) => (a[field] && b[field]) ? a[field].localeCompare(b[field]) : 1)
      return [...items].sort((a, b) => (a[field] || '').localeCompare((b[field] || '')))
    }
    if (ascOrDesc.toLowerCase() === 'desc') {
      // return [...items].sort((a, b) => (a[field] && b[field]) ? b[field].localeCompare(a[field]) : 1)
      return [...items].sort((a, b) => (b[field] || '').localeCompare((a[field] || '')))
    }
  } else if (type.toLowerCase() === 'date') {
    if (ascOrDesc.toLowerCase() === 'asc') {
      return [...items].sort((a, b) => (a[field] && b[field]) ? (new Date(a[field]) - new Date(b[field])) : 1)
    }
    if (ascOrDesc.toLowerCase() === 'desc') {
      return [...items].sort((a, b) => (a[field] && b[field]) ? (new Date(b[field]) - new Date(a[field])) : 1)
    }
  }
  return items
}

export function today() {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return today
}

export function resizeMe(img, exif, maxWidth, maxHeight, quality) {

  const canvas = document.createElement('canvas')

  let width = img.width
  let height = img.height

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round(height *= maxWidth / width)
      width = maxWidth
    }
  } else {
    if (height > maxHeight) {
      width = Math.round(width *= maxHeight / height)
      height = maxHeight
    }
  }

  // resize the canvas and draw the image data into it
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0, width, height)

  switch(exif.Orientation) {
    case 8:
      canvas.width = height
      canvas.height = width
      ctx.setTransform(0, -1, 1, 0, 0, height)
      ctx.drawImage(img, 0, 0, height, width)
      break
    case 6:
      canvas.width = height
      canvas.height = width
      ctx.setTransform(0, 1, -1, 0, height, 0)
      ctx.drawImage(img, 0, 0, width, height)
      break
    default:
      canvas.width = width
      canvas.height = height
      ctx.drawImage(img, 0, 0, width, height)
      break
  }

  // preview.appendChild(canvas) // do the actual resized preview

  return canvas.toDataURL('image/jpeg', quality) // get the data from canvas as 70% JPG (can be also PNG, etc.)
}

export function blobToBase64(blob) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })
}

export function fileBase64(file) {
  return new Promise(resolve => {
    const reader = new FileReader()

    reader.onload = async e => {
      const blob = new Blob([e.target.result])

      // const buffer = await blob.arrayBuffer()
      // window.URL = window.URL || window.webkitURL
      // const blobURL = window.URL.createObjectURL(blob)
      const base64 = await blobToBase64(blob)
      // resolve(blobURL)
      resolve(base64)
    }

    reader.readAsArrayBuffer(file)
  })
}

export function resizedImageBase64(file, { maxWidth = 200, maxHeight = 200, quality = 0.7 }) {
  return new Promise(resolve => {
    const reader = new FileReader()

    reader.onload = async e => {
      const blob = new Blob([e.target.result])

      const buffer = await blob.arrayBuffer()
      const exif = EXIF.readFromBinaryFile(buffer)

      window.URL = window.URL || window.webkitURL
      const blobURL = window.URL.createObjectURL(blob)
      const image = new Image()
      image.src = blobURL
      image.onload = async () => {
        const resized = resizeMe(image, exif, maxWidth, maxHeight, quality)
        resolve(resized)
      }
    }

    reader.readAsArrayBuffer(file)
  })
}

export async function asyncReduce(arr, fn, val, pure) {
  for (let i = 0; i < arr.length; i++) {
    let v = await fn(val, arr[i], i, arr)
    if (pure !== false) val = v
  }
  return val
}

export function humanFileSize(bytes, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024
  if (Math.abs(bytes) < thresh) return bytes + ' B'
  const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10**dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return `${bytes.toFixed(dp)} ${units[u]}`
}








// https://github.com/Khan/fuzzy-match-utils/blob/master/src/main.js

export function cleanUpText(input, substitutions) {
  if (!input) return ''
  // Uppercase and remove all non-alphanumeric, non-accented characters. Also remove underscores.
  input = input.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/((?=[^\u00E0-\u00FC])\W)|_/g, '')
  if (!substitutions) return input
  // Replace all strings in `substitutions` with their standardized counterparts.
  return Object.keys(substitutions)
    .reduce((output, substitution) => {
      const unsubbed = new RegExp(substitution, 'g')
      return output.replace(unsubbed, substitutions[substitution])
    }, input)
}


export function fullStringDistance(a, b) {
  const aLength = a.length
  const bLength = b.length
  const table = []

  if (!aLength) return bLength
  if (!bLength) return aLength

  for (let x = 0; x <= aLength; ++x) {
    table[x] = [x]
  }
  for (let y = 0; y <= bLength; ++y) {
    table[0][y] = y
  }
  // Populate the rest of the table with a dynamic programming algorithm.
  for (let x = 1; x <= aLength; ++x) {
    for (let y = 1; y <= bLength; ++y) {
      table[x][y] = a[x - 1] === b[y - 1] ?
        table[x - 1][y - 1] :
        1 + Math.min(
          table[x - 1][y],       // Substitution,
          table[x][y - 1],       // insertion,
          table[x - 1][y - 1])  // and deletion.
    }
  }

  return table[aLength][bLength]
}

export function typeaheadSimilarity(a, b) {
  const aLength = a.length
  const bLength = b.length
  const table = []

  if (!aLength || !bLength) return 0

  // Ensure `a` isn't shorter than `b`.
  if (aLength < bLength) [a, b] = [b, a]

  // Early exit if `a` includes `b`; these will be scored higher than any other options with the same `b` (filter string), with a preference for shorter `a` strings (option labels).
  if (a.indexOf(b) !== -1) return bLength + 1 / aLength

  for (let x = 0; x <= aLength; ++x) {
      table[x] = [0]
  }
  for (let y = 0; y <= bLength; ++y) {
      table[0][y] = 0
  }

  // Populate the rest of the table with a dynamic programming algorithm.
  for (let x = 1; x <= aLength; ++x) {
    for (let y = 1; y <= bLength; ++y) {
      table[x][y] = a[x - 1] === b[y - 1] ? 1 + table[x - 1][y - 1] : Math.max(table[x][y - 1], table[x - 1][y])
    }
  }

  return table[aLength][bLength]
}

export function filterOptions(options, filter, substitutions) {
  // If the filter is blank, return the full list of Options.
  if (!filter) return options

  const cleanFilter = cleanUpText(filter, substitutions)

  return options
    // Filter out undefined or null Options.
    .filter(({ label, value }) => label != null && value != null)
    // Create a {score, Option} pair for each Option based on its label's similarity to the filter text.
    .map(option => ({ option: option, score: typeaheadSimilarity(cleanUpText(option.label, substitutions), cleanFilter) }))
    // Only include matches of the entire substring, with a slight affordance for transposition or extra characters.
    .filter(pair => pair.score >= cleanFilter.length - 2)
    // Sort 'em by order of their score.
    .sort((a, b) => b.score - a.score)
    // …and grab the original Options back from their pairs.
    .map(pair => pair.option)
}



export function secondsToHour(seconds) {
  if (!seconds) return ''
  return Math.floor(seconds / 3600)
}
