import React from 'react'
import { gql, useQuery } from '@apollo/client'

import Spin from '../../components/Spin'
import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import { FormatDateTimeWithSeconds, FormatUtcDate } from '../../components/Format'
import { useAppContext } from '../../contexts/AppContext'

const GET = gql`
  query ($id: ID!) {
    users {
      id
      name
    }
    order(id: $id) {
      id
      createdAt
      description
      accessionNumber
      ExamId
      ExamName
      ExamModality
      ExamSpecialty
      PatientName
      PatientGender
      PatientAge
      PatientBirthDate
      PatientWeight
      PatientHeight
      PhysicianName
      PhysicianCrm
      examinedAt
      sla
      indication
      anamnesis
      attachments
      timeline
      studyInstanceUID
      PatientIdTag
      reconstructedAt
      reconstruction
      unit {
        id
        name
        portfolios {
          id
          ExamId
          ExamName
        }
      }
      user {
        id
        name
      }
    }
  }
`

function humanState(state) {
  const states = {
    ASSIGNED: 'Atribuído',
    UNASSIGNED: 'Desatribuído ',
    STEP1: 'Laudando (etapa 1)',
    STEP2: 'Laudando (etapa 2)',
    STEP3: 'Laudando (etapa 3)',
    SIGNED: 'Assinado',
    UNSIGNED: 'Desassinado',
    AVAILABLE: 'Liberado',
    DELIVERED: 'Entregue',
    UNDER_CORRECTION: 'Correção solicitada',
  }
  return states[state]
}

function Show({ data: { order }, onRequestClose }) {

  const { state: { me } } = useAppContext()

  // const users = data.users.reduce((acc, item) => {
  //   acc[item.id] = item.name
  //   return acc
  // }, {})

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Detalhes da Solicitação</SubTitle></Flex>
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        <Box mb={4}>
          <Box mb={3}><SubTitle>Dados</SubTitle></Box>
          <Flex mb={2}><Box mr={3}><strong>Exame</strong></Box><Box>{order.ExamModality} - {order.ExamSpecialty} - {order.ExamName}</Box></Flex>
          <Flex mb={2}><Box mr={3}><strong>Paciente</strong></Box><Box>{order.PatientName}</Box></Flex>
          <Flex mb={2}><Box mr={3}><strong>Unidade</strong></Box><Box>{order.unit.name}</Box></Flex>
          <Flex mb={2}><Box mr={3}><strong>Médico solicitante</strong></Box><Box>{order.PhysicianName}</Box></Flex>
          <Flex mb={2}><Box mr={3}><strong>CRM do médico solicitante</strong></Box><Box>{order.PhysicianCrm}</Box></Flex>
          <Flex>
            <Box width={1/2}>
              <Flex mb={2}><Box mr={3}><strong>Exame realizado em</strong></Box><Box><FormatUtcDate>{order.examinedAt}</FormatUtcDate></Box></Flex>
              <Flex mb={2}><Box mr={3}><strong>Reconstrução?</strong></Box><Box>{order.reconstruction && order.reconstruction === 'ARECONSTRUIR' ? 'Sim' : 'Não'}</Box></Flex>
              <Flex mb={2}><Box mr={3}><strong>Sexo</strong></Box><Box>{order.PatientGender}</Box></Flex>
              <Flex mb={2}><Box mr={3}><strong>Idade</strong></Box><Box>{order.PatientAge}</Box></Flex>
            </Box>
            <Box width={1/2}>
              <Flex mb={2}><Box mr={3}><strong>SLA</strong></Box><Box>{order.sla}</Box></Flex>
              <Flex mb={2}><Box mr={3}><strong>Peso</strong></Box><Box>{order.PatientWeight}</Box></Flex>
              <Flex mb={2}><Box mr={3}><strong>Altura</strong></Box><Box>{order.PatientHeight}</Box></Flex>
              <Flex mb={2}><Box mr={3}><strong>Data de Nascimento</strong></Box><Box>{order.PatientBirthDate} (YYYYMMDD)</Box></Flex>
            </Box>
          </Flex>
          <Flex mb={2}><Box mr={3}><strong>Anexos</strong></Box><Box>{order.attachments ? order.attachments.length : 0}</Box></Flex>
          <Flex mb={2}><Box mr={3}><strong>Indicação clínica</strong></Box><Box>{order.indication}</Box></Flex>
          <Flex mb={2}><Box mr={3}><strong>Anamnese</strong></Box><Box>{order.anamnesis}</Box></Flex>
          <Flex mb={2}><Box mr={3}><strong>DICOM PatientID</strong></Box><Box>{order.PatientIdTag}</Box></Flex>
          <Flex mb={2}><Box mr={3}><strong>DICOM Description</strong></Box><Box>{order.description}</Box></Flex>
          <Flex mb={2}><Box mr={3}><strong>DICOM Accession Number</strong></Box><Box>{order.accessionNumber}</Box></Flex>
          <Flex mb={2}><Box mr={3}><strong>DICOM StudyInstanceUID</strong></Box><Box>{order.studyInstanceUID}</Box></Flex>
        </Box>

        {me.role.includes('ADMIN') &&
          <>
            <Box mb={3}><SubTitle>Linha do tempo</SubTitle></Box>
            <Flex mb={2}>
              <Box mr={3}><FormatDateTimeWithSeconds locale='pt-br'>{order.createdAt}</FormatDateTimeWithSeconds></Box>
              <Box mr={3}><strong>Criado</strong></Box>
              {order.user && <Box>por {order.user.name}</Box>}
            </Flex>
            {order.timeline && order.timeline.map((item) => (
              <Flex key={item.at} mb={2}>
                <Box mr={3}><FormatDateTimeWithSeconds locale='pt-br'>{item.at}</FormatDateTimeWithSeconds></Box>
                <Box mr={3}><strong>{humanState(item.status)}</strong></Box>
                <Box>{JSON.stringify(item.details).replace(/"/g, '').replace(/,/g, ' - ').replace(/^\{/, '').replace(/\}$/, '').replace(/by:/, 'por Usuário ID: ').replace(/to:/, 'para: ').replace(/id:/, 'ID: ')}</Box>
              </Flex>
            ))}
          </>
        }

      </Box>
    </Flex>
  )
}

const ShowQuery = ({ id, onRequestClose }) => {
  const { loading, error, data } = useQuery(GET, { variables: { id }, fetchPolicy: 'network-only' })
  if (loading) return <Spin />
  if (error) return error.message
  return <Show data={data} onRequestClose={onRequestClose} />
}

export default ShowQuery
