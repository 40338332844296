import React from 'react'
import { Field } from 'formik'

import { Box } from '../../components/FlexBox'
import Label from '../../components/Label'
import Button from '../../components/Button'
import FieldInput from '../../components/FieldInput'
import FieldSelect from '../../components/FieldSelect'

function Form({ exams, handleSubmit }) {

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label required>Exame</Label>
        <Field width='100%' name='ExamId' component={FieldSelect} options={[{ value: '', label: 'Selecione...' }, ...exams.map(item => ({ value: item.id, label: item.name }))]} />
      </Box>

      <Box mb={3}>
        <Label>Preço base</Label>
        <Field width='100%' name='price' component={FieldInput} />
      </Box>

      {/* <Box mb={3}>
        <Label>Modalidade</Label>
        <Field width='100%' name='modality' component={FieldInput} withError={touched.modality && errors.modality} />
        <FormErrorBox fieldName='modality' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>Especialidade</Label>
        <Field width='100%' name='specialty' component={FieldInput} withError={touched.specialty && errors.specialty} />
        <FormErrorBox fieldName='specialty' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>Parte do Corpo</Label>
        <Field width='100%' name='bodyPart' component={FieldInput} withError={touched.bodyPart && errors.bodyPart} />
        <FormErrorBox fieldName='bodyPart' errors={errors} touched={touched} />
      </Box> */}

      <Box mb={3}>
        <Button type='submit'>Salvar</Button>
      </Box>

    </form>
  )
}

export default Form
