import React from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { gql, useQuery, useMutation } from '@apollo/client'

import Spin from '../../components/Spin'
import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './NewOrderForm'

const BASE_URL = 'estudos'

function getAge(date) {
  if (!date) return ''
  const birthdate = moment(date, 'YYYYMMDD', true)
  return moment().diff(birthdate, 'years')
}

function getDate(date) {
  if (!date) return ''
  return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`
}

const GET = gql`
  query ($id: ID!) {
    study(id: $id) {
      id
      PatientName
      PatientGender
      description
      accessionNumber
      studyInstanceUID
      PatientBirthDate
      referringPhisicianNameTag
      studyDateTag
      modality
      orders
      reconstruction
      patientIDTag
      unit {
        id
        name
        portfolios {
          id
          ExamId
          ExamName
          ExamModality
          ExamSpecialty
          ExamBodyPart
          PortfolioUnitId
          PortfolioUnitPrice
        }
      }
    }
  }
`

const CREATE = gql`
  mutation ($UnitId: ID!, $PatientName: String!, $PatientGender: String!, $description: String, $accessionNumber: String, $studyInstanceUID: String, $PatientIdTag: String, $PatientAge: Int, $PatientWeight: Int, $PatientHeight: Int, $PatientBirthDate: Int, $PhysicianName: String, $PhysicianCrm: String, $examinedAt: Date, $sla: Int, $indication: String, $anamnesis: String, $Portfolios: JSON, $StudiesIds: JSON, $attachments: JSON) {
    createOrder(UnitId: $UnitId, PatientName: $PatientName, PatientGender: $PatientGender, description: $description, accessionNumber: $accessionNumber, studyInstanceUID: $studyInstanceUID, PatientIdTag: $PatientIdTag, PatientAge: $PatientAge, PatientWeight: $PatientWeight, PatientHeight: $PatientHeight, PatientBirthDate: $PatientBirthDate, PhysicianName: $PhysicianName, PhysicianCrm: $PhysicianCrm, examinedAt: $examinedAt, sla: $sla, indication: $indication, anamnesis: $anamnesis, Portfolios: $Portfolios, StudiesIds: $StudiesIds, attachments: $attachments)
  }
`

const validationSchema = yup.object().shape({
  // ExamId: yup.string().required(),
  PatientName: yup.string().required(),
  PatientGender: yup.string().required(),
  indication: yup.string().required(),
  PatientAge: yup.number().required().integer().min(0).max(130),
  PatientWeight: yup.number().integer().min(0).max(300),
  PatientHeight: yup.number().integer().min(0).max(300),
  sla: yup.number().integer().min(6).max(48),
  // Portfolios: yup.array().of(yup.number()).min(1, 'Selecione pelo menos um exame').required('Selecione pelo menos um exame')
  Portfolios: yup.array().min(1, 'Selecione pelo menos um exame').required('Selecione pelo menos um exame'),
  attachments: yup.array().min(1, 'Adicione pelo menos um anexo').required('Adicione pelo menos um anexo')
})

function NewOrder({ ids, data: { study }, onRequestClose }) {

  const history = useHistory()
  const { message } = useMessageContext()
  const [createOrder] = useMutation(CREATE)

  let initialValues = {
    // ExamId: '',
    Portfolios: [],
    PatientName: study.PatientName,
    PatientGender: study.PatientGender,
    description: study.description,
    accessionNumber: study.accessionNumber,
    studyInstanceUID: study.studyInstanceUID,
    PatientAge: getAge(study.PatientBirthDate),
    PatientBirthDate: study.PatientBirthDate ? Number(study.PatientBirthDate) : null,
    PatientWeight: '',
    PatientHeight: '',
    PhysicianName: study.referringPhisicianNameTag,
    PhysicianCrm: '',
    examinedAt: getDate(study.studyDateTag),
    previousExam1At: '',
    previousExam2At: '',
    sla: 48,
    indication: '',
    anamnesis: '',
    attachments: [],
    isComparative: false
  }

  if (study.orders && study.orders.length > 0) {
    initialValues.PatientGender = study.orders[0].PatientGender
    initialValues.PatientName = study.orders[0].PatientName
    initialValues.anamnesis = study.orders[0].anamnesis
    initialValues.indication = study.orders[0].indication
    // initialValues.attachments = study.orders[0].attachments
  }

  const handleSubmit = async (values, actions) => {
    let { PatientAge, PatientWeight, PatientHeight, examinedAt, sla, ...variables } = values

    variables = {
      ...variables,
      UnitId: study.unit.id,
      PatientIdTag: study.patientIDTag,
      PatientAge: PatientAge || null,
      PatientWeight: PatientWeight || null,
      PatientHeight: PatientHeight || null,
      examinedAt: examinedAt || null,
      sla: Number(sla) || 48,
      StudiesIds: ids.split('-')
    }

    try {
      await createOrder({ variables })
      message(`Solicitação criada com sucesso!`)
      history.push(`/${BASE_URL}${window.location.search}`)
      history.go()
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Nova Solicitação</SubTitle></Flex>
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} modality={study.modality} unit={study.unit} description={study.description} />}
        </Formik>

      </Box>
    </Flex>
  )
}

const NewOrderQuery = ({ ids, onRequestClose }) => {
  const id = ids.split('-')[0]
  const { loading, error, data } = useQuery(GET, { variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  return <NewOrder ids={ids} data={data} onRequestClose={onRequestClose} />
}

export default NewOrderQuery
