import React, { useState, useMemo } from 'react'
import { css } from 'styled-components/macro'
import { useHistory } from 'react-router-dom'
import { GlobalHotKeys } from 'react-hotkeys'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params'

import Spin from '../../components/Spin'
import { Flex, Box } from '../../components/FlexBox'
import Tooltip from '../../components/Tooltip'
import ContextMenu from '../../components/ContextMenu'
import PopOver from '../../components/PopOver'
import { Menu, MenuItem } from '../../components/Menu'
import { MoreIcon } from '../../components/Icons'
import { SortLabel, TableContainer, Table, TableHeaderRow, TableHeaderCell, TableRow, TableCell } from '../../components/Table'
import { FormatDateTime } from '../../components/Format'
import Pagination from '../../components/Pagination'
import { useMessageContext } from '../../contexts/MessageContext'
import useLocalStorage from '../../hooks/useLocalStorage'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import PortalAwareItem from '../../components/PortalAwareItem'
import { SettingsIcon, DragIndicatorIcon } from '../../components/Icons'
import Checkbox from '../../components/Checkbox'

import { reorder, clamp, orderBy, searchFor } from '../../utils'

const BASE_URL = 'unidades'

const LIST = gql`
  query {
    units {
      id
      createdAt
      name
      shortname
    }
  }
`

const DELETE = gql`
  mutation ($id: ID!) {
    deleteUnit(id: $id)
  }
`

const PAGE_SIZE = 30
const INITIAL_ORDER = 'name asc'

function List({ data: { units: items } }) {

  const history = useHistory()

  const [{ o: order, p: page, q: search }, setQuery] = useQueryParams({ o: withDefault(StringParam, INITIAL_ORDER), p: withDefault(NumberParam, 1), q: StringParam })

  const filteredItems = useMemo(() => searchFor(items, search, 'name'), [items, search])

  const pageCount = Math.ceil(filteredItems.length > 0 ? filteredItems.length / PAGE_SIZE : 0)
  const offset = (page ? page - 1 : 0) * PAGE_SIZE

  let [field, ascOrDesc] = order ? order.split(' ') : ['', '']

  const paginatedItems = useMemo(() => orderBy(filteredItems, field, ascOrDesc).slice(offset, offset + PAGE_SIZE), [items, field, ascOrDesc, page, search])

  const { message } = useMessageContext()
  const [deleteUnit] = useMutation(DELETE)

  const [activeId, setActiveId] = useState(null)

  // ---------------- COLUMNS ----------------

  const initialColumns = [
    { name: 'name', label: 'Nome', nowrap: true, sortable: true },
    { name: 'shortname', label: 'Código', nowrap: true, sortable: true },
  ]

  const tds = {
    name: (item, config) => <TableCell key='name' data-title='Nome' style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.name}</TableCell>,
    shortname: (item, config) => <TableCell key='shortname' data-title='Código' style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.shortname}</TableCell>,
  }

  // const [columns, setColumns] = useLocalStorage('unitsColumns', initialColumns)
  const [columns, setColumns] = useState(initialColumns)
  const [hiddenColumns, setHiddenColumns] = useLocalStorage('unitsHiddenColumns', [])

  const handleDragEnd = result => {
    if (!result.destination) return
    if (result.destination.index === result.source.index) return
    const reorderedColumns = reorder(columns, result.source.index, result.destination.index)
    setColumns(reorderedColumns)
  }

  const handleChangeHiddenColumns = (columnName, checked) => {
    if (checked) {
      setHiddenColumns(currentHiddenColumns => {
        return currentHiddenColumns.filter(item => item !== columnName)
      })
    } else {
      setHiddenColumns(currentHiddenColumns => {
        return [...currentHiddenColumns, columnName]
      })
    }
  }

  // ---------------- COLUMNS END ----------------

  const handleDelete = async id => {
    const name = items.find(item => item.id === id).name
    if (!window.confirm(`Você tem certeza que deseja excluir a unidade ${name}?`)) return false

    const update = (cache, { data: { deleteUnit } }) => {
      if (!deleteUnit) return false
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, units: cacheList.units.filter(item => item.id !== id) } })
    }

    try {
      await deleteUnit({ variables: { id }, update })
      message('Unidade excluída com sucesso!')
    } catch(error) {
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  const handleUp = () => {
    if (window.location.href.includes('editar')) return false
    const ids = paginatedItems.map(item => item.id)
    const currentIndex = activeId ? ids.indexOf(activeId) : ids.length
    setActiveId(ids[clamp((currentIndex - 1), 0, (ids.length - 1))])
  }

  const handleDown = () => {
    if (window.location.href.includes('editar')) return false
    const ids = paginatedItems.map(item => item.id)
    const currentIndex = activeId ? ids.indexOf(activeId) : -1
    setActiveId(ids[clamp((currentIndex + 1), 0, (ids.length - 1))])
  }

  const handleEditKey = () => {
    if (!activeId) return false
    history.push(`/${BASE_URL}/${activeId}/editar${window.location.search}`)
  }

  const handleDeleteKey = () => {
    if (!activeId) return false
    handleDelete(activeId)
  }

  const handlePageChange = ({ selected }) => {
    if (selected) {
      setQuery({ p: (selected + 1) })
    } else {
      setQuery({ p: 1 })
      setActiveId(null)
    }
  }

  return (
    <>
      <GlobalHotKeys allowChanges keyMap={{ DOWN: ['j'], UP: ['k'], EDIT: ['e'], DELETE: ['x']  }} handlers={{ DOWN: handleDown, UP: handleUp, EDIT: handleEditKey, DELETE: handleDeleteKey }} />

      <Flex alignItems='center' justifyContent='space-between'>
        <Box>
          {(filteredItems.length > 0) && <>{`Total: ${filteredItems.length}`}</>}
        </Box>
        <Box py={1}>
          <PopOver render={() => (
            <Menu css={css`max-height: calc(100vh - 58px); overflow-y: scroll;`}>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId='list'>
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {columns.map((item, index) => (
                        <Draggable draggableId={item.name} index={index} key={item.name}>
                          {(provided, snapshot) => (
                            <PortalAwareItem innerRef={provided.innerRef} provided={provided} snapshot={snapshot}>
                              <div style={{ marginRight: 8 }} {...provided.dragHandleProps}><DragIndicatorIcon height={16} /></div>
                              <MenuItem disableHover pl={0}>
                                <Checkbox label={item.label} checked={!hiddenColumns.includes(item.name)} onChange={checked => handleChangeHiddenColumns(item.name, checked)} />
                              </MenuItem>
                            </PortalAwareItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Menu>
          )}>
            <Tooltip tooltip='Exibir/ocultar e reordenar colunas'><SettingsIcon height={16} style={{ cursor: 'pointer' }} /></Tooltip>
          </PopOver>
        </Box>
      </Flex>

      <TableContainer count={filteredItems.length}>
        <Table>
          <TableHeaderRow>
            <TableHeaderCell style={{ whiteSpace: 'nowrap', width: 140 }}>Criado</TableHeaderCell>
            {columns.filter(item => !hiddenColumns.includes(item.name)).map(item => (
              <TableHeaderCell key={item.label} width={item.width ? item.width : null} style={{ whiteSpace: item.nowrap ? 'nowrap' : null, paddingRight: item.paddingRight ? item.paddingRight : null }}>
                {item.sortable ? (<SortLabel label={item.label} headerField={item.name} currentField={field} currentAscOrDesc={ascOrDesc} onChange={o => setQuery({ o, p: 1 })} />) : item.label}
              </TableHeaderCell>
            ))}
            <TableHeaderCell></TableHeaderCell>
          </TableHeaderRow>
          {paginatedItems.map(item => (
            <ContextMenu key={item.id} render={() => (
              <Menu>
                <MenuItem to={`/${BASE_URL}/${item.id}/editar${window.location.search}`}>Editar (e)</MenuItem>
                <MenuItem onClick={() => { handleDelete(item.id) }}>Excluir (x)</MenuItem>
              </Menu>
            )}>
              <TableRow active={activeId === item.id} onMouseOver={() => setActiveId(item.id)}>
                <TableCell data-title='Criado'><FormatDateTime locale='pt-br'>{item.createdAt}</FormatDateTime></TableCell>
                {columns.filter(td => !hiddenColumns.includes(td.name)).map(td => tds[td.name](item, td))}
                <TableCell width={40} lineHeight={0}>
                  <PopOver placement='left' modifiers={{ preventOverflow: { enabled: false }, hide: { enabled: false } }} render={() => (
                    <Menu>
                      <MenuItem to={`/${BASE_URL}/${item.id}/editar${window.location.search}`}>Editar (e)</MenuItem>
                      <MenuItem onClick={() => { handleDelete(item.id) }}>Excluir (x)</MenuItem>
                    </Menu>
                  )}>
                    <Flex cursor='pointer'><MoreIcon /></Flex>
                  </PopOver>
                </TableCell>
              </TableRow>
            </ContextMenu>
          ))}
        </Table>
      </TableContainer>

      <Box textAlign='center'>
        <Pagination onPageChange={handlePageChange} currentPage={page} pageCount={pageCount} />
      </Box>
    </>
  )
}

const ListQuery = () => {
  const { loading, error, data } = useQuery(LIST)
  if (loading) return <Spin />
  if (error) return error.message
  return <List data={data} />
}

export default ListQuery
