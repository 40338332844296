import React from 'react'
import styled from 'styled-components/macro'

import Portal from './Portal'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 6px;
  border: 1px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'hsla(202, 20%, 86%, 1)'};
  background-color: ${props => (props.theme && props.theme.colors && props.theme.colors.grey100) ? props.theme.colors.grey100 : 'hsla(201, 20%, 96%, 1)'};
  /* ${props => props.inPortal ? `::after { position: absolute; background: lightgreen; bottom: 0; right: 0; content: "in portal" }` : ''}; */
`

const PortalAwareItem = ({ children, provided, snapshot, ...rest }) => {
  // https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/portal/portal-app.jsx
  const usePortal = snapshot.isDragging
  const child = (
    <Container
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      inPortal={usePortal}
      {...rest}
    >
      {children}
    </Container>
  )
  if (!usePortal) return child
  return <Portal>{child}</Portal>
}

export default PortalAwareItem
