import React from 'react'
import { Formik } from 'formik'
import { gql, useQuery, useMutation } from '@apollo/client'

import Spin from '../../components/Spin'
import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

// CHANGE
const GET = gql`
  query ($id: ID!) {
    user(id: $id) {
      id
      name
      email
      role
      UnitId
    }
    units {
      id
      name
    }
  }
`

// CHANGE
const UPDATE = gql`
  mutation ($id: ID!, $name: String!, $email: String!, $role: String, $UnitId: ID) {
    updateUser(id: $id, name: $name, email: $email, role: $role, UnitId: $UnitId) {
      id
      name
      email
      role
      unit {
        id
        name
      }
    }
  }
`

const validationSchema = yup.object().shape({
  name: yup.string().required(),
})

function Edit({ data: { user, units }, onRequestClose }) {

  const { message } = useMessageContext()
  const [updateUser] = useMutation(UPDATE) // CHANGE

  const initialValues = user // CHANGE

  const handleSubmit = async (variables, actions) => {
    try {
      await updateUser({ variables: { id: user.id, ...variables } }) // CHANGE
      message(`Usuário alterado com sucesso!`) // CHANGE
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Editar Usuário</SubTitle></Flex>{/* // CHANGE */}
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} units={units} />}
        </Formik>

      </Box>
    </Flex>
  )
}

const EditQuery = ({ id, onRequestClose }) => {
  const { loading, error, data } = useQuery(GET, { variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  return <Edit data={data} onRequestClose={onRequestClose} />
}

export default EditQuery
