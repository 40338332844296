import React from 'react'
import { Formik } from 'formik'
import { gql, useQuery, useMutation } from '@apollo/client'

import Spin from '../../components/Spin'
import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

const GET = gql`
  query ($id: ID!) {
    portfolio(id: $id) {
      id
      price
      ExamId
      ExamName
      ExamModality
      ExamSpecialty
      ExamBodyPart
    }
  }
`

const UPDATE = gql`
  mutation ($id: ID!, $ExamId: ID!, $price: Int) {
    updatePortfolio(id: $id, ExamId: $ExamId, price: $price) {
      id
      price
      ExamId
      ExamName
      ExamModality
      ExamSpecialty
      ExamBodyPart
    }
  }
`

const validationSchema = yup.object().shape({
  ExamId: yup.string().required(),
})

function Edit({ data, onRequestClose }) {

  const { message } = useMessageContext()
  const [updatePortfolio] = useMutation(UPDATE)

  const initialValues = data.portfolio

  const handleSubmit = async (variables, actions) => {
    try {
      await updatePortfolio({ variables: { id: data.portfolio.id, ...variables } })
      message(`Portfolio alterado com sucesso!`)
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Editar Portfolio</SubTitle></Flex>
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
}

const EditQuery = ({ id, onRequestClose }) => {
  const { loading, error, data } = useQuery(GET, { variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  return <Edit data={data} onRequestClose={onRequestClose} />
}

export default EditQuery
