import React from 'react'
import { Field } from 'formik'
import { Box } from '../../components/FlexBox'
import Label from '../../components/Label'
import Button from '../../components/Button'
import FieldInput from '../../components/FieldInput'
import useFocusOnLoad from '../../hooks/useFocusOnLoad'

function Form({ handleSubmit }) {

  useFocusOnLoad('PatientName')

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label required>Nome do paciente</Label>
        <Field width='100%' name='PatientName' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Button type='submit'>Salvar</Button>
      </Box>

    </form>
  )
}

export default Form
