import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components/macro'
import FormErrorBox from './FormErrorBox'

const Select = forwardRef(({ form, field, width, options, placeholder, ...rest }, ref) => {

  let selectOptions = [...options]
  if (placeholder) selectOptions = [{ label: placeholder, value: '' }, ...selectOptions]

  return (
    <>
      <select {...field} {...rest} ref={ref}>
        {selectOptions.map(item => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
      <FormErrorBox fieldName={field.name} errors={form.errors} touched={form.touched} />
    </>
  )
})

const StyledSelect = styled(Select)`
	-moz-appearance: none;
	-webkit-appearance: none;
  appearance: none;


background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2232%22%20height%3D%2232%22%3E%3Cpath%20fill%3D%22${props => props.theme && props.theme.colors && props.theme.colors.primary ? encodeURIComponent(props.theme.colors.primary) : 'grey'}%22%20d%3D%22M16.59%208.59L12%2013.17%207.41%208.59%206%2010l6%206%206-6z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat;
	background-position: right 4px top 50%;
  background-size: 16px auto;

  font-family: inherit;
  font-size: inherit;
  color: inherit;
  padding: 8px 12px;
  border: 1px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
  ${props => props.width && css`width: ${props.width};`}
  ${props => props.form.errors[props.field.name] && props.form.touched[props.field.name] && css`border-color: ${props.theme && props.theme.colors && props.theme.colors.error ? props.theme.colors.error : 'hsla(6, 66%, 47%, 1)'} !important;`}
  &:focus {
    outline: 3px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey100) ? props.theme.colors.grey100 : 'hsla(0, 0%, 96%, 1)'};
  }
`

export default StyledSelect
