import React from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import { css } from 'styled-components/macro'
import { Flex, Box } from './FlexBox'
import PopOver from './PopOver'

import Button from './Button'

const weekdays = 'Domingo_Segunda-feira_Terça-feira_Quarta-feira_Quinta-feira_Sexta-feira_Sábado'.split('_')
const months = 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')

const lastXDays = days => {
  const date = new Date()
  date.setDate(date.getDate() - days)
  date.setHours(0, 0, 0, 0)
  return date
}

const today = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return today
}

const tomorrow = () => {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0, 0, 0, 0)
  return tomorrow
}

const lastWeek = () => {
  const lastWeek = new Date()
  lastWeek.setDate(lastWeek.getDate() - 7)
  lastWeek.setHours(0, 0, 0, 0)
  return lastWeek
}

const weekdaysShort = weekdays.map(item => item.slice(0, 2))

const formatDate = (date, locale = (window.navigator.userLanguage || window.navigator.language)) => {
  if (!date) return ''
  return date.toLocaleDateString(locale, { year: 'numeric', month: '2-digit', day: '2-digit' })
}

function DateRangePicker({ from, to, onChange, locale }) {

  const handleDayClick = day => {
    if (day.getTime() < tomorrow().getTime()) {
      const range = DateUtils.addDayToRange(day, {from, to})
      onChange(range)
    }
  }

  const handleRangeToday = () => {
    const todayDate = today()
    onChange({from: todayDate, to: todayDate})
  }

  const handleRangeLastWeek = () => {
    onChange({from: lastWeek(), to: today()})
  }

  const handleRangeLast3Days = () => {
    onChange({from: lastXDays(3), to: today()})
  }

  const popper = onRequestClose => (
    <Box bg='white' css={css`box-shadow: rgba(0, 0, 0, 0.14) 3px 3px 5px 0px;`}>
      <DayPicker
        month={from}
        disabledDays={{after: today()}}
        className='Selectable'
        numberOfMonths={2}
        selectedDays={[from, { from, to }]}
        modifiers={{start: from, end: to}}
        onDayClick={handleDayClick}
        months={months}
        weekdaysLong={weekdays}
        weekdaysShort={weekdaysShort}
        firstDayOfWeek={0}
      />
      <Flex jc='space-around' p={2} flexWrap='wrap'>
        <Box m={2}><Button onClick={onRequestClose}>Fechar</Button></Box>
        <Box m={2}><Button onClick={() => { handleRangeToday(); onRequestClose() }}>Hoje</Button></Box>
        <Box m={2}><Button onClick={() => { handleRangeLast3Days(); onRequestClose() }}>Últimos 3 dias</Button></Box>
        <Box m={2}><Button onClick={() => { handleRangeLastWeek(); onRequestClose() }}>Última semana</Button></Box>
      </Flex>
    </Box>
  )

  return (
    <PopOver render={({onRequestClose}) => popper(onRequestClose)}>
      <Box px='10px' css={css`border: 1px solid ${props => props.theme.colors.grey300}; background-color: ${props => props.theme.colors.white}; border-radius: 3px; line-height: 33px;`}>{`${formatDate(from, locale)} - ${formatDate(to, locale)}`}</Box>
    </PopOver>
  )
}

export default DateRangePicker
