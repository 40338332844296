import React from 'react'
import { BrowserRouter, Switch, Route, } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ThemeProvider } from 'styled-components'

import { AppProvider } from './contexts/AppContext'
import { MessageProvider } from './contexts/MessageContext'

import { useAppContext } from './contexts/AppContext'

import GlobalStyle from './GlobalStyle'
import themes from './themes'

import AutoLogout from './components/AutoLogout'
import WithMe from './components/WithMe'
import PrivateRoute from './components/PrivateRoute'
import PublicRoute from './components/PublicRoute'

import MainLayout from './layouts/MainLayout'
import LoginLayout from './layouts/LoginLayout'

import Dashboard from './containers/Dashboard'
import Units from './containers/Units'
import Exams from './containers/Exams'
import Portfolios from './containers/Portfolios'
import Studies from './containers/Studies'
import Orders from './containers/Orders'
import Users from './containers/Users'
import Sectors from './containers/Sectors'
import AllMessages from './containers/AllMessages'
import MyMessages from './containers/MyMessages'
import Actions from './containers/Actions'
import Login from './containers/Login'
import Notifications from './containers/Notifications'
import ImportExport from './containers/ImportExport'
import Reports from './containers/Reports'
import Lab from './containers/Lab'

function App() {

  const { state } = useAppContext()
  const theme = themes[state.theme]

  // React.useEffect(() => {
  //   if (state.me) {
  //     window.splitbee.user.set({ userId: state.me.id, displayName: state.me.name, email: state.me.email })
  //   }
  // }, [])

  return (
    <BrowserRouter>

      <QueryParamProvider ReactRouterRoute={Route}>

        <ThemeProvider theme={theme}>

          <GlobalStyle />

          <AutoLogout />

          <MessageProvider>

            <Switch>
              <Route path='/lab' exact>
                <Lab />
              </Route>

              <Route path={['/login']}>
                <LoginLayout>
                  <Switch>
                    <PublicRoute path='/login' redirectTo='/estudos'>
                      <Login />
                    </PublicRoute>
                  </Switch>
                </LoginLayout>
              </Route>

              <Route path='/' exact>
                <Dashboard />
              </Route>

              <Route path={['/relatorios', '/importar-exportar', '/notificacoes', '/usuarios', '/setores', '/unidades', '/exames', '/portfolio', '/estudos', '/solicitacoes', '/todas-mensagens', '/minhas-mensagens', '/log']}>
                <MainLayout>
                  <Switch>
                    <PrivateRoute path='/relatorios'>
                      <Reports />
                    </PrivateRoute>
                    <PrivateRoute path='/importar-exportar'>
                      <ImportExport />
                    </PrivateRoute>
                    <PrivateRoute path='/notificacoes'>
                      <Notifications />
                    </PrivateRoute>
                    <PrivateRoute path='/usuarios'>
                      <Users />
                    </PrivateRoute>
                    <PrivateRoute path='/setores'>
                      <Sectors />
                    </PrivateRoute>
                    <PrivateRoute path='/unidades'>
                      <Units />
                    </PrivateRoute>
                    <PrivateRoute path='/exames'>
                      <Exams />
                    </PrivateRoute>
                    <PrivateRoute path='/portfolio'>
                      <Portfolios />
                    </PrivateRoute>
                    <PrivateRoute path='/estudos'>
                      <Studies />
                    </PrivateRoute>
                    <PrivateRoute path='/solicitacoes'>
                      <Orders />
                    </PrivateRoute>
                    <PrivateRoute path='/todas-mensagens'>
                      <AllMessages />
                    </PrivateRoute>
                    <PrivateRoute path='/minhas-mensagens'>
                      <MyMessages />
                    </PrivateRoute>
                    <PrivateRoute path='/log'>
                      <Actions />
                    </PrivateRoute>
                  </Switch>
                </MainLayout>
              </Route>

            </Switch>

          </MessageProvider>

        </ThemeProvider>

      </QueryParamProvider>

    </BrowserRouter>
  )

}

// const AppWithProvider = () => {
//   return (
//     <AppProvider defaultValue={{ theme: 'default' }}><App /></AppProvider>
//     // localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN) ? (
//     //   <WithMe>{({ data }) => <AppProvider defaultValue={{ theme: 'default', me: data.me }}><App /></AppProvider>}</WithMe>
//     // ) : (
//     //   <AppProvider defaultValue={{ theme: 'default' }}><App /></AppProvider>
//     // )
//   )
// }


function AppWithProvider() {
  const token = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)
  return (
    token ? (
      <WithMe>{({ data }) => <AppProvider defaultValue={{ theme: 'default', me: data.me }}><App /></AppProvider>}</WithMe>
    ) : (
      <AppProvider defaultValue={{ theme: 'default' }}><App /></AppProvider>
    )
  )
}


export default AppWithProvider
