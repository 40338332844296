import React from 'react'
import { Formik } from 'formik'
import { gql, useQuery, useMutation } from '@apollo/client'

import Spin from '../../components/Spin'
import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

const GET = gql`
  query ($id: ID!) {
    order(id: $id) {
      id
      createdAt
      ExamName
      PatientName
      PatientGender
      PatientAge
      PatientWeight
      PatientHeight
      PhysicianName
      PhysicianCrm
      examinedAt
      sla
      indication
      anamnesis
      attachments
      unit {
        id
        name
        portfolios {
          id
          ExamId
          ExamName
        }
      }
    }
  }
`

const UPDATE = gql`
  mutation ($id: ID!, $PatientName: String!, $PatientGender: String!, $PatientAge: Int, $PatientWeight: Int, $PatientHeight: Int, $PhysicianName: String, $PhysicianCrm: String, $examinedAt: Date, $sla: Int, $indication: String, $anamnesis: String, $addedAttachments: JSON, $deletedAttachments: JSON) {
    updateOrder(id: $id, PatientName: $PatientName, PatientGender: $PatientGender, PatientAge: $PatientAge, PatientWeight: $PatientWeight, PatientHeight: $PatientHeight, PhysicianName: $PhysicianName, PhysicianCrm: $PhysicianCrm, examinedAt: $examinedAt, sla: $sla, indication: $indication, anamnesis: $anamnesis, addedAttachments: $addedAttachments, deletedAttachments: $deletedAttachments) {
      id
      createdAt
      ExamId
      PatientName
      PatientGender
      PatientAge
      PatientWeight
      PatientHeight
      PhysicianName
      PhysicianCrm
      examinedAt
      sla
      indication
      anamnesis
      attachments
      unit {
        id
        name
        portfolios {
          id
          ExamId
          ExamName
        }
      }
    }
  }
`

const validationSchema = yup.object().shape({
  PatientName: yup.string().required(),
  PatientGender: yup.string().required(),
  PatientAge: yup.number().integer().min(0).max(140).nullable(),
  PatientWeight: yup.number().integer().min(0).max(300).nullable(),
  PatientHeight: yup.number().integer().min(0).max(300).nullable(),
  sla: yup.number().integer().min(6).max(48),
})

function Edit({ data, onRequestClose }) {

  const { message } = useMessageContext()
  const [updateOrder] = useMutation(UPDATE)

  const initialValues = {
    ...data.order,
    PatientAge: data.order.PatientAge || '',
    PatientWeight: data.order.PatientWeight || '',
    PatientHeight: data.order.PatientHeight || '',
    addedAttachments: [],
    deletedAttachments: []
  }

  const handleSubmit = async (values, actions) => {
    let { PatientAge, PatientWeight, PatientHeight, examinedAt, sla, ...variables } = values
    variables = {
      ...variables,
      PatientAge: PatientAge !== null ? Number(PatientAge) : null,
      PatientWeight: PatientWeight ? Number(PatientWeight) : null,
      PatientHeight: PatientHeight ? Number(PatientHeight) : null,
      examinedAt: examinedAt || null,
      sla: sla ? Number(sla) : 48
    }

    try {
      await updateOrder({ variables: { id: data.order.id, ...variables } })
      message(`Solicitação alterada com sucesso!`)
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Editar Solicitação</SubTitle></Flex>
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} unit={data.order.unit} />}
        </Formik>

      </Box>
    </Flex>
  )
}

const EditQuery = ({ id, onRequestClose }) => {
  const { loading, error, data } = useQuery(GET, { variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  return <Edit data={data} onRequestClose={onRequestClose} />
}

export default EditQuery
