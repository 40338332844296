import React from 'react'
import QRCode from 'qrcode.react'

import { gql, useMutation } from '@apollo/client'

import { Flex, Box } from '../../components/FlexBox'
import Button from '../../components/Button'
import Title from '../../components/Title'

const PUBLIC_KEY = 'BMcaS1AvbZHKB3GPDHMaxSLV501v_Gx2qlvbtmlxWXxEh8Mg_UxCMC8v21-hryb9NlvgVpuTZFkJ5QHFnqDBu9Q'

function Notifications() {

  const [subscribe] = useMutation(gql`mutation ($payload: JSON!, $navigator: JSON) { subscribe(payload: $payload, navigator: $navigator) }`)

  const [log, setLog] = React.useState('Log')

  const isMobile = Boolean(navigator.userAgent.match(/Android/i)) || Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i))

  const url = `${process.env.REACT_APP_DOMAIN}/notificacoes?token=${localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)}`

  const handleAsk = async () => {
    // if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
    //   return alert('Seu navegador não tem o recurso de notificações.')
    // }
    // setLog(current => `${current} \n handleAsk`)
    // setLog(current => `${current} \n navigator.userAgent: ${JSON.stringify(navigator.userAgent)}`)
    // setLog(current => `${current} \n navigator: ${JSON.stringify(navigator)}`)
    // setLog(current => `${current} \n 'serviceWorker' in navigator: ${'serviceWorker' in navigator}`)
    // setLog(current => `${current} \n 'PushManager' in window: ${'PushManager' in window}`)

    // if ('serviceWorker' in navigator && 'PushManager' in window) {
    if (true) {
      const permission = await Notification.requestPermission()

      if (permission !== 'granted') {
        return alert('Você deve clicar em "Permitir" para receber notificações.')
      }

      // setLog(current => `${current} \n permission: ${permission}`)
      if (permission === 'granted') {
        const serviceWorker = await navigator.serviceWorker.ready
        // setLog(current => `${current} \n serviceWorker: ${JSON.stringify(serviceWorker)}`)
        let payload
        try {
          payload = await serviceWorker.pushManager.getSubscription()
          // setLog(current => `${current} \n payload1: ${JSON.stringify(payload)}`)
        } catch(error) {
          // setLog(current => `${current} \n error: ${JSON.stringify(error)}`)
        }
        // setLog(current => `${current} \n payload: ${payload}`)
        if (!payload) {
          payload = await serviceWorker.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: PUBLIC_KEY })
          // setLog(current => `${current} \n payload2: ${JSON.stringify(payload)}`)
        }
        const result = await subscribe({ variables: { payload, navigator: { userAgent: navigator.userAgent } } })

        if (result.data.subscribe === 'exist') {
          alert('Você já autorizou a receber notificações neste dispositivo.')
        } else if (result.data.subscribe === 'subscribed') {
          alert('Autorizado com sucesso!')
        } else if (result.data.subscribe === 'fail') {
          alert('Ocorreu um erro.')
        }
        // setLog(current => `${current} \n result: ${JSON.stringify(result)}`)
      }

    }

  }

  return (
    <>
      <Box p={4}>

        <Flex mb={4} alignItems='center' justifyContent='space-between'>
          <Box>
            <Title>Notificações</Title>
          </Box>
        </Flex>

        {/* <Box mb={3}>
          <pre>{log}</pre>
        </Box> */}

        <Box mb={3}>
          <Button onClick={handleAsk}>{isMobile ? `Quero receber notificações neste celular` : `Quero receber notificações neste computador`}</Button>
        </Box>

        {!isMobile &&
          <Box>
            <Box ta='center' mb={3}><em>Para receber notificações no celular também, aponte a câmera do aparelho para este QR Code e clique em "Quero receber notificações neste celular".</em></Box>
            <Box ta='center'><QRCode value={url} renderAs='svg' size={256} /></Box>
          </Box>
        }

      </Box>
    </>
  )
}

export default Notifications
