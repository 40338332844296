import React, { useState, useRef } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params'

import { Flex, Box } from '../../components/FlexBox'
import Title from '../../components/Title'
import Input from '../../components/Input'
import useDebounce from '../../hooks/useDebounce'
import useIgnoreMount from '../../hooks/useIgnoreMount'

import List from './List'

function Actions() {
  const inputSearchRef = useRef(null)

  // const [{ q }, setQuery] = useQueryParams({ p: StringParam, q: StringParam })
  const [{ p: page, q }, setQuery] = useQueryParams({ p: withDefault(NumberParam, 1), q: StringParam })

  const [search, setSearch] = useState(q || '')
  const debouncedSearch = useDebounce(search.trim(), 500)

  useIgnoreMount(debouncedSearch, newDebouncedSearch => {
    setQuery({ p: undefined, q: newDebouncedSearch ? newDebouncedSearch : undefined })
  })

  const handleSearchKey = e => {
    e.preventDefault()
    inputSearchRef.current.focus()
  }

  return (
    <>
      <GlobalHotKeys keyMap={{ SEARCH: ['f'] }} handlers={{ SEARCH: handleSearchKey }} />
      <Box p={4}>

        <Flex mb={4} alignItems='center' justifyContent='space-between'>
          <Box>
            <Title>Log</Title>
          </Box>
        </Flex>

        <Box>
          <Box mb={3}>
            <Input width='300px' ref={inputSearchRef} type='search' placeholder='Buscar...' onChange={e => setSearch(e.target.value)} value={search} />
          </Box>
          <List page={page} search={debouncedSearch} />
        </Box>

      </Box>

    </>
  )
}

export default Actions
