import React from 'react'
import { Formik } from 'formik'
import { gql, useMutation } from '@apollo/client'

import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

// CHANGE
const LIST = gql`
  query {
    exams {
      id
      name
    }
  }
`

// CHANGE
const CREATE = gql`
  mutation ($name: String!, $modality: String!, $specialty: String!, $bodyPart: String!, $examinationTree: String!, $hasReconstruction: Boolean) {
    createExam(name: $name, modality: $modality, specialty: $specialty, bodyPart: $bodyPart, examinationTree: $examinationTree, hasReconstruction: $hasReconstruction) {
      id
      name
      modality
      specialty
      bodyPart
      examinationTree
      hasReconstruction
    }
  }
`

const validationSchema = yup.object().shape({
  name: yup.string().required(),
})

function New({ onRequestClose }) {

  const { message } = useMessageContext()
  const [createExam] = useMutation(CREATE)

  const initialValues = { name: '', modality: '', specialty: '', bodyPart: '', examinationTree: '', hasReconstruction: false }

  const handleSubmit = async (variables, actions) => {

    const update = (cache, { data: { createExam } }) => {
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, exams: [createExam, ...cacheList.exams] } })
    }

    try {
      console.log('variables', variables)
      await createExam({ variables, update })
      message(`Exame criado com sucesso!`)
      actions.resetForm()
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }

  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Novo Exame</SubTitle></Flex>
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
}

export default New
