import React from 'react'
import useConnection from 'use-connection'
import styled, { keyframes } from 'styled-components/macro'

const animationName = keyframes`
  0% { color: transparent; }
  100% { color: white; }
`

const Pulse = styled.div`
  animation: ${animationName} 2s infinite;
`

const fast = 'M12,16A3,3 0 0,1 9,13C9,11.88 9.61,10.9 10.5,10.39L20.21,4.77L14.68,14.35C14.18,15.33 13.17,16 12,16M12,3C13.81,3 15.5,3.5 16.97,4.32L14.87,5.53C14,5.19 13,5 12,5A8,8 0 0,0 4,13C4,15.21 4.89,17.21 6.34,18.65H6.35C6.74,19.04 6.74,19.67 6.35,20.06C5.96,20.45 5.32,20.45 4.93,20.07V20.07C3.12,18.26 2,15.76 2,13A10,10 0 0,1 12,3M22,13C22,15.76 20.88,18.26 19.07,20.07V20.07C18.68,20.45 18.05,20.45 17.66,20.06C17.27,19.67 17.27,19.04 17.66,18.65V18.65C19.11,17.2 20,15.21 20,13C20,12 19.81,11 19.46,10.1L20.67,8C21.5,9.5 22,11.18 22,13Z'

const normal = 'M12 1.38L9.14 12.06C8.8 13.1 9.04 14.29 9.86 15.12C11.04 16.29 12.94 16.29 14.11 15.12C14.9 14.33 15.16 13.2 14.89 12.21M14.6 3.35L15.22 5.68C18.04 6.92 20 9.73 20 13C20 15.21 19.11 17.21 17.66 18.65H17.65C17.26 19.04 17.26 19.67 17.65 20.06C18.04 20.45 18.68 20.45 19.07 20.07C20.88 18.26 22 15.76 22 13C22 8.38 18.86 4.5 14.6 3.35M9.4 3.36C5.15 4.5 2 8.4 2 13C2 15.76 3.12 18.26 4.93 20.07C5.32 20.45 5.95 20.45 6.34 20.06C6.73 19.67 6.73 19.04 6.34 18.65C4.89 17.2 4 15.21 4 13C4 9.65 5.94 6.86 8.79 5.65'

const slow = 'M12 16C13.66 16 15 14.66 15 13C15 11.88 14.39 10.9 13.5 10.39L3.79 4.77L9.32 14.35C9.82 15.33 10.83 16 12 16M12 3C10.19 3 8.5 3.5 7.03 4.32L9.13 5.53C10 5.19 11 5 12 5C16.42 5 20 8.58 20 13C20 15.21 19.11 17.21 17.66 18.65H17.65C17.26 19.04 17.26 19.67 17.65 20.06C18.04 20.45 18.68 20.45 19.07 20.07C20.88 18.26 22 15.76 22 13C22 7.5 17.5 3 12 3M2 13C2 15.76 3.12 18.26 4.93 20.07C5.32 20.45 5.95 20.45 6.34 20.06C6.73 19.67 6.73 19.04 6.34 18.65C4.89 17.2 4 15.21 4 13C4 12 4.19 11 4.54 10.1L3.33 8C2.5 9.5 2 11.18 2 13Z'

const off = 'M2.28,3L1,4.27L2.47,5.74C2.04,6 1.61,6.29 1.2,6.6L3,9C3.53,8.6 4.08,8.25 4.66,7.93L6.89,10.16C6.15,10.5 5.44,10.91 4.8,11.4L6.6,13.8C7.38,13.22 8.26,12.77 9.2,12.47L11.75,15C10.5,15.07 9.34,15.5 8.4,16.2L12,21L14.46,17.73L17.74,21L19,19.72M12,3C9.85,3 7.8,3.38 5.9,4.07L8.29,6.47C9.5,6.16 10.72,6 12,6C15.38,6 18.5,7.11 21,9L22.8,6.6C19.79,4.34 16.06,3 12,3M12,9C11.62,9 11.25,9 10.88,9.05L14.07,12.25C15.29,12.53 16.43,13.07 17.4,13.8L19.2,11.4C17.2,9.89 14.7,9 12,9Z'



const Speedometer = props => {

  const connection = useConnection()

  if (!connection) {
    return <div style={{ width: 24, height: 24 }}></div>
  }

  if (connection && connection.online === false) {
    return (<Pulse title='Offline' style={{ width: 24, height: 24 }}><svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}><path d={off} /></svg></Pulse>)
  }

  if (connection && connection.online === true && connection.data && connection.data.effectiveType && connection.data.effectiveType.includes('2g')) {
    return (<Pulse title='Velocidade da conexão: ruim' style={{ width: 24, height: 24 }}><svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}><path d={slow} /></svg></Pulse>)
  }

  if (connection && connection.online === true && connection.data && connection.data.effectiveType && connection.data.effectiveType.includes('3g')) {
    return (<div title='Velocidade da conexão: razoável' style={{ width: 24, height: 24 }}><svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}><path d={normal} /></svg></div>)
  }

  if (connection && connection.online === true && connection.data && connection.data.effectiveType && connection.data.effectiveType.includes('4g')) {
    return (<div title='Velocidade da conexão: boa' style={{ width: 24, height: 24 }}><svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}><path d={fast} /></svg></div>)
  }

  return (
    <div title='Velocidade da conexão: Boa' style={{ width: 24, height: 24 }}><svg viewBox='0 0 24 24' height={24} fill='currentColor' {...props}><path d={off} /></svg></div>
  )

}

export default Speedometer

