import React, { useState, useRef, useEffect } from 'react'
import { lighten } from 'polished'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { useMergeRefs } from 'use-callback-ref'
import { useSpring, a } from 'react-spring'
import { createPortal } from 'react-dom'

const Portal = ({ children }) => createPortal(children, document.getElementById('portal-root'))

const PopperContainer = styled.div`
  padding-top: 8px;
  /* text-align: center; */

  /* box-shadow: 0px 4px 8px hsl(0 0% 0% / 0.1); */
  box-shadow: 0px 2px 8px hsla(0, 0%, 0%, 0.15);
  max-width: 640px;
  font-size: 13px;
  /* color: hsl(0 0% 84% / 1); */
  /* background: linear-gradient(136.61deg, hsl(240 5% 20% / 1) 13.72%, hsl(240 8% 18% / 1) 74.3%); */
  background-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? lighten(0.1, props.theme.colors.grey200) : 'hsla(0, 0%, 90%, 1)'};
  border-radius: 8px;

  #arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    &:after {
      content: " ";
      background-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? lighten(0.1, props.theme.colors.grey200) : 'hsla(0, 0%, 90%, 1)'};
      /* background-color: hsl(240, 6%, 19%);
      box-shadow: -1px -1px 1px hsl(0 0% 0% / 0.1);  */
      position: absolute;
      top: -13px; /* padding + popper height */
      left: 0;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
    }
  }

  &[data-popper-placement^='top'] > #arrow {
    bottom: -18px;
    /* :after { */
      /* box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); */
    /* } */
  }
`

const PopOver = ({ children, render, withArrow = true }) => {

  const ref = useRef()

  const [open, setOpen] = useState(false)

  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }, { name: 'offset', options: { offset: [0, 4] } }],
  })

  const springStyle = useSpring(open ? { opacity: 1, immediate: true } : { opacity: 0, immediate: true })

  const wrapperRef = useRef(null)

  const handleClick = e => {
    if (e.button === 2) return false
    if (e.target.id === 'MenuItem') return setTimeout(() => { setOpen(false) }, 100)
    if (ref.current.contains(e.target)) return setOpen(current => !current)
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) return setOpen(false)
  }

  useEffect(() => {
    // https://codesandbox.io/s/outside-alerter-hooks-lmr2y
    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, [])

  return (
    <>
      <span ref={useMergeRefs([ref, setReferenceElement])}>{children}</span>
      {open &&
        <Portal>
          <a.div style={springStyle}>
            <div ref={wrapperRef}>
              <PopperContainer ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                {withArrow && <div ref={setArrowElement} style={styles.arrow} id='arrow' />}
                {render({ onRequestClose: () => setOpen(false) })}
              </PopperContainer>
            </div>
          </a.div>
        </Portal>
      }
    </>
  )

}

export default PopOver
