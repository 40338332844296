import React from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'

import Spin from '../../components/Spin'
import { Flex } from '../../components/FlexBox'
import ContextMenu from '../../components/ContextMenu'
import PopOver from '../../components/PopOver'
import { Menu, MenuItem } from '../../components/Menu'
import { MoreIcon } from '../../components/Icons'
import { TableContainer, Table, TableHeaderRow, TableHeaderCell, TableRow, TableCell } from '../../components/Table'
import { useMessageContext } from '../../contexts/MessageContext'

const BASE_URL = 'portfolio'

const LIST = gql`
  query {
    portfolios {
      id
      price
      ExamId
      ExamName
      ExamModality
      ExamSpecialty
      ExamBodyPart
    }
  }
`

const DELETE = gql`
  mutation ($id: ID!) {
    deletePortfolio(id: $id)
  }
`

function List({ data }) {
  const { message } = useMessageContext()
  const [deletePortfolio] = useMutation(DELETE)

  const handleDelete = async id => {
    if (!window.confirm('Você tem certeza que deseja excluir este portfolio?')) return false

    const update = (cache, { data: { deletePortfolio } }) => {
      if (!deletePortfolio) return false
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, portfolios: cacheList.portfolios.filter(item => item.id !== id) } })
    }

    try {
      await deletePortfolio({ variables: { id }, update })
      message('Portfolio excluído com sucesso!')
    } catch(error) {
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  return (
    <TableContainer>
      <Table>
        <TableHeaderRow>
          <TableHeaderCell>Nome</TableHeaderCell>
          <TableHeaderCell></TableHeaderCell>
        </TableHeaderRow>
        {data.portfolios.map(item => (
          <ContextMenu key={item.id} render={() => (
            <Menu>
              <MenuItem to={`/${BASE_URL}/${item.id}/editar${window.location.search}`}>Editar</MenuItem>
              <MenuItem onClick={() => { handleDelete(item.id) }}>Excluir</MenuItem>
            </Menu>
          )}>
            <TableRow>
              <TableCell data-title='Nome'>{item.ExamName}</TableCell>
              <TableCell width={40} lineHeight={0}>
                <PopOver placement='left' modifiers={{ preventOverflow: { enabled: false }, hide: { enabled: false } }} render={() => (
                  <Menu>
                    <MenuItem to={`/${BASE_URL}/${item.id}/editar${window.location.search}`}>Editar</MenuItem>
                    <MenuItem onClick={() => { handleDelete(item.id) }}>Excluir</MenuItem>
                  </Menu>
                )}>
                  <Flex cursor='pointer'><MoreIcon /></Flex>
                </PopOver>
              </TableCell>
            </TableRow>

          </ContextMenu>
        ))}
      </Table>
    </TableContainer>
  )
}

const ListQuery = () => {
  const { loading, error, data } = useQuery(LIST)
  if (loading) return <Spin />
  if (error) return error.message
  return <List data={data} />
}

export default ListQuery
