import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

function Dashboard() {
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      history.push('/estudos')
    }, 0)
  }, [history])

  return null
}

export default Dashboard
