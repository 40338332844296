import React from 'react'
import { css } from 'styled-components/macro'
import Tooltip from './Tooltip'
import { Box } from './FlexBox'

function avatarLetters(name) {
  const words = name.trim().split(' ')
  if (words.length === 1) {
    return words[0][0]
  }
  if (words.length > 1) {
    return `${words[0][0]}${words.slice(-1)[0][0]}`
  }
}

function Avatar({ imageUrl, name = '', size = 48, customCss = '', onClick }) {

  if (!imageUrl && name) {
    return <Tooltip tooltip={name} offset={8} immediate delay={0}><Box onClick={onClick} className='avatar' bg='grey100' css={css`position: relative; box-sizing: content-box; width: ${size}px; height: ${size}px; border-radius: 50%; overflow: hidden; display: flex; align-items: center; justify-content: center; font-size: 12px; font-weight: 700; color: hsla(0, 0%, 0%, 0.5); ${customCss}`}>{avatarLetters(name)}</Box></Tooltip>
  }
  return (
    <Tooltip tooltip={name} offset={8} immediate delay={0}><div onClick={onClick} className='avatar' css={css`position: relative; box-sizing: content-box; width: ${size}px; height: ${size}px; border-radius: 50%; overflow: hidden; ${customCss}`}>
      {imageUrl && <img src={imageUrl} alt='' draggable='false' css={css`width: 100%; height: 100%;`} />}
    </div></Tooltip>
  )
}

export default Avatar



// {item.assignedTo.avatar ? (
//   <Box css={css`position: relative; width: 32px; height: 32px; border-radius: 50%; overflow: hidden; border: 1px solid hsla(0, 0%, 0%, 0.2);`}>
//     <Tooltip tooltip={item.assignedTo.name} offset={8} immediate delay={0}><img src={item.assignedTo.avatar} alt='' draggable='false' css={css`width: 100%; height: 100%;`} /></Tooltip>
//   </Box>
// ) : (
//   <Tooltip tooltip={item.assignedTo.name} offset={8} immediate delay={0}><Box bg='grey200' css={css`position: relative; width: 32px; height: 32px; border-radius: 50%; overflow: hidden; display: flex; align-items: center; justify-content: center; font-size: 12px; font-weight: 700; color: hsla(0, 0%, 0%, 0.5);`}>{avatarLetters(item.assignedTo.name)}</Box></Tooltip>
// )}
