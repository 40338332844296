import React from 'react'
import { Field } from 'formik'

import { Box } from '../../components/FlexBox'
import Label from '../../components/Label'
import Button from '../../components/ButtonWithLoading'
import FieldInput from '../../components/FieldInput'
import FieldSelect from '../../components/FieldSelect'
import useFocusOnLoad from '../../hooks/useFocusOnLoad'

function Form({ handleSubmit, units = [], isCreating, values, isSubmitting }) {

  useFocusOnLoad('name') // CHANGE

  const unitsOptions = units.map(item => ({ label: item.name, value: item.id }))

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label required>Nome</Label>
        <Field width='100%' name='name' autoComplete='new-name' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Label required>Email</Label>
        <Field type='email' width='100%' name='email' autoComplete='new-email' component={FieldInput} />
      </Box>

      {isCreating &&
        <Box mb={3}>
          <Label required>Senha</Label>
          <Field type='password' width='100%' name='password' autoComplete='new-password' component={FieldInput} />
        </Box>
      }

      <Box mb={3}>
        <Label required>Permissão</Label>
        <Field width='160px' name='role' component={FieldSelect} options={[{ label: 'Selecione...', value: '' }, { label: 'Usuário', value: 'USER' }, { label: 'Administrador', value: 'ADMIN' }, { label: 'Cliente', value: 'CUSTOMER' }]} />
      </Box>

      {values.role === 'CUSTOMER' &&
        <Box mb={3}>
          <Label>Este usuário é da unidade</Label>
          <Field width='160px' name='UnitId' component={FieldSelect} options={[{ label: 'Não é cliente', value: '' }, ...unitsOptions]} />
        </Box>
      }

      <Box mb={3}>
        <Button type='submit' loading={isSubmitting}>Salvar</Button>
      </Box>

    </form>
  )
}

export default Form
