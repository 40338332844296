import { Flex } from './FlexBox'
import React from 'react'
import styled from 'styled-components'

const ModalHeader = ({ children, className, ...rest }) => {
  return (
    <Flex p={3} alignItems='center' justifyContent='space-between' className={className} {...rest}>
      {children}
    </Flex>
  )
}

const StyledModalHeader = styled(ModalHeader)`
  background-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey100 ? props.theme.colors.grey100 : 'hsl(0 0% 96% / 1)'};
  border-bottom: 1px solid ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? props.theme.colors.grey200 : 'hsl(0 0% 86% / 1)'};
`

export default StyledModalHeader
