import React, { useState } from 'react'
import { useCombobox } from 'downshift'
import styled, { css } from 'styled-components/macro'

import { gql, useQuery } from '@apollo/client'
import Spin from '../../components/Spin'

import { Flex, Box } from '../../components/FlexBox'
import useFocusOnLoad from '../../hooks/useFocusOnLoad'

const DownshiftMenu = styled(Box)`
  height: 279px;
  overflow-y: auto;
  width: 400px;
  margin: 0;
  list-style: none;
  padding: 0;
  border-radius: 0 0 8px 8px;
`

const DownshiftMenuItem = styled(Flex)`
  padding: 0 16px;
  align-items: center;
  cursor: pointer;
  min-height: 64px;
  ${props => props.selected && `& .avatar{ border: 5px solid hsl(0 0% 0% / 0.3); }`}
  ${props => props.highlighted && `background-color: hsla(180, 19%, 66%, 1);`}
`

const GET = gql`
  query ($id: ID!) {
    order(id: $id) {
      id
      ExamModality
      unit {
        id
        name
        portfolios {
          id
          ExamId
          ExamName
          ExamModality
          ExamSpecialty
          ExamBodyPart
          PortfolioUnitId
          PortfolioUnitPrice
        }
      }
    }
  }
`

function ChangeExam({ onSelect, data: { order }, selectedId }) {

  const items = order.unit.portfolios.filter(item => item.ExamModality === order.ExamModality).sort((a, b) => a.ExamName.localeCompare(b.ExamName))

  useFocusOnLoad('user', true)

  const [inputItems, setInputItems] = useState(items)

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    setHighlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    itemToString: item => item ? item.ExamName : '',
    items: inputItems,
    initialIsOpen: true,
    isOpen: true,
    initialSelectedItem: selectedId ? { ...items.find(item => item.id === selectedId) } : null,
    initialInputValue: selectedId ? { ...items.find(item => item.id === selectedId) }['ExamName'] : '',
    onSelectedItemChange: ({ selectedItem }) => {
      onSelect({...selectedItem, OrderId: selectedId})
    },
    onInputValueChange: ({ inputValue }) => {
      setInputItems(items.filter(item => item.ExamName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(inputValue.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))))
      // setInputItems(items.filter(item => item.ExamName.toLowerCase().startsWith(inputValue.toLowerCase())))
      setHighlightedIndex(0)
    },
  })

  // React.useEffect(() => {
  //   if (selectedId) {
  //     setInputItems(inputItems.filter(item => item.ExamName.toLowerCase().startsWith({ ...items.find(item => item.id === selectedId) }['ExamName'].toLowerCase())))
  //     setHighlightedIndex(0)
  //   }
  // }, [])

  return (
    <Box css={css`box-shadow: hsla(0, 0%, 0%, 0.31) 7px 7px 16px 0px; border-radius: 8px;`}>
      {/* <label {...getLabelProps()}>Choose an element:</label> */}

      <Flex css={css`border-radius: 8px 8px 0 0; justify-content: space-between; align-items: center; background: hsla(180, 19%, 66%, 1); border-bottom: 1px solid hsla(180, 19%, 56%, 1);`} {...getComboboxProps()}>
        <Flex css={css`padding-left: 8px;`}>
          <label {...getLabelProps()}><svg viewBox='0 0 24 24' width='24' height='24'><path fill='currentColor' d='M15.009 13.805h-.636l-.22-.219a5.184 5.184 0 0 0 1.256-3.386 5.207 5.207 0 1 0-5.207 5.208 5.183 5.183 0 0 0 3.385-1.255l.221.22v.635l4.004 3.999 1.194-1.195-3.997-4.007zm-4.808 0a3.605 3.605 0 1 1 0-7.21 3.605 3.605 0 0 1 0 7.21z' /></svg></label>
        </Flex>
        <Flex css={css`flex: 1;`}>
          <input {...getInputProps()} name='user' placeholder='Buscar exame' css={css`width: calc(100% - 23px); padding: 10px 12px; border: none; appearance: none; outline: none; background: transparent; font-size: 15px; color: inherit;`} />
        </Flex>
        {/* <Flex css={css`padding-right: 8px; cursor: pointer;`} onClick={() => onRequestClose()}>
          <svg viewBox='0 0 24 24' width='24' height='24'><path fill='currentColor' d='M17.25 7.8L16.2 6.75l-4.2 4.2-4.2-4.2L6.75 7.8l4.2 4.2-4.2 4.2 1.05 1.05 4.2-4.2 4.2 4.2 1.05-1.05-4.2-4.2 4.2-4.2z' /></svg>
          <button type='button' {...getToggleButtonProps()} aria-label='toggle menu'>&#8595;</button>
        </Flex> */}
      </Flex>
      <DownshiftMenu {...getMenuProps()} bg='grey200'>
        {isOpen &&
          inputItems.map((item, index) => (
            <DownshiftMenuItem highlighted={highlightedIndex === index} selected={item.id === selectedId}  key={`${item.id}${index}`} {...getItemProps({ item: item.ExamName, index })}>
              <Flex>{item.ExamModality} - {item.ExamName}</Flex>
            </DownshiftMenuItem>
          ))}
      </DownshiftMenu>
    </Box>
  )
}

function ChangeExamQuery({ onSelect, id }) {

  const { loading, error, data, refetch } = useQuery(GET, { fetchPolicy: 'network-only', variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  return (
    <ChangeExam data={data} onSelect={onSelect} selectedId={id} />
  )

}

export default ChangeExamQuery
