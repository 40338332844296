import React from 'react'
import QRCode from 'qrcode.react'
import loadImage from 'blueimp-load-image'
import Blitz from 'blitz-resize'

function readFile(file) {
  return new Promise(resolve => {
    const fr = new FileReader()
    fr.onload = () => resolve(fr.result)
    fr.readAsDataURL(file)
  })
}

function readImage(src) {
  return new Promise(resolve => {
    const img = new Image()
    img.onload = function () {
      resolve(this)
    }
    img.src = src
  })
}

function blobToBase64(blob) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })
}

function imageToBlob(image) {
  return new Promise(resolve => {
    image.toBlob(function (blob) {
      resolve(blob)
    }, 'image/jpeg')
  })
}

function Lab() {
  const url = 'http://10.0.10.143:3000/lab'

  const [log, setLog] = React.useState('')
  const [src, setSrc] = React.useState('')

  const handleChangeFiles2 = async files => {
    const file = files[0]

    setLog(current => `${current} \n file.name: ${file.name}  \n file.type: ${file.type} \n file.size: ${Math.round(file.size / 1000)}kb`)

    const blitz = Blitz.create()
    let resized = await blitz({
      source: file,
      maxWidth: 1200,
      outputFormat: 'jpg',
      output: 'blob',
      quality: 0.8
    })
    setLog(current => `${current} \n blobsize: ${Math.round(resized.size / 1000)}kb`)
    const base64 = await blobToBase64(resized)
    setLog(current => `${current} \n base64: ${base64}`)
    setSrc(window.URL.createObjectURL(resized))
  }

  const handleChangeFiles = async files => {
    const file = files[0]

    // https://github.com/blueimp/JavaScript-Load-Image
    const data = await loadImage(file, { meta: true, canvas: true, orientation: 1, maxWidth: 100 })
    const blob = await imageToBlob(data.image)
    const blob2 = await loadImage.replaceHead(blob, data.imageHead)
    const base64 = await blobToBase64(blob)
    setLog(current => `${current} \n base64: ${base64}`)
    setSrc(window.URL.createObjectURL(blob2))
    // const blobURL = (window.URL || window.webkitURL).createObjectURL(blob)

    // loadImage(file, { meta: true, canvas: true, orientation: 1, maxWidth: 600 })
    // .then(function (data) {
    //   return new Promise(function (resolve) {
    //     data.image.toBlob(function (blob) {
    //       data.blob = blob
    //       resolve(data)
    //     }, 'image/jpeg')
    //   })
    // })
    // .then(function (data) {
    //   return loadImage.replaceHead(data.blob, data.imageHead)
    // })
    // .then(function (blob) {
    //   setSrc(window.URL.createObjectURL(blob))
    //   // console.log('blob', blob)
    //   // do something with the new Blob object
    // })
    // .catch(function (err) {
    //   console.error(err)
    // })

    // const a = await loadImage(file, { meta: true, maxWidth: 600 })
    // setSrc(a.image.src)
    // console.log('a', a)

    setLog(current => `${current} \n file.name: ${file.name}  \n file.type: ${file.type} \n file.size: ${Math.round(file.size / 1000)}kb`)
    const src = URL.createObjectURL(file)
    setLog(current => `${current} \n src: ${src}`)

    const img = await readImage(src)
    setLog(current => `${current} \n img: ${img}`)
    const { width, height } = img
    setLog(current => `${current} \n width: ${width}`)
    setLog(current => `${current} \n height: ${height}`)

    const result = await readFile(file)
    setLog(current => `${current} \n result: ${result.length}`)
  }

  return (
    <>
      <pre>{log}</pre>
      Lab
      <input type='file' accept='image/*' capture='camera' id='file' className='inputfile' onChange={e => handleChangeFiles2(e.target.files)} multiple={false} />
      <label htmlFor='file'>Toque aqui e tire uma foto</label>

      <div style={{ padding: 100 }}>
        <QRCode value={url} renderAs='svg' size={256} />
      </div>

      <img src={src} />
    </>
  )
}

export default Lab
