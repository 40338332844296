import styled, { css } from 'styled-components'
import { Box } from './FlexBox'

const Label = styled(Box)`
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  &::after {
    ${props => props.required && css`content: '*';`}
  }
`

export default Label
