import React from 'react'
import { Field } from 'formik'

import { Box } from '../../components/FlexBox'
import Label from '../../components/Label'
import Button from '../../components/ButtonWithLoading'
import FieldInput from '../../components/FieldInput'
import useFocusOnLoad from '../../hooks/useFocusOnLoad'

function Form({ handleSubmit, isSubmitting }) {

  useFocusOnLoad('email')

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label>Email</Label>
        <Field autoComplete='email' width='100%' name='email' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Label>Senha</Label>
        <Field type='password' autoComplete='current-password' width='100%' name='password' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Button type='submit' loading={isSubmitting}>Login</Button>
      </Box>

    </form>
  )
}

export default Form
