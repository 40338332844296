import React, { useState } from 'react'
import { Field } from 'formik'
import { css } from 'styled-components/macro'
import Viewer from 'react-viewer'

import { Flex, Box } from '../../components/FlexBox'
import Spin from '../../components/Spin'
import Label from '../../components/Label'
import Button from '../../components/ButtonWithLoading'
import FieldInput from '../../components/FieldInput'
import FieldInputDate from '../../components/FieldInputDate'
import FieldTextarea from '../../components/FieldTextarea'
import FieldSelect from '../../components/FieldSelect'
import useFocusOnLoad from '../../hooks/useFocusOnLoad'
import useScrollToError from '../../hooks/useScrollToError'
import { IconPdf, CloseIcon } from '../../components/Icons'

import { asyncReduce, fileBase64, resizedImageBase64, humanFileSize, blobToBase64 } from '../../utils'

function replaceAttachmentUrl(url) {
  if (!url) return ''
  if (url.includes('https://s3.amazonaws.com/telerison.a/')) return url
  let fileName
  if (url.includes('https://telerison.a.s3.amazonaws.com')) {
    fileName = url.replace('https://telerison.a.s3.amazonaws.com/', '')
  } else if (url.includes('https://telerison.s3.amazonaws.com')) {
    fileName = url.replace('https://telerison.s3.amazonaws.com/', '')
  }
  return `https://s3.amazonaws.com/telerison.a/${fileName}`
}

function Form({ handleSubmit, unit, errors, isValid, isSubmitting, values, setFieldValue }) {

  useFocusOnLoad('PatientName')
  useScrollToError({ errors, isValid, isSubmitting })

  const [loading, setLoading] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(null)

  const handleChangeFiles = async (e, append = {}) => {
    setLoading(true)
    try {
      let files = await asyncReduce(e.target.files, async (acc, file) => {
        let result = null
        if (!file.type.includes('image')) {
          result = await fileBase64(file)
        } else {
          result = await resizedImageBase64(file, { maxWidth: 1000, maxHeight: 1000, quality: 1 })
        }
        return [ ...acc, { ...append, name: file.name, type: file.type, size: Math.round(file.size / 1000), realSize: Math.round((result.length * (3/4)) / 1000), base64: result } ]
      }, [])
      // setFieldValue('attachments', [...values.attachments, ...files])
      setFieldValue('addedAttachments', [...values.addedAttachments, ...files])
      setLoading(false)

    } catch(err) {
      console.error(err)
      setLoading(false)
    }
  }

  const handleRemoveAttachment = index => {
    setFieldValue('deletedAttachments', [...values.deletedAttachments, values.attachments[index].url])
    setFieldValue('attachments', values.attachments.filter((_, i) => index !== i))
  }

  const handlePasteMedicalOrder = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read()
      const clipboardItem = await clipboardItems[0]
      const blob = await clipboardItem.getType('image/png')
      const base64 = await blobToBase64(blob)
      // setFieldValue('attachments', [...values.attachments, { isMedicalOrder: true, name: null, type: 'image/png', size: null, realSize: null, base64 }])
      setFieldValue('addedAttachments', [...values.addedAttachments, { isMedicalOrder: true, name: null, type: 'image/png', size: null, realSize: null, base64 }])
    } catch(err) {
      console.error(err)
    }
  }

  const handlePaste = async () => {
    // https://web.dev/async-clipboard/
    // https://arnellebalane.com/blog/async-clipboard-api/
    // https://async-clipboard-text.glitch.me/
    // https://async-clipboard-api.glitch.me/
    try {
      const clipboardItems = await navigator.clipboard.read()
      const clipboardItem = await clipboardItems[0]
      const blob = await clipboardItem.getType('image/png')
      const base64 = await blobToBase64(blob)
      // setFieldValue('attachments', [...values.attachments, { name: null, type: 'image/png', size: null, realSize: null, base64 }])
      setFieldValue('addedAttachments', [...values.addedAttachments, { name: null, type: 'image/png', size: null, realSize: null, base64 }])
    } catch(err) {
      console.error(err)
    }
  }

  const images = [...values.attachments, ...values.addedAttachments].filter(item => {
    if (item.type && item.type.includes('image')) return true
    return false
  }).map(item => {
    if (item.url) return { src: replaceAttachmentUrl(item.url), alt: '' }
    if (item.base64) return { src: item.base64, alt: '' }
  })

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label>Unidade</Label>
        <Box>{unit.name}</Box>
      </Box>

      <Box mb={3}>
        <Label>Exame</Label>
        <Box>{values.ExamName}</Box>
      </Box>

      <Box mb={3}>
        <Label required>Nome do paciente</Label>
        <Field width='100%' name='PatientName' component={FieldInput} />
      </Box>

      <Flex flexDirection={['column', 'row']}>
        <Box mb={3} mr={[0, 1]} width={[1, 1/2]}>
          <Label required>Sexo do paciente</Label>
          <Field width='160px' name='PatientGender' component={FieldSelect} options={[{ label: 'Selecione...', value: '' }, { label: 'Masculino', value: 'M' }, { label: 'Feminino', value: 'F' }]} />
        </Box>

        <Box mb={3} ml={[0, 1]} width={[1, 1/2]}>
          <Label required>Idade do paciente</Label>
          <Flex alignItems='flex-start'>
            <Box mr={1} flex='1' maxWidth={130}>
              <Field type='number' onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()} width='100%' name='PatientAge' component={FieldInput} />
            </Box>
            <Flex css={css`height: 34px; align-items: center; margin-left: 4px;`}>anos</Flex>
          </Flex>
        </Box>
      </Flex>

      <Flex flexDirection={['column', 'row']}>
        <Box mb={3} mr={[0, 1]} width={[1, 1/2]}>
          <Label>Peso do paciente</Label>
          <Flex alignItems='flex-start'>
            <Box mr={1} flex='1' maxWidth={130}>
              <Field type='number' onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()} width='100%' name='PatientWeight' component={FieldInput} />
            </Box>
            <Flex css={css`height: 34px; align-items: center; margin-left: 4px;`}>kg</Flex>
          </Flex>
        </Box>

        <Box mb={3} ml={[0, 1]} width={[1, 1/2]}>
          <Label>Altura do paciente</Label>
          <Flex alignItems='flex-start'>
            <Box mr={1} flex='1' maxWidth={130}>
              <Field type='number' onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()} width='100%' name='PatientHeight' type='number' component={FieldInput} />
            </Box>
            <Flex css={css`height: 34px; align-items: center; margin-left: 4px;`}>cm</Flex>
          </Flex>
        </Box>
      </Flex>

      <Box mb={3}>
        <Label>Nome do médico solicitante</Label>
        <Field width='100%' name='PhysicianName' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Label>CRM do médico solicitante</Label>
        <Field width='100%' name='PhysicianCrm' component={FieldInput} />
      </Box>

      <Flex flexDirection={['column', 'row']}>
        <Box mb={3} mr={[0, 1]} width={[1, 1/2]}>
          <Label>Data do Exame</Label>
          <Field placeholder='DD/MM/AAAA' name='examinedAt' component={FieldInputDate} />
        </Box>

        <Box mb={3} ml={[0, 1]} width={[1, 1/2]}>
          <Label>SLA</Label>
          <Flex alignItems='flex-start'>
            <Box mr={1} flex='1' maxWidth={130}>
              <Field width='160px' name='sla' component={FieldSelect} options={[{ label: '48h', value: 48 }, { label: '24h', value: 24 }, { label: '12h', value: 12 }, { label: '6h', value: 6 }]} />
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Box mb={3}>
        <Label>Indicação clínica</Label>
        <Field width='100%' name='indication' component={FieldTextarea} />
      </Box>

      <Box mb={3}>
        <Label>Anamnese</Label>
        <Field width='100%' name='anamnesis' component={FieldTextarea} />
      </Box>

      <Box mb={3}>
        <Label>Pedido médico</Label>
        <Box>
          <input style={{ display: 'none' }} type='file' accept='image/png,image/jpeg,.pdf' id='file' className='inputfile' onChange={e => handleChangeFiles(e, { isMedicalOrder: true })} multiple />
          <Box mb={2}><label htmlFor='file'>Adicionar imagens</label></Box>
          <Box onClick={handlePasteMedicalOrder}>Colar</Box>
        </Box>
        <Box>
          {loading && <Spin />}
        </Box>
      </Box>

      <Box>
        {[...values.attachments, ...values.addedAttachments].filter(item => !!item.isMedicalOrder).map((file, index) => (
          <Box key={index} m={2} style={{ borderBottom: '1px solid hsla(203,20%,76%,1)', marginBottom: 10 }}>
            <Box cursor='zoom-in' onClick={() => setPreviewOpen(index)}>
              {['image/png', 'image/jpg', 'image/jpeg'].includes(file.type) &&
                <img height={100} alt='' src={file.base64 || replaceAttachmentUrl(file.url)} />
              }
              {['application/pdf'].includes(file.type) &&
                <IconPdf />
              }
            </Box>
            <Box onClick={() => handleRemoveAttachment(index)} display='inline-block' cursor='pointer' p={1}><CloseIcon height={16} /></Box>
            {file.name && <Box>{file.name}</Box>}
            {file.size && <Box>{humanFileSize(file.size * 1000)}</Box>}
          </Box>
        ))}
      </Box>

      <Box mb={3}>
        <Label>Outros arquivos</Label>
        <Box>
          <input style={{ display: 'none' }} type='file' accept='image/png,image/jpeg,.pdf' id='file2' className='inputfile' onChange={e => handleChangeFiles(e, { isMedicalOrder: false })} multiple />
          <Box mb={2}><label htmlFor='file2'>Adicionar imagens</label></Box>
          <Box onClick={handlePaste}>Colar</Box>
        </Box>
        <Box>
          {loading && <Spin />}
        </Box>
      </Box>

      <Box>
        {[...values.attachments, ...values.addedAttachments].filter(item => !item.isMedicalOrder).map((file, index) => (
          <Box key={index} m={2} style={{ borderBottom: '1px solid hsla(203,20%,76%,1)', marginBottom: 10 }}>
            <Box cursor='zoom-in' onClick={() => setPreviewOpen(index)}>
              {['image/png', 'image/jpg', 'image/jpeg'].includes(file.type) &&
                <img height={100} alt='' src={file.base64 || replaceAttachmentUrl(file.url)} />
              }
              {['application/pdf'].includes(file.type) &&
                <IconPdf />
              }
            </Box>
            <Box onClick={() => handleRemoveAttachment(index)} display='inline-block' cursor='pointer' p={1}><CloseIcon height={16} /></Box>
            {file.name && <Box>{file.name}</Box>}
            {file.size && <Box>{humanFileSize(file.size * 1000)}</Box>}
          </Box>
        ))}
      </Box>

      <Box mb={3}>
        <Button type='submit' loading={isSubmitting}>Salvar</Button>
      </Box>

      {/*previewOpen !== null &&
        <Viewer activeIndex={previewOpen} files={[...values.attachments, ...values.addedAttachments]} onRequestClose={() => setPreviewOpen(null)} />
      */}

      <Viewer scalable={false} disableMouseZoom={true} showTotal={false} zoomSpeed={1} visible={previewOpen !== null} onClose={() => setPreviewOpen(null) } images={images} />

    </form>
  )
}

export default Form
