import styled from 'styled-components/macro'

const Title = styled.span`
  font-size: 28px;
  letter-spacing: -0.5px;
  font-weight: 600;
`

export const SubTitle = styled.span`
  font-size: 18px;
  letter-spacing: -0.5px;
  font-weight: 600;
`

export default Title
