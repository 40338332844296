import React from 'react'
import { Formik } from 'formik'
import { gql, useQuery, useMutation } from '@apollo/client'

import Spin from '../../components/Spin'
import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

// CHANGE
const LIST = gql`
  query {
    users {
      id
      name
      email
      role
      unit {
        id
        name
      }
    }
  }
`

// CHANGE
const CREATE = gql`
  mutation ($name: String!, $email: String!, $password: String!, $role: String, $UnitId: ID) {
    createUser(name: $name, email: $email, password: $password, role: $role, UnitId: $UnitId) {
      id
      name
      email
      role
      unit {
        id
        name
      }
    }
  }
`

const GET_UNITS = gql`
  query {
    units {
      id
      name
    }
  }
`

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required(),
  password: yup.string().required(),
  role: yup.string().required(),
})

function New({ onRequestClose, data: { units } }) {

  const { message } = useMessageContext()
  const [createUser] = useMutation(CREATE) // CHANGE

  const initialValues = { name: '', email: '', password: '', role: '', UnitId: '' }

  const handleSubmit = async (variables, actions) => {

    const update = (cache, { data: { createUser } }) => { // CHANGE
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, users: [createUser, ...cacheList.users] } }) // CHANGE
    }

    try {
      await createUser({ variables, update }) // CHANGE
      message(`Usuário criado com sucesso!`) // CHANGE
      actions.resetForm()
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }

  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Novo Usuário</SubTitle></Flex>{/* // CHANGE */}
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} units={units} isCreating />}
        </Formik>

      </Box>
    </Flex>
  )
}

const NewQuery = ({ onRequestClose }) => {
  const { loading, error, data } = useQuery(GET_UNITS)
  if (loading) return <Spin />
  if (error) return error.message
  return <New onRequestClose={onRequestClose} data={data} />
}

export default NewQuery
