import React from 'react'
import styled, { css } from 'styled-components/macro'

export const ButtonRaw = styled.button`
  line-height: 0;
  padding: 0;
  border: 0;
  background: transparent;
  appearance: none;
  cursor: pointer;
  position: relative;
  user-select: none;
  outline: none;
`

const Button = ({ children, loading, ...rest}) => {
  return (
    <ButtonRaw {...rest}>
      {loading &&
        <i>
          <svg height={16} viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
            <circle cx='50' cy='50' fill='none' stroke='currentColor' strokeWidth='10' r='35' strokeDasharray='164.93361431346415 56.97787143782138' transform='rotate(305.844 50 50)'>
              <animateTransform attributeName='transform' type='rotate' calcMode='linear' values='0 50 50;360 50 50' keyTimes='0;1' dur='1s' begin='0s' repeatCount='indefinite' />
            </circle>
          </svg>
        </i>
      }
      <span style={{ display: 'inline-block', pointerEvents: 'none' }}>
        {children}
      </span>
    </ButtonRaw>
  )
}

export default styled(Button)`
  height: 36px;
  font-family: 'Inter', Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  border-radius: 4px;
  font-size: inherit;
  font-weight: 600;
  padding: 0 16px;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  box-shadow: ${props => props.theme && props.theme.shadows && props.theme.shadows.buttonShadow ? props.theme.shadows.buttonShadow : '0 2px 0 rgba(0, 0, 0, 0.045)'};
  color: white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  background-color: ${props => props.theme && props.theme.colors && props.theme.colors.primary ? props.theme.colors.primary : 'hsla(0, 0%, 43%, 1)'};
  ${props => props.loading && css`
    background-color: ${props => (props.theme && props.theme.colors && props.theme.colors.primary300) ? props.theme.colors.primary300 : 'hsla(203, 82%, 76%, 1)'};
    pointer-events: none;
  `}
  &:focus {
    outline: 3px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
    /* box-shadow: 0 0 0 3px ${props => (props.theme && props.theme.colors && props.theme.colors.primary200) ? props.theme.colors.primary200 : 'hsla(202, 81%, 86%, 1)'};
    border: 1px solid ${props => (props.theme && props.theme.colors && props.theme.colors.primary400) ? props.theme.colors.primary400 : 'hsla(205, 79%, 66%, 1)'}; */
  }
  &:disabled {
    background: ${props => (props.theme && props.theme.colors && props.theme.colors.primary200) ? props.theme.colors.primary200 : 'hsla(202,  81%, 86%, 1)'};
    cursor: not-allowed;
  }

  &::before {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: hsl(0 0% 100% / 0.2);
    border-radius: 4px;
    transform: scale(0);
    transition-property: transform, opacity;
  }
  &:hover::before {
    opacity: 1;
    outline: 1px solid transparent;
    transform: scale(1);
  }

  & > i {
    display: inline-block;
    line-height: 0;
    pointer-events: none;

    vertical-align: -2px;
    opacity: 0;
    margin-left: -16px;
    transition: margin-left .3s cubic-bezier(.645, .045, .355, 1);

    ${props => props.loading && css`
      opacity: 1;
      margin-left: 0px;
      margin-right: 8px;
    `}
  }
`
