import React, { useState } from 'react'
import { css } from 'styled-components/macro'
import { gql, useQuery } from '@apollo/client'
import { useQueryParams, NumberParam, withDefault } from 'use-query-params'

import Spin from '../../components/Spin'
import { Flex, Box } from '../../components/FlexBox'
import { FormatDateTime } from '../../components/Format'
import FormatAge from '../../components/FormatAge'
import ContextMenu from '../../components/ContextMenu'
import PopOver from '../../components/PopOver'
import Tooltip from '../../components/Tooltip'
import Checkbox from '../../components/OptimisticCheckbox'
import LinkButton from '../../components/LinkButton'
import { Menu, MenuItem } from '../../components/Menu'
import { MoreIcon } from '../../components/Icons'
import Portal from '../../components/Portal'
import { TableContainer, Table, TableHeaderRow, TableHeaderCell, TableRow, TableCell } from '../../components/Table'
import Pagination from '../../components/Pagination'

const BASE_URL = 'estudos'

function humanRecon(reconstruction) {
  if (reconstruction === 'ARECONSTRUIR') {
    return (<Tooltip tooltip='A reconstruir' offset={8} immediate delay={0}><svg style={{ display: 'block', margin: 'auto' }} viewBox='0 0 24 24' height={20} fill='hsla(174, 58%, 29%, 0.3)'><path d='M9,7H13A2,2 0 0,1 15,9V11C15,11.84 14.5,12.55 13.76,12.85L15,17H13L11.8,13H11V17H9V7M11,9V11H13V9H11M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M5,5V19H19V5H5Z' /></svg></Tooltip>)
  } else {
    return ''
  }
}

// function humanRecon(reconstruction) {
//   if (reconstruction === 'ARECONSTRUIR') {
//     return (<Tooltip tooltip='A reconstruir' offset={8} immediate delay={0}><svg style={{ display: 'block', margin: 'auto' }} viewBox='0 0 24 24' height={20} fill='hsla(174, 58%, 29%, 0.3)'><path d='M9,7H13A2,2 0 0,1 15,9V11A2,2 0 0,1 13,13H11V17H9V7M11,9V11H13V9H11M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M5,5V19H19V5H5Z' /></svg></Tooltip>)
//   } else if (reconstruction === 'SEMRECON') {
//     return (<Tooltip tooltip='Sem reconstrução' offset={8} immediate delay={0}><svg style={{ display: 'block', margin: 'auto' }} viewBox='0 0 24 24' height={20} fill='hsla(174, 58%, 29%, 1)'><path d='M9,7V17H11V13H13A2,2 0 0,0 15,11V9A2,2 0 0,0 13,7H9M11,9H13V11H11V9M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3Z' /></svg></Tooltip>)
//   } else {
//     return ''
//   }
// }

const LIST = gql`
  query($limit: Int, $offset: Int, $search: String, $from: Date, $to: Date, $modality: String, $shortname: String) {
    serverDate
    studies(limit: $limit, offset: $offset, search: $search, from: $from, to: $to, modality: $modality, shortname: $shortname) {
      count
      results {
        id
        createdAt
        PatientName
        CustomerName
        modality
        studyDateTag
        description
        imagesTotal
        PatientBirthDate
        orders
        reconstruction
      }
    }
  }
`

const PAGE_SIZE = 30


function List({ data: { serverDate, studies: { results: items, count }} }) {

  const [selectedIds, setSelectedIds] = useState([])

  const [{ p: page }, setQuery] = useQueryParams({ p: withDefault(NumberParam, 1) })

  const pageCount = Math.ceil(count > 0 ? count / PAGE_SIZE : 0)

  function diff(dt) {
    if (!dt) return 0
    return parseInt(((new Date(serverDate)) - (new Date(dt))) / 1000)
  }

  const handleSelectChange = (id, checked) => {
    if (checked) {
      setSelectedIds(previousSelectedIds => [...previousSelectedIds, id])
    } else {
      setSelectedIds(previousSelectedIds => previousSelectedIds.filter(item => item !== id))
    }
  }

  const handleSelectAllChange = checked => {
    if (checked) {
      setSelectedIds(items.map(item => Number(item.id)))
    } else {
      setSelectedIds([])
    }
  }

  const handlePageChange = ({ selected }) => {
    if (selected) {
      setQuery({ p: (selected + 1) })
    } else {
      setQuery({ p: undefined })
    }
  }

  return (
    <>
      <Box mb={1}>
        {(count > 0) && <>{`Total: ${count}`}</>}
      </Box>
      <TableContainer>
        <Table>
          <TableHeaderRow>
            <TableHeaderCell width={60}><Flex alignItems='center'><Checkbox checked={selectedIds.length === items.length} onChange={handleSelectAllChange} /></Flex></TableHeaderCell>
            <TableHeaderCell>Criado</TableHeaderCell>
            <TableHeaderCell>Cliente</TableHeaderCell>
            <TableHeaderCell>Realizado</TableHeaderCell>
            <TableHeaderCell>Nome do paciente</TableHeaderCell>
            <TableHeaderCell>Mod.</TableHeaderCell>
            <TableHeaderCell>Descrição</TableHeaderCell>
            <TableHeaderCell>Imagens</TableHeaderCell>
            <TableHeaderCell>Idade</TableHeaderCell>
            <TableHeaderCell>Rec.</TableHeaderCell>
            <TableHeaderCell>Sol.</TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableHeaderRow>
          {items.map(item => (
            <ContextMenu key={item.id} render={() => (
              <Menu>
                <MenuItem to={`/${BASE_URL}/${item.id}${window.location.search}`}>Detalhes</MenuItem>
              </Menu>
            )}>
              <TableRow>
                <TableCell data-title=''><Flex ai='center'><Checkbox checked={selectedIds.includes(Number(item.id))} onChange={checked => handleSelectChange(Number(item.id), checked)} /></Flex></TableCell>
                <TableCell data-title='Criado' style={{ whiteSpace: 'nowrap' }}><FormatDateTime locale='pt-br'>{item.createdAt}</FormatDateTime></TableCell>
                <TableCell data-title='Cliente'>{item.CustomerName}</TableCell>
                <TableCell data-title='Realizado' style={{ whiteSpace: 'nowrap' }}>{item.studyDateTag && `${item.studyDateTag.slice(6, 8)}/${item.studyDateTag.slice(4, 6)}/${item.studyDateTag.slice(0, 4)}`}</TableCell>
                <TableCell data-title='Nome do paciente'>{item.PatientName}</TableCell>
                <TableCell data-title='Modalidade'>{item.modality}</TableCell>
                <TableCell data-title='Descrição'>{item.description}</TableCell>
                <TableCell data-title='Imagens'>{item.imagesTotal}</TableCell>
                <TableCell data-title='Idade' style={{ whiteSpace: 'nowrap' }}><FormatAge format='YYYYMMDD'>{item.PatientBirthDate}</FormatAge></TableCell>
                <TableCell data-title='Reconstrução'>{humanRecon(item.reconstruction)}</TableCell>
                <TableCell data-title='Solicitações'>{item.orders.length}</TableCell>
                <TableCell width={40} lineHeight={0}>
                  <PopOver placement='left' modifiers={{ preventOverflow: { enabled: false }, hide: { enabled: false } }} render={() => (
                    <Menu>
                      <MenuItem to={`/${BASE_URL}/${item.id}${window.location.search}`}>Detalhes</MenuItem>
                    </Menu>
                  )}>
                    <Flex cursor='pointer'><MoreIcon /></Flex>
                  </PopOver>
                </TableCell>
              </TableRow>

            </ContextMenu>
          ))}
        </Table>
      </TableContainer>

      <Box textAlign='center'>
        <Pagination onPageChange={handlePageChange} currentPage={page} pageCount={pageCount} />
      </Box>

      {selectedIds.length > 0 &&
        <Portal>
          <Box width='-webkit-fill-available' p={3} m={[0, 4]} css={css`position: fixed; bottom: 0; box-shadow: 0px 2px 8px hsla(0, 0%, 0%, 0.15); border-radius: 8px; background-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? props.theme.colors.grey200 : 'white'};`}>
            <Box pb={1} pl={2}>{selectedIds.length} {'selecionado(s)'}</Box>
            <LinkButton to={`/${BASE_URL}/nova-solicitacao/${selectedIds.join('-').replace(/\[(.+?)\]/, '$1')}${window.location.search}`}>Criar solicitação</LinkButton>
          </Box>
        </Portal>
      }
    </>
  )
}

const ListQuery = ({ from, to, page, search, modality, shortname }) => {
  const variables = { limit: PAGE_SIZE, offset: (page - 1) * PAGE_SIZE, from, to, search, modality, shortname }
  const { loading, error, data } = useQuery(LIST, { fetchPolicy: 'network-only', variables })
  if (loading) return <Spin />
  if (error) return error.message
  return <List data={data} />
}

export default ListQuery
