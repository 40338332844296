import React, { useState, useRef } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { GlobalHotKeys } from 'react-hotkeys'
import { useQueryParams, StringParam } from 'use-query-params'

import { Flex, Box } from '../../components/FlexBox'
import Title from '../../components/Title'
import Tooltip from '../../components/Tooltip'
import Modal from '../../components/Modal'
import LinkButton from '../../components/LinkButton'
import Input from '../../components/Input'
import useDebounce from '../../hooks/useDebounce'
import useIgnoreMount from '../../hooks/useIgnoreMount'

import List from './List'
import New from './New'
import Edit from './Edit'

const BASE_URL = 'setores'

function Sectors() {
  const history = useHistory()

  const newMatch = useRouteMatch(`/${BASE_URL}/novo`)
  const editMatch = useRouteMatch(`/${BASE_URL}/:id/editar`)

  const inputSearchRef = useRef(null)

  const [{ q }, setQuery] = useQueryParams({ p: StringParam, q: StringParam })

  const [name, setName] = useState(q || '')
  const debouncedName = useDebounce(name.trim(), 500)

  useIgnoreMount(debouncedName, newDebouncedName => {
    setQuery({ p: 1, q: newDebouncedName ? newDebouncedName : undefined })
  })

  const handleNewKey = e => {
    e.preventDefault()
    if (!window.location.href.includes('editar')) history.push(`/${BASE_URL}/novo${window.location.search}`)
  }

  const handleSearchKey = e => {
    e.preventDefault()
    inputSearchRef.current.focus()
  }

  return (
    <>
      <GlobalHotKeys keyMap={{ NEW: ['n'], SEARCH: ['f'] }} handlers={{ NEW: handleNewKey, SEARCH: handleSearchKey }} />
      <Box p={4}>

        <Flex mb={4} alignItems='center' justifyContent='space-between'>
          <Box>
            <Title>Setores</Title>
          </Box>
          <Box>
            <Tooltip tooltip='Cadastrar unidade (n)'><LinkButton to={`/${BASE_URL}/novo${window.location.search}`}>Novo</LinkButton></Tooltip>
          </Box>
        </Flex>

        <Box>
          <Box mb={3}>
            <Input width='300px' ref={inputSearchRef} type='search' placeholder='Buscar por nome...' onChange={e => setName(e.target.value)} value={name} />
          </Box>
          <List />
        </Box>

      </Box>

      <Modal shown={!!newMatch} onCancel={() => history.push(`/${BASE_URL}${window.location.search}`)}>
        {({ onRequestClose }) => (
          <New onRequestClose={onRequestClose} />
        )}
      </Modal>

      <Modal shown={!!editMatch} onCancel={() => history.push(`/${BASE_URL}${window.location.search}`)}>
        {({ onRequestClose }) => (
          <Edit id={editMatch && editMatch.params && editMatch.params.id} onRequestClose={onRequestClose} />
        )}
      </Modal>

    </>
  )
}

export default Sectors
