import React from 'react'
import { Formik } from 'formik'
import { gql, useQuery, useMutation } from '@apollo/client'

import Spin from '../../components/Spin'
import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

const LIST_EXAMS = gql`
  query {
    exams {
      id
      name
    }
  }
`

const LIST = gql`
  query {
    portfolios {
      id
      price
      ExamId
      ExamName
      ExamModality
      ExamSpecialty
      ExamBodyPart
    }
  }
`

const CREATE = gql`
  mutation ($ExamId: ID!, $price: Int) {
    createPortfolio(ExamId: $ExamId, price: $price) {
      id
      price
      ExamId
      ExamName
      ExamModality
      ExamSpecialty
      ExamBodyPart
    }
  }
`

const validationSchema = yup.object().shape({
  ExamId: yup.string().required(),
})

function New({ data, onRequestClose }) {

  const { message } = useMessageContext()
  const [createPortfolio] = useMutation(CREATE)

  const initialValues = { ExamId: '', price: '' }

  const handleSubmit = async (variables, actions) => {

    const update = (cache, { data: { createPortfolio } }) => {
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, portfolios: [createPortfolio, ...cacheList.portfolios] } })
    }

    try {
      await createPortfolio({ variables, update })
      message(`Portfolio criado com sucesso!`)
      actions.resetForm()
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }

  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Novo item de portfólio</SubTitle></Flex>
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} exams={data.exams} />}
        </Formik>

      </Box>
    </Flex>
  )
}

const NewQuery = ({ id, onRequestClose }) => {
  const { loading, error, data } = useQuery(LIST_EXAMS)
  if (loading) return <Spin />
  if (error) return error.message
  return <New data={data} onRequestClose={onRequestClose} />
}

export default NewQuery

