import React, { forwardRef, useRef, useImperativeHandle  } from 'react'
import { Formik, Field } from 'formik'
import { gql, useQuery, useMutation } from '@apollo/client'

import yup from '../../validation'

import Spin from '../../components/Spin'
import Label from '../../components/Label'
import Button from '../../components/ButtonWithLoading'
import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import { FormatDateTime } from '../../components/Format'
import FieldInput from '../../components/FieldInput'
import FieldSelect from '../../components/FieldSelect'
import { useMessageContext } from '../../contexts/MessageContext'

export const LIST_MESSAGES = gql`
  query($code: ID!) {
    messages(code: $code) {
      id
      to
      by
      body
      conversation
      createdAt
    }
  }
`


const LIST = gql`
  query ($OrderId: ID!) {
    messages(OrderId: $OrderId) {
      id
      to
      by
      body
      conversation
      # OrderId
      # SectorId
      # readInfo
      createdAt
      # user {
      #   id
      #   name
      # }
      # sector {
      #   id
      #   name
      # }
    }
    sectors {
      id
      name
    }
  }
`

const LIST_SECTORS = gql`
  query {
    sectors {
      id
      name
    }
  }
`

const CREATE = gql`
  mutation ($OrderId: ID!, $to: JSON, $body: String!) {
    createMessage(OrderId: $OrderId, to: $to, body: $body)
  }
`

const humanBy = (item) => {
  if (item && item.by && item.by.name) {
    return item.by.name
  }
  return ''
}

const MessagesList = forwardRef(({ data: { messages }, refetch }, ref) => {
  // useImperativeHandle(ref, () => ({ refetch() { refetch() } }))
  useImperativeHandle(ref, () => ({ refetch: () => { refetch() } }))

  const [addMessageConversation] = useMutation(gql`mutation ($id: ID!, $text: String) { addMessageConversation(id: $id, text: $text) }`)

  const handleAddConversation = async id => {
    const text = prompt('Digite o comentário:')
    if (!text || !text.trim()) return false
    try {
      await addMessageConversation({ variables: { id, text } })
      refetch()
    } catch(error) {
      console.error(error)
    }
  }

  return (
    <>
        {messages.map(item => (
          <Box mb={5} key={item.id}>
            <Box mr={2} mb={1}><FormatDateTime locale='pt'>{item.createdAt}</FormatDateTime> - <strong>Criada</strong> por {humanBy(item)}</Box>
            <Box mb={3}><strong>Para:</strong> {item.to.name}</Box>
            <Box mb={3}><strong>Mensagem:</strong> {item.body}</Box>
            <Box ml={3}>
              {item.conversation && item.conversation.map((c, index) => (
                <Box mb={3} key={index}>
                  <Box mb={1}><FormatDateTime locale='pt'>{c.at}</FormatDateTime> - <strong>Comentada</strong> por {humanBy(c)}</Box>
                  <Box>{c.text}</Box>
                </Box>
              ))}
            </Box>
            <Box ml={3}>
              <Button small onClick={() => handleAddConversation(item.id)}>Adicionar comentário</Button>
            </Box>
          </Box>
        ))}


      {/* <Box mb={4}>
        {messages.map(item => (
          <Box mb={3} key={item.id}>
            <Box mr={2} mb={1}><FormatDateTime locale='pt'>{item.createdAt}</FormatDateTime> - <strong>De</strong> {item.user.name} - <strong>Para</strong> {item.sector.name}</Box>
            <Box>{item.body}</Box>
          </Box>
        ))}
      </Box>
      {messages.length > 0 && messages[0].readInfo &&
        <Box mb={4}><span><strong>Lido por:</strong> {messages[0].readInfo.map(item => item.userName).join(', ')}</span></Box>
      } */}
    </>
  )
})

const MessagesListQuery = forwardRef(({ OrderId }, ref) => {
  const { loading, error, data, refetch } = useQuery(LIST, { variables: { OrderId }, fetchPolicy: 'cache-and-network' })
  if (loading) return <Spin />
  if (error) return error.message
  return <MessagesList ref={ref} data={data} refetch={refetch} />
})


function Messages({ id, data: { sectors }, onRequestClose }) {

  const childRef = useRef()
  const scrollRef = useRef()

  const { message } = useMessageContext()
  const [createMessage] = useMutation(CREATE)

  async function handleSubmit(variables, actions) {
    try {
      const to = { from: 'TELERIS', id: Number(variables.SectorId),  name: sectors.find(item => Number(item.id) === Number(variables.SectorId)).name }
      const result = await createMessage({
        variables: { body: variables.body, OrderId: id, to },
        update: (cache, { data: { createMessage } }) => {
          // const cacheList = cache.readQuery({ query: LIST, variables: { OrderId: id } })
          // cache.writeQuery({ query: LIST, variables: { OrderId: id }, data: { ...cacheList, messages: [createMessage, ...cacheList.messages] } })
          actions.resetForm()
          childRef.current.refetch()
          setTimeout(() => {
            // childRef.current.scrollIntoView()
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight
          }, 500)
        }
      })

      if (result && result.data && result.data.createMessage && result.data.createMessage.success) message(`Mensagem adicionada com sucesso!`)
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Mensagens</SubTitle></Flex>
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto' ref={scrollRef}>

        <MessagesListQuery OrderId={id} ref={childRef} />

        {/*messages.map(item => (
          <Box mb={3} key={item.id}>
            <Box mr={2} mb={1}><FormatDateTime locale='pt'>{item.createdAt}</FormatDateTime> - <strong>De</strong> {item.user.name} - <strong>Para</strong> {item.sector.name}</Box>
            <Box>{item.body}</Box>
          </Box>
        ))*/}

        <Formik
          enableReinitialize
          initialValues={{ body: '', SectorId: '' }}
          onSubmit={handleSubmit}
          validationSchema={yup.object().shape({
            SectorId: yup.string().required(),
            body: yup.string().required(),
          })}
        >
          {({ handleSubmit, isSubmitting }) => {
            return (
              <form autoComplete='nope' onSubmit={handleSubmit}>
                <Box>
                  <Label required>Nova mensagem para</Label>
                  <Field width='100%' name='SectorId' component={FieldSelect} placeholder='Selecione o setor...' options={sectors.map(item => ({ value: item.id, label: item.name }))} />
                </Box>

                <Box>
                  <Label required>Mensagem</Label>
                  <Field width='100%' name='body' component={FieldInput} />
                </Box>

                <Box>
                  <Button type='submit' loading={isSubmitting}>Adicionar</Button>
                </Box>
              </form>
            )
          }}
        </Formik>

      </Box>
    </Flex>
  )
}

const MessagesQuery = ({ id, onRequestClose }) => {
  const { loading, error, data } = useQuery(LIST_SECTORS)
  if (loading) return <Spin />
  if (error) return error.message
  return <Messages id={id} data={data} onRequestClose={onRequestClose} />
}

export default MessagesQuery
