import React from 'react'
import { Formik } from 'formik'
import { gql, useQuery, useMutation } from '@apollo/client'

import Spin from '../../components/Spin'
import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

// CHANGE
const GET = gql`
  query ($id: ID!) {
    sector(id: $id) {
      id
      name
      color
      users {
        id
        name
      }
    }
  }
`

// CHANGE
const UPDATE = gql`
  mutation ($id: ID!, $name: String!, $color: String, $users: [ID]) {
    updateSector(id: $id, name: $name, color: $color, users: $users) {
      id
      name
      color
      users {
        id
        name
      }
    }
  }
`

const validationSchema = yup.object().shape({
  name: yup.string().required(),
})

function Edit({ data: { sector }, onRequestClose }) {

  console.log('sector', sector)

  const { message } = useMessageContext()
  const [updateSector] = useMutation(UPDATE) // CHANGE

  // const usersIds = sector.users.map(item => item.id)
  // console.log('usersIds', usersIds)
  const initialValues = { name: sector.name, color: sector.color || '', users: sector.users } // CHANGE

  const handleSubmit = async (variables, actions) => {
    console.log('variables', variables)
    try {
      // await updateSector({ variables: { id: sector.id, ...variables } }) // CHANGE
      console.log('variables.users', variables.users)
      await updateSector({
        variables: { ...variables, id: sector.id, users: variables.users.map(item => item.id) }
      })


      message(`Setor alterado com sucesso!`) // CHANGE
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Editar Setor</SubTitle></Flex>{/* // CHANGE */}
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
}

const EditQuery = ({ id, onRequestClose }) => {
  const { loading, error, data } = useQuery(GET, { variables: { id } })
  if (loading) return <Spin />
  if (error) return error.message
  return <Edit data={data} onRequestClose={onRequestClose} />
}

export default EditQuery
