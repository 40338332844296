import React from 'react'
import { Box } from './FlexBox'
import Text from './Text'
import { css } from 'styled-components/macro'

function EmptyList() {
  return (
    <Box my={5} css={css`@keyframes fadein { 0% { opacity: 0 } 66% { opacity: 0 } 100% { opacity: 1 } } & { text-align: center; animation: 0.5s ease 0s normal forwards 1 fadein; }`}>
      <Text medium fontWeight={300} color='grey400'>Nenhum registro encontrado</Text>
    </Box>
  )
}

export default EmptyList

