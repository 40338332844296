import React from 'react'
import { Formik } from 'formik'
import { gql, useMutation } from '@apollo/client'

import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

// CHANGE
const LIST = gql`
  query {
    sectors {
      id
      name
      color
      users {
        id
        name
      }
    }
  }
`

// CHANGE
const CREATE = gql`
  mutation ($name: String!, $color: String, $users: [ID]) {
    createSector(name: $name, color: $color, users: $users) {
      id
      name
      color
      users {
        id
        name
      }
    }
  }
`

const validationSchema = yup.object().shape({
  name: yup.string().required(),
})

function New({ onRequestClose }) {

  const scrollRef = React.useRef()
  const { message } = useMessageContext()
  const [createSector] = useMutation(CREATE) // CHANGE

  const initialValues = { name: '', color: '', users: [] }

  const handleSubmit = async (variables, actions) => {
    try {
      await createSector({
        variables: { ...variables, users: variables.users.map(item => item.id) },
        update: (cache, { data: { createSector } }) => {
          const cacheList = cache.readQuery({ query: LIST })
          cache.writeQuery({ query: LIST, data: { ...cacheList, sectors: [createSector, ...cacheList.sectors] } }) // CHANGE

          actions.resetForm()
          scrollRef.current.scrollTop = 0
        }
      })

      message(`Setor adicionado com sucesso!`) // CHANGE

      // setTimeout(() => {
      //   // childRef.current.scrollIntoView()
      //   scrollRef.current.scrollTop = scrollRef.current.scrollHeight
      // }, 500)
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Novo Setor</SubTitle></Flex>{/* // CHANGE */}
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto' ref={scrollRef}>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
}

export default New
