import React, { useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import tinykeys from 'tinykeys'

import Modal from '../../components/Modal'
import { Flex, Box } from '../../components/FlexBox'
import Title from '../../components/Title'
import LinkButton from '../../components/LinkButton'
import Tooltip from '../../components/Tooltip'

import List from './List'
import New from './New'
import Edit from './Edit'

const BASE_URL = 'portfolio'

function useKeyboardShortcuts(keyMap) {
  useEffect(() => {
    let unsubscribe = tinykeys(window, keyMap)
    return () => {
      unsubscribe()
    }
  })
}

function Portfolios() {
  const history = useHistory()

  const newMatch = useRouteMatch(`/${BASE_URL}/novo`)
  const editMatch = useRouteMatch(`/${BASE_URL}/:id/editar`)

  // useKeyboardShortcuts({
  //   'N': () => { history.push(`/${BASE_URL}/novo${window.location.search}`) }
  // })

  return (
    <>
      <Box p={4}>

        <Flex mb={4} alignItems='center' justifyContent='space-between'>
          <Box>
            <Title>Portfólio</Title>
          </Box>
          <Box>
            <Tooltip tooltip='Cadastrar item de portfólio'><LinkButton to={`/${BASE_URL}/novo${window.location.search}`}>Novo</LinkButton></Tooltip>
          </Box>
        </Flex>

        <Box>
          <List />
        </Box>

      </Box>

      <Modal shown={!!newMatch} onCancel={() => history.push(`/${BASE_URL}${window.location.search}`)}>
        {({ onRequestClose }) => (
          <New onRequestClose={onRequestClose} />
        )}
      </Modal>

      <Modal shown={!!editMatch} onCancel={() => history.push(`/${BASE_URL}${window.location.search}`)}>
        {({ onRequestClose }) => (
          <Edit id={editMatch && editMatch.params && editMatch.params.id} onRequestClose={onRequestClose} />
        )}
      </Modal>

    </>
  )
}

export default Portfolios
