import React from 'react'
import { Field } from 'formik'

import { gql, useQuery } from '@apollo/client'

import Spin from '../../components/Spin'
import { Flex, Box } from '../../components/FlexBox'
import Label from '../../components/Label'
import Checkbox from '../../components/Checkbox'
import Button from '../../components/Button'
import FieldInput from '../../components/FieldInput'
import useFocusOnLoad from '../../hooks/useFocusOnLoad'

function Form({ handleSubmit, values, setFieldValue, data: { notCostumerUsers: users } }) {

  useFocusOnLoad('name')
  // useScrollToError({ errors, isValid, isSubmitting })

  const selectedIds = values.users.map(u => u.id)

  const handleCheckboxChange = (id, checked) => {
    const user = users.find(item => item.id === id)
    if (checked) {
      setFieldValue('users', [...values.users, { id: user.id, name: user.name } ])
    } else {
      setFieldValue('users', [...values.users.filter(item => item.id !== user.id) ])
    }
  }

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={2}>
        <Label required>Nome</Label>
        <Field width='100%' name='name' component={FieldInput} />
      </Box>

      <Box mb={2}>
        <Label>Cor</Label>
        <Field width='100%' name='color' component={FieldInput} />
      </Box>

      <Box mb={4}>
        <Box mb={2}><Label>Usuários</Label></Box>
        {users.map(item => (
          <Flex ai='center' key={item.id}>
            <Box mr={2}><Checkbox checked={selectedIds.includes(item.id)} onChange={checked => handleCheckboxChange(item.id, checked)} /></Box>
            <Box>{item.name}</Box>
          </Flex>
        ))}
      </Box>

      <Box mb={3}>
        <Button type='submit'>Salvar</Button>
      </Box>

    </form>
  )
}

// export default Form

const FormQuery = ({ handleSubmit, values, setFieldValue }) => {
  const { loading, error, data } = useQuery(gql`query { notCostumerUsers { id, name } }`, { fetchPolicy: 'network-only' })
  if (loading) return <Spin />
  if (error) return error.message
  return <Form data={data} handleSubmit={handleSubmit} values={values} setFieldValue={setFieldValue} />
}

export default FormQuery
