import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'

const Input = forwardRef(({ width, ...rest }, ref) => {
  return (
    <input {...rest} ref={ref} />
  )
})

const StyledInput = styled(Input)`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  padding: 8px 12px;
  border: 1px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'hsl(0 0% 86% / 1)'};
  ${props => props.width && css`width: ${props.width};`}
  &:focus {
    outline: 3px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey100) ? props.theme.colors.grey100 : 'hsl(0 0% 96% / 1)'};
  }
`

export default StyledInput
