
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

// const NavLink = props => <Link {...props} getProps={({ isPartiallyCurrent }) => { return { className: isPartiallyCurrent ? `active ${props.className}` : props.className } }} />

function ButtonNavLink({ className, children, to }) {
  return (
    <NavLink className={className} to={to}>{children}</NavLink>
  )
}

const StyledButtonNavLink = styled(ButtonNavLink)`
  display: flex;
  align-items: center;
  color: hsla(0, 0%, 100%, 0.5);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  margin: 9px;
  padding: 4px 16px;
  cursor: pointer;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  border-radius: 15px;
  text-align: center;
  height: 30px;
  white-space: nowrap;
  &.active, &:hover {
    border: 1px solid hsla(0, 0%, 100%, 1);
    color: hsla(0, 0%, 100%, 1);
  }
`

export default StyledButtonNavLink
