import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { Box } from '../components/FlexBox'

export const Menu = styled(({ children, className, ...rest }) => (<Box pb={2} minWidth={100} className={className} {...rest}>{children}</Box>))``

export const MenuItem = ({ children, className, to, disableHover = false, ...rest }) => {

  const cssHover = disableHover ? css`` : css`
    &:hover {
      background-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
    }
  `

  if (to) return <Link id='MenuItem' to={to}><Box id='MenuItem' cursor='pointer' className={className} css={cssHover} py={2} px={3} {...rest}>{children}</Box></Link>
  return <Box id='MenuItem' cursor='pointer' className={className} css={cssHover} py={2} px={3} {...rest}>{children}</Box>
}
