import React from 'react'
import { Field } from 'formik'
import { Box } from '../../components/FlexBox'
import Label from '../../components/Label'
import Button from '../../components/Button'
import FieldTextarea from '../../components/FieldTextarea'
import FieldSelect from '../../components/FieldSelect'
import useScrollToError from '../../hooks/useScrollToError'

const options = [
  'Problemas com a aquisição das imagens',
  'Problemas com a reconstrução ou processamento das imagens',
  'Falta de imagens ou séries',
  'Problemas com as informações clínicas do paciente',
  'Problemas com a identificação do exame ou paciente',
  'Divergência entre as informações das imagens/exame/informações',
  'Outro'
]


function NewPendencyForm({ errors, handleSubmit, isSubmitting, isValid }) {

  useScrollToError({ errors, isValid, isSubmitting })

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label required>Motivo</Label>
        <Field placeholder='Selecione...' width='100%' name='description' component={FieldSelect} options={options.map(item => ({ label: item, value: item }))} />
      </Box>

      <Box mb={3}>
        <Label required>Detalhes</Label>
        <Field width='100%' name='text' component={FieldTextarea} />
      </Box>

      <Box mb={3}>
        <Button type='submit'>Salvar</Button>
      </Box>

    </form>
  )
}

export default NewPendencyForm
