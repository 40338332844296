import React, { useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { GlobalHotKeys } from 'react-hotkeys'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params'

import Spin from '../../components/Spin'
import EmptyList from '../../components/EmptyList'
import { Flex, Box } from '../../components/FlexBox'
import { SortLabel, TableContainer, Table, TableHeaderRow, TableHeaderCell, TableRow, TableCell } from '../../components/Table'
import { FormatDateTimeWithSeconds } from '../../components/Format'
import Pagination from '../../components/Pagination'
import { useMessageContext } from '../../contexts/MessageContext'
import { searchFor } from '../../utils'

const BASE_URL = 'exames'

const LIST = gql`

query($limit: Int, $offset: Int, $search: String, $from: Date, $to: Date) {
    actions(limit: $limit, offset: $offset, search: $search, from: $from, to: $to) {
      count
      results {
        id
        createdAt
        name
        details
        user {
          id
          name
        }
      }
    }
  }
`

const PAGE_SIZE = 30
const INITIAL_ORDER = 'name asc'

function List({ data: { actions: { results: items, count }} }) {

  const history = useHistory()

  const [{ o: order, p: page, q: search }, setQuery] = useQueryParams({ o: withDefault(StringParam, INITIAL_ORDER), p: withDefault(NumberParam, 1), q: StringParam })

  const pageCount = Math.ceil(count > 0 ? count / PAGE_SIZE : 0)

  let [field, ascOrDesc] = order ? order.split(' ') : ['', '']

  const [activeId, setActiveId] = useState(null)

  // ---------------- COLUMNS ----------------

  const columns = [
    { name: 'user', label: 'Usuário', nowrap: true, sortable: false },
    { name: 'name', label: 'Nome', nowrap: true, sortable: false },
    { name: 'details', label: 'Detalhes', nowrap: true, sortable: false },
  ]

  const tds = {
    user: (item, config) => <TableCell key='user' data-title='Usuários' style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.user.name}</TableCell>,
    name: (item, config) => <TableCell key='name' data-title='Nome' style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.name}</TableCell>,
    details: (item, config) => <TableCell key='details' data-title='Detalhes' style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{JSON.stringify(item.details)}</TableCell>,
  }

  // ---------------- COLUMNS END ----------------

  // const handleUp = () => {
  //   if (window.location.href.includes('editar')) return false
  //   const currentIndex = activeId ? ids.indexOf(activeId) : ids.length
  //   setActiveId(ids[clamp((currentIndex - 1), 0, (ids.length - 1))])
  // }

  // const handleDown = () => {
  //   if (window.location.href.includes('editar')) return false
  //   const currentIndex = activeId ? ids.indexOf(activeId) : -1
  //   setActiveId(ids[clamp((currentIndex + 1), 0, (ids.length - 1))])
  // }

  const handlePageChange = ({ selected }) => {
    if (selected) {
      setQuery({ p: (selected + 1) })
    } else {
      setQuery({ p: undefined })
      setActiveId(null)
    }
  }

  return (
    <>
      <Flex alignItems='center' justifyContent='space-between'>
        <Box>
          {(count > 0) && <>{`Total: ${count}`}</>}
        </Box>
      </Flex>

      <TableContainer count={count}>
        <Table>
          <TableHeaderRow>
            <TableHeaderCell style={{ whiteSpace: 'nowrap', minWidth: 140 }}>Data e hora</TableHeaderCell>
            {columns.map(item => (
              <TableHeaderCell key={item.label} width={item.width ? item.width : null} style={{ whiteSpace: item.nowrap ? 'nowrap' : null, paddingRight: item.paddingRight ? item.paddingRight : null }}>
                {item.sortable ? (<SortLabel label={item.label} headerField={item.name} currentField={field} currentAscOrDesc={ascOrDesc} onChange={o => setQuery({ o, p: undefined })} />) : item.label}
              </TableHeaderCell>
            ))}
            <TableHeaderCell></TableHeaderCell>
          </TableHeaderRow>
          {items.map(item => (
            <TableRow key={item.id} active={activeId === item.id} onMouseOver={() => setActiveId(item.id)}>
              <TableCell data-title='Criado'><FormatDateTimeWithSeconds locale='pt-br'>{item.createdAt}</FormatDateTimeWithSeconds></TableCell>
              {columns.map(td => tds[td.name](item, td))}
            </TableRow>
          ))}
        </Table>
      </TableContainer>

      <Box textAlign='center'>
        <Pagination onPageChange={handlePageChange} currentPage={page} pageCount={pageCount} />
      </Box>

    </>
  )
}

const ListQuery = ({ from, to, page, search }) => {
  const variables = { limit: PAGE_SIZE, offset: (page - 1) * PAGE_SIZE, from, to, search }
  const { loading, error, data } = useQuery(LIST, { fetchPolicy: 'network-only', variables })
  if (loading) return <Spin />
  if (error) return error.message
  return <List data={data} />
}

export default ListQuery
