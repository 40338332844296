
import styled from 'styled-components/macro'
import { style, flex, space, layout, typography, color, position, flexbox } from 'styled-system'

export const Box = styled.div(
  { boxSizing: 'border-box' },
  space,
  layout,
  typography,
  color,
  position,
  flex,
  style({ prop: 'cursor', cssProperty: 'cursor' }),
  props => props.css
)

export const Flex = styled(Box)(
  { display: 'flex' },
  flexbox,
  // style({ prop: 'jc', cssProperty: 'justifyContent' }),
  // style({ prop: 'ai', cssProperty: 'alignItems' })
)
