import React from 'react'

import { Flex, Box } from '../../components/FlexBox'
import Title from '../../components/Title'

import List from './List'

function Messages() {

  return (
    <>
      <Box p={4}>

        <Flex mb={4} alignItems='center' justifyContent='space-between'>
          <Box>
            <Title>Minhas Mensagens</Title>
          </Box>
        </Flex>

        <Box>
          <List />
        </Box>

      </Box>

    </>
  )
}

export default Messages
