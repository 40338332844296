import React, { useState } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'
import { GlobalHotKeys } from 'react-hotkeys'
import { css } from 'styled-components/macro'
import { useQueryParams, NumberParam, StringParam, withDefault } from 'use-query-params'
import Viewer from 'react-viewer'

import Modal from '../../components/Modal'
import Spin from '../../components/Spin'
import EmptyList from '../../components/EmptyList'
import { Flex, Box } from '../../components/FlexBox'
import Text from '../../components/Text'
import PopOver from '../../components/PopOver'
import Tooltip from '../../components/Tooltip'
import { Menu, MenuItem } from '../../components/Menu'
import { MoreIcon, DocumentIcon, GenericIcon } from '../../components/Icons'
import { FormatUtcDate, FormatDateTime } from '../../components/Format'
import { SortLabel, TableContainer, Table, TableHeaderRow, TableHeaderCell, TableRow, TableCell } from '../../components/Table'
import { useMessageContext } from '../../contexts/MessageContext'
import { useAppContext } from '../../contexts/AppContext'
import { secondsToHour } from '../../utils'
import Pagination from '../../components/Pagination'
import Checkbox from '../../components/OptimisticCheckbox'
import Portal from '../../components/Portal'
import Avatar from '../../components/Avatar'

import Assign from './Assign'
import ChangeExam from './ChangeExam'
import NewPendency from './NewPendency'
import Pendencies from './Pendencies'
import Messages from './Messages'

const BASE_URL = 'solicitacoes'

function replaceAttachmentUrl(url) {
  if (!url) return ''
  if (url.includes('https://s3.amazonaws.com/telerison.a/')) return url
  let fileName
  if (url.includes('https://telerison.a.s3.amazonaws.com')) {
    fileName = url.replace('https://telerison.a.s3.amazonaws.com/', '')
  } else if (url.includes('https://telerison.s3.amazonaws.com')) {
    fileName = url.replace('https://telerison.s3.amazonaws.com/', '')
  }
  return `https://s3.amazonaws.com/telerison.a/${fileName}`
}

function humanSla(item) {
  if (!item.remainingSla) return `/${item.sla}`
  let remainingSla = secondsToHour(item.remainingSla)
  if (remainingSla > item.sla) remainingSla = item.sla
  return `${remainingSla}/${item.sla}`
}

function humanDuration(minutes) {
  if (minutes < 60) return `${minutes}min`
  return `${Math.round(minutes/60)}h`
}

function humanRecon({ order, role, onClick }) {
  const { reconstruction, reconstructedAt, reconstructingAt } = order

  let cursor = 'auto'
  if (role.includes('ADMIN')) {
    cursor = 'pointer'
  }

  if (reconstructedAt) {
    const at = FormatDateTime({ locale: 'pt-br', children: reconstructedAt })
    return (<Tooltip tooltip={`Reconstruído em ${at}`} offset={8} immediate delay={0}><svg style={{ display: 'block', margin: 'auto' }} viewBox='0 0 24 24' height={20} fill='hsla(174, 58%, 29%, 1)'><path d='M9,7V17H11V13H11.8L13,17H15L13.76,12.85C14.5,12.55 15,11.84 15,11V9A2,2 0 0,0 13,7H9M11,9H13V11H11V9M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3Z' /></svg></Tooltip>)
  }
  if (reconstructingAt) {
    const at = FormatDateTime({ locale: 'pt-br', children: reconstructingAt })
    return (<Tooltip tooltip={`Reconstruindo em ${at}`} offset={8} immediate delay={0}><svg onClick={onClick} style={{ display: 'block', margin: 'auto', cursor }} viewBox='0 0 24 24' height={20} fill='hsla(36, 90%, 48%, 1)'><path d='M9,7V17H11V13H11.8L13,17H15L13.76,12.85C14.5,12.55 15,11.84 15,11V9A2,2 0 0,0 13,7H9M11,9H13V11H11V9M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3Z' /></svg></Tooltip>)
  }
  if (reconstruction === 'ARECONSTRUIR') {
    return (<Tooltip tooltip='A reconstruir' offset={8} immediate delay={0}><svg onClick={onClick} style={{ display: 'block', margin: 'auto', cursor }} viewBox='0 0 24 24' height={20} fill='hsla(174, 58%, 29%, 0.3)'><path d='M9,7H13A2,2 0 0,1 15,9V11C15,11.84 14.5,12.55 13.76,12.85L15,17H13L11.8,13H11V17H9V7M11,9V11H13V9H11M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M5,5V19H19V5H5Z' /></svg></Tooltip>)
  } else {
    return ''
  }
}

function HumanPatientName({ children }) {
  const { message } = useMessageContext()
  const [show, setShow] = useState(false)

  const handleCopy = async (text) => {
    await navigator.clipboard.writeText(text)
    message('Copiado!')
  }

  if (children) {
    return (
      <Flex alignItems='center' onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        <Box mr={1}>{children}</Box>
        <Box><Tooltip tooltip='Copiar' offset={8} immediate delay={0}><svg onClick={() => handleCopy(children)} style={{ display: 'block', cursor: 'pointer', visibility: show ? 'initial' : 'hidden' }} viewBox='0 0 24 24' height={16} fill='hsla(174, 58%, 29%, 0.3)'><path d='M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z' /></svg></Tooltip></Box>
      </Flex>)
  } else {
    return ''
  }
}

function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max)
}

function avatarLetters(name) {
  const words = name.trim().split(' ')
  if (words.length === 1) {
    return words[0][0]
  }
  if (words.length > 1) {
    return `${words[0][0]}${words.slice(-1)[0][0]}`
  }
}

function humanPendencies(OpenPendenciesQty, ClosedPendenciesQty, minutes = 0) {
  const total = (OpenPendenciesQty || 0) + (ClosedPendenciesQty || 0)
  if (total > 0 && OpenPendenciesQty === 0) {
    return (<Tooltip tooltip={`${ClosedPendenciesQty} pendência(s) encerrada(s)`} offset={8} immediate delay={0}><svg style={{ display: 'block', margin: 'auto' }} viewBox='0 0 24 24' height={20} fill='hsla(174, 58%, 29%, 0.3)'><path d='M9,7H13A2,2 0 0,1 15,9V11A2,2 0 0,1 13,13H11V17H9V7M11,9V11H13V9H11M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z' /></svg></Tooltip>)
  } else if (total > 0 && OpenPendenciesQty > 0) {
    let color = 'hsla(174, 58%, 29%, 1)'
    if (minutes > 480) color = 'hsla(0, 75%, 58%, 1)'
    if (minutes > 240 && minutes <= 480) color = 'hsla(37, 92%, 49%, 1)'
    return (<Tooltip tooltip={`${OpenPendenciesQty} pendência(s)`} offset={8} immediate delay={0}><svg style={{ display: 'block', margin: 'auto' }} viewBox='0 0 24 24' height={20} fill={color}><path d='M9,7V17H11V13H13A2,2 0 0,0 15,11V9A2,2 0 0,0 13,7H9M11,9H13V11H11V9M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z' /></svg></Tooltip>)
  } else {
    return ''
  }
}

function humanMessages(item) {
  if (item.lastMessageColor) return (<Tooltip tooltip={`Mensagens`} offset={8} immediate delay={0}><Link to={`/${BASE_URL}/${item.id}/mensagens${window.location.search}`} style={{ display: 'block' }}><svg style={{ display: 'block', margin: 'auto' }} viewBox='0 0 24 24' height={20} fill={item.lastMessageColor}><path d='M12,3C17.5,3 22,6.58 22,11C22,15.42 17.5,19 12,19C10.76,19 9.57,18.82 8.47,18.5C5.55,21 2,21 2,21C4.33,18.67 4.7,17.1 4.75,16.5C3.05,15.07 2,13.13 2,11C2,6.58 6.5,3 12,3Z' /></svg></Link></Tooltip>)
  return ''
}

function colorByName(name) {
  if (name === 'Musculo Esqueletico') return 'hsla(177, 55%, 52%, 1)'
  if (name === 'Medicina Interna') return 'hsla(202, 55%, 52%, 1)'
  if (name === 'Grupo Mama') return 'hsla(329, 62%, 71%, 1)'
  return 'hsla(36, 90%, 48%, 1)'
}

function Status({ order, status, role }) {

  // if (order.examinationCorrectedAt && order.examinationCode) {
  //   return <Tooltip tooltip='Corrigido' offset={8} immediate delay={0}><a href={`https://app.telerison.com/resultado/${order.examinationCode}`} target='_blank' style={{ display: 'inline-block', marginTop: 6 }}><GenericIcon path='M17,21L14.25,18L15.41,16.84L17,18.43L20.59,14.84L21.75,16.25 M 13 9 L 18.5 9 L 13 3.5 Z M 6 2 L 14 2 L 20 8 L 20 12.187 C 19.281 11.876 18.488 11.704 17.655 11.704 C 17.008 11.704 16.385 11.808 15.802 12 L 6 12 L 6 14 L 12.974 14 C 12.518 14.588 12.17 15.265 11.961 16 L 6 16 L 6 18 L 11.748 18 C 11.847 19.583 12.569 20.996 13.67 22 L 6 22 C 4.895 22 4 21.105 4 20 L 4 4 C 4 2.89 4.89 2 6 2 Z' /></a></Tooltip>
  // }

  // if (order.examinationUnderCorrectionAt && order.examinationCode) {
  //   return <Tooltip tooltip='Em correção' offset={8} immediate delay={0}><a href={`https://app.telerison.com/resultado/${order.examinationCode}`} target='_blank' style={{ display: 'inline-block', marginTop: 6 }}><GenericIcon path='M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H10V20.09L12.09,18H6V16H14.09L16.09,14H6V12H18.09L20,10.09V8L14,2H6M13,3.5L18.5,9H13V3.5M20.15,13C20,13 19.86,13.05 19.75,13.16L18.73,14.18L20.82,16.26L21.84,15.25C22.05,15.03 22.05,14.67 21.84,14.46L20.54,13.16C20.43,13.05 20.29,13 20.15,13M18.14,14.77L12,20.92V23H14.08L20.23,16.85L18.14,14.77Z' /></a></Tooltip>
  // }

  if (order.availableAt && order.examinationCode) {
    return <Tooltip tooltip='Liberado' offset={8} immediate delay={0}><a href={`https://app.telerison.com/resultado/${order.examinationCode}`} target='_blank' style={{ display: 'inline-block', marginTop: 6 }}><DocumentIcon /></a></Tooltip>
  }

  if (role.includes('ADMIN') && !status && order.assignedTo) return <>Atribuído</>
  if (!status) return <>Criado</>
  if (role === 'CUSTOMER') {
    if (order.availableAt) return <>Liberado</>
    if (order.signedAt) return <>Laudando</>
    if (status === 'STEP1' || status === 'STEP2' || status === 'STEP3') {
      return <>Laudando</>
    }
    if (status === 'SIGNED' && order.availableAt) return <>Liberado</>
    if (status === 'SIGNED') return <>Finalizado</>
  } else {
    if (order.availableAt) return <>Liberado</>
    if (order.signedAt) return <>Assinado</>
    if (order.step3At) return <>Etapa 3</>
    if (order.step2At) return <>Etapa 2</>
    if (order.step1At) return <>Etapa 1</>
    if (status === 'STEP1') return <>Etapa 1</>
    if (status === 'STEP2') return <>Etapa 2</>
    if (status === 'STEP3') return <>Etapa 3</>
    if (status === 'SIGNED') return <>Assinado</>
  }
  return ''
}

const LIST = gql`
  query($limit: Int, $offset: Int, $search: String, $orderBy: String, $messages: String, $from: Date, $to: Date, $modality: String, $specialty: String, $unit: Int, $reconstruction: String, $pendency: String, $assignedTo: String, $status: String) {
    serverDate
    orders(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy, messages: $messages, from: $from, to: $to, modality: $modality, specialty: $specialty, unit: $unit, reconstruction: $reconstruction, pendency: $pendency, assignedTo: $assignedTo, status: $status) {
      count
      results {
        id
        createdAt
        examinedAt
        attachments
        PatientName
        PatientGender
        PatientAge
        PatientWeight
        PatientHeight
        price
        assignedTo
        status
        sla
        remainingSla
        ExamModality
        ExamSpecialty
        examinationDetails
        examinationCode
        mainOrderId
        secondaryOrders
        availableAt
        signedAt
        step1At
        step2At
        step3At
        timeline
        OpenPendenciesQty
        ClosedPendenciesQty
        reconstruction
        reconstructedAt
        reconstructingAt
        limitAt
        pendenciesMinutes
        messagesDetails
        lastMessageColor
        examinationUnderCorrectionAt
        examinationCorrectedAt
        exam {
          id
          name
        }
        unit {
          id
          name
        }
      }
    }
  }
`

const DELETE = gql`
  mutation ($id: ID!) {
    deleteOrder(id: $id)
  }
`

const MAKE_ORDER_AVAILABLE = gql`
  mutation ($id: ID!) {
    makeOrderAvailable(id: $id)
  }
`

const CORRECT = gql`
  mutation ($id: ID!) {
    correctOrder(id: $id)
  }
`

const UPDATE_ORDER_EXAM = gql`
  mutation ($id: ID!, $PortfolioUnitId: ID, $ExamId: ID, $ExamName: String, $ExamSpecialty: String) {
    updateOrderExam(id: $id, PortfolioUnitId: $PortfolioUnitId, ExamId: $ExamId, ExamName: $ExamName, ExamSpecialty: $ExamSpecialty)
  }
`

const ADD_ORDER_EXAM = gql`
  mutation ($id: ID!, $PortfolioUnitId: ID, $ExamId: ID, $ExamName: String, $ExamSpecialty: String) {
    addOrderExam(id: $id, PortfolioUnitId: $PortfolioUnitId, ExamId: $ExamId, ExamName: $ExamName, ExamSpecialty: $ExamSpecialty)
  }
`

// const ASSIGN = gql`
//   mutation ($AssignedBy: ID, $AssignedTo: ID!, $tree: String!, $PatientName: String!, $ClinicId: ID!) {
//     assign(AssignedBy: $AssignedBy, AssignedTo: $AssignedTo, tree: $tree, PatientName: $PatientName, ClinicId: $ClinicId) {
//       id
//       name
//       avatar
//     }
//   }
// `

const ASSIGN = gql`
  mutation ($OrderId: ID!, $AssignedTo: ID!) {
    assign(OrderId: $OrderId, AssignedTo: $AssignedTo)
  }
`

const BATCH_ASSIGN = gql`
  mutation ($OrderIds: [ID!], $AssignedTo: ID!) {
    batchAssign(OrderIds: $OrderIds, AssignedTo: $AssignedTo)
  }
`

const UNASSIGN = gql`
  mutation ($OrderId: ID!) {
    unassign(OrderId: $OrderId)
  }
`

const RECONSTRUCT = gql`
  mutation ($id: ID!) {
    reconstruct(id: $id)
  }
`

const RECONSTRUCTING = gql`
  mutation ($id: ID!) {
    reconstructing(id: $id)
  }
`

const PAGE_SIZE = 30
const INITIAL_ORDER = 'remainingSla asc'


function List({ refetch, doctors, data: { serverDate, orders: { results: items, count }} }) {

  const { state: { me } } = useAppContext()
  const { message } = useMessageContext()
  const [deleteOrder] = useMutation(DELETE)
  const [makeOrderAvailable] = useMutation(MAKE_ORDER_AVAILABLE)
  const [correctOrder] = useMutation(CORRECT)
  const [updateOrderExam] = useMutation(UPDATE_ORDER_EXAM)
  const [addOrderExam] = useMutation(ADD_ORDER_EXAM)
  const [unassignOrder] = useMutation(UNASSIGN)
  const [assign] = useMutation(ASSIGN)
  const [batchAssign] = useMutation(BATCH_ASSIGN)
  const [reconstruct] = useMutation(RECONSTRUCT)
  const [reconstructing] = useMutation(RECONSTRUCTING)

  const [{ p: page, o: order }, setQuery] = useQueryParams({ p: withDefault(NumberParam, 1), o: withDefault(StringParam, INITIAL_ORDER) })
  let [field, ascOrDesc] = order ? order.split(' ') : ['', '']

  const pageCount = Math.ceil(count > 0 ? count / PAGE_SIZE : 0)

  const ids = items.map(item => item.id)

  const [selectedId, setSelectedId] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [activeId, setActiveId] = useState(null)
  const [assignModalOpen, setAssignModalOpen] = useState(false)
  const [changeExamModalOpen, setChangeExamModalOpen] = useState(false)
  const [addExamModalOpen, setAddExamModalOpen] = useState(false)

  const [previewAttachments, setPreviewAttachments] = useState([])

  const history = useHistory()
  const newPendencyMatch = useRouteMatch(`/${BASE_URL}/:id/nova-pendencia`)
  const pendenciesMatch = useRouteMatch(`/${BASE_URL}/:id/pendencias`)
  const messagesMatch = useRouteMatch(`/${BASE_URL}/:id/mensagens`)


  React.useEffect(() => {
    const handleVisibilityChange = e => {
      if (typeof document.hidden !== 'undefined' && !document.hidden) {
        refetch()
      }
    }
    if (typeof document.addEventListener !== 'undefined' && typeof document.hidden !== 'undefined') {
      document.addEventListener('visibilitychange', handleVisibilityChange, false)
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange)
      }
    }
  }, [refetch])


  // function diff(dt) {
  //   if (!dt) return 0
  //   return parseInt(((new Date(serverDate)) - (new Date(dt))) / 1000)
  // }

  const handleDelete = async id => {
    if (!window.confirm('Você tem certeza que deseja excluir esta solicitação?')) return false

    // const update = (cache, { data: { deleteOrder } }) => {
    //   if (!deleteOrder) return false
    //   const cacheList = cache.readQuery({ query: LIST })
    //   cache.writeQuery({ query: LIST, data: { ...cacheList, orders: cacheList.orders.filter(item => item.id !== id) } })
    // }

    try {
      await deleteOrder({ variables: { id } })
      refetch()
      message('Solicitação excluída com sucesso!')
    } catch(error) {
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  const handleMakeAvailable = async id => {
    if (!window.confirm('Você tem certeza que deseja liberar agora este laudo?')) return false

    try {
      const result = await makeOrderAvailable({ variables: { id } })
      console.log('result', result)
      refetch()
      message('Laudo liberado com sucesso!')
    } catch(error) {
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  const handleAssignModal = id => {
    const selectedOrder = items.find(item => item.id === id)
    setSelectedId(selectedOrder.assignedTo ? selectedOrder.assignedTo.id : null)
    setActiveId(id)
    setAssignModalOpen(true)
  }

  const handleChangeExamModal = id => {
    setActiveId(id)
    setChangeExamModalOpen(true)
  }

  const handleAddExamModal = id => {
    setActiveId(id)
    setAddExamModalOpen(true)
  }

  const handleUnassign = async id => {
    if (!window.confirm('Você tem certeza que deseja remover a atribuição desta solicitação?')) return false

    try {
      await unassignOrder({ variables: { OrderId: id } })
      refetch()
      message('Atribuição removida com sucesso!')
    } catch(error) {
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  const handleAssignKey = () => {
    if (!activeId) return false
    setAssignModalOpen(true)
  }

  const handleUp = () => {
    if (window.location.href.includes('editar')) return false
    const currentIndex = activeId ? ids.indexOf(activeId) : ids.length
    setActiveId(ids[clamp((currentIndex - 1), 0, (ids.length - 1))])
  }

  const handleDown = () => {
    if (window.location.href.includes('editar')) return false
    const currentIndex = activeId ? ids.indexOf(activeId) : -1
    setActiveId(ids[clamp((currentIndex + 1), 0, (ids.length - 1))])
  }

  const handleRefetch = () => {
    refetch()
    message(`Dados reacarregados com sucesso!`)
  }

  const handleAssign = async (option, onRequestClose) => {

    setSelectedId(option.id)

    const variables = {
      OrderId: activeId,
      AssignedTo: option.id,
    }

    // const update = (cache, { data: { assign } }) => {
    //   const cacheList = cache.readQuery({ query: LIST })
    //   const updatedOrders = cacheList.orders.map(item => {
    //     if (item.id === activeId) {
    //       return { ...item, assignedTo: assign }
    //     }
    //     return item
    //   })
    //   cache.writeQuery({ query: LIST, data: { ...cacheList, orders: updatedOrders } })
    //   onRequestClose()
    // }

    try {
      await assign({ variables })
      refetch()
      onRequestClose()
      message('Solicitação atribuída com sucesso!')
    } catch(error) {
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }

    // setSelectedId(option.id)
    // setTimeout(() => onRequestClose(), 300)
  }

  const handlePageChange = ({ selected }) => {
    if (selected) {
      setQuery({ p: (selected + 1) })
    } else {
      setQuery({ p: undefined })
    }
  }

  const handleUpdateOrderExam = async option => {
    try {
      await updateOrderExam({ variables: { id: option.OrderId, PortfolioUnitId: option.PortfolioUnitId, ExamId: option.ExamId, ExamName: option.ExamName, ExamSpecialty: option.ExamSpecialty } })
      refetch()
      // onRequestClose()
      setChangeExamModalOpen(false)
      message('Exame alterado com sucesso!')
    } catch(error) {
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  const handleAddOrderExam = async option => {
    if (!window.confirm(`Você tem certeza que deseja adicionar este exame ${option.ExamName}?`)) return false
    try {
      await addOrderExam({ variables: { id: option.OrderId, PortfolioUnitId: option.PortfolioUnitId, ExamId: option.ExamId, ExamName: option.ExamName, ExamSpecialty: option.ExamSpecialty } })
      refetch()
      setAddExamModalOpen(false)
      message('Exame criado com sucesso!')
    } catch(error) {
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  const handleReconstructClick = async (item) => {

    if (!item.reconstructingAt && !item.reconstructedAt) {
      if (!window.confirm(`Você tem certeza que deseja marcar como reconstruindo?`)) return false
      try {
        await reconstructing({ variables: { id: item.id } })
        refetch()
        message('Exame marcado como reconstruindo com sucesso!')
      } catch(error) {
        console.error(error)
        message(error.message.replace('GraphQL error: ', ''))
      }
    } else if (item.reconstructingAt && !item.reconstructedAt) {
      if (!window.confirm(`Você tem certeza que deseja marcar esta solicitação como reconstruído?`)) return false
      try {
        await reconstruct({ variables: { id: item.id } })
        refetch()
        message('Exame marcado como reconstruído com sucesso!')
      } catch(error) {
        console.error(error)
        message(error.message.replace('GraphQL error: ', ''))
      }
    }

  }

  const handleSelectChange = (id, checked) => {
    if (checked) {
      setSelectedIds(previousSelectedIds => [...previousSelectedIds, id])
    } else {
      setSelectedIds(previousSelectedIds => previousSelectedIds.filter(item => item !== id))
    }
  }

  const handleSelectAllChange = checked => {
    if (checked) {
      setSelectedIds(items.map(item => Number(item.id)))
    } else {
      setSelectedIds([])
    }
  }

  const handleBatchAssign = async doctorId => {
    if (!window.confirm(`Você tem certeza que atribuir as solicitações selecionadas?`)) return false
    try {
      const result = await batchAssign({ variables: { OrderIds: selectedIds, AssignedTo: doctorId } })
      const total = result.data.batchAssign
      message(`O total de ${total} solicitações foram atribuídas.`)
      setSelectedIds([])
      refetch()
    } catch(error) {
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }
  }

  const handleCorrection = async id => {

    if (!window.confirm('Você tem certeza que deseja autorizar a correção desta solicitação?')) return false

    // const update = (cache, { data: { deleteOrder } }) => {
    //   if (!deleteOrder) return false
    //   const cacheList = cache.readQuery({ query: LIST })
    //   cache.writeQuery({ query: LIST, data: { ...cacheList, orders: cacheList.orders.filter(item => item.id !== id) } })
    // }

    try {
      const result = await correctOrder({ variables: { id } })
      console.log('result', result)
      refetch()
      message('Correção autorizada com sucesso!')
    } catch(error) {
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }

  }

  return (
    <>
      <GlobalHotKeys allowChanges keyMap={{ ASSIGN: ['a'], DOWN: ['j'], UP: ['k'], REFETCH: ['r'] }} handlers={{ ASSIGN: handleAssignKey, DOWN: handleDown, UP: handleUp, REFETCH: handleRefetch }} />
      {items.length > 0 && (
        <>
          <Flex mb={1} justifyContent='space-between' alignItems='flex-end'>
            <Box>
              {(count > 0) && <>{`Total: ${count}`}</>}
            </Box>
            <Box>
              <Tooltip tooltip='Recarregar dados (r)'><Box cursor='pointer' onClick={handleRefetch}><GenericIcon path='M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z' /></Box></Tooltip>
            </Box>
          </Flex>
          {/* <Box mb={1}>
            {(count > 0) && <>{`Total: ${count}`}</>}
          </Box> */}
          <TableContainer>
            <Table>
              <TableHeaderRow>
                {me.role.includes('ADMIN') && <TableHeaderCell width={50}><Flex alignItems='center'><Checkbox checked={selectedIds.length === items.length} onChange={handleSelectAllChange} /></Flex></TableHeaderCell>}
                <TableHeaderCell style={{ whiteSpace: 'nowrap', width: 109 }}>
                  {me.role.includes('ADMIN') ? (
                    <SortLabel label='Criada' headerField='createdAt' currentField={field} currentAscOrDesc={ascOrDesc} onChange={o => setQuery({ o, p: undefined })} />
                  ) : (
                    <>Criada</>
                  )}
                </TableHeaderCell>
                <TableHeaderCell style={{ whiteSpace: 'nowrap', width: 90 }}>
                  {me.role.includes('ADMIN') ? (
                    <SortLabel label='Realizado' headerField='examinedAt' currentField={field} currentAscOrDesc={ascOrDesc} onChange={o => setQuery({ o, p: undefined })} />
                  ) : (
                    <>Realizado</>
                  )}
                </TableHeaderCell>
                {me.role.includes('ADMIN') && <TableHeaderCell>Unidade</TableHeaderCell>}
                <TableHeaderCell style={{ whiteSpace: 'nowrap', width: 48 }}>Mod.</TableHeaderCell>
                <TableHeaderCell>Especialidade</TableHeaderCell>
                <TableHeaderCell>Exame</TableHeaderCell>
                <TableHeaderCell>
                  {me.role.includes('ADMIN') ? (
                    <SortLabel label='Paciente' headerField='PatientName' currentField={field} currentAscOrDesc={ascOrDesc} onChange={o => setQuery({ o, p: undefined })} />
                  ) : (
                    <>Paciente</>
                  )}
                </TableHeaderCell>
                <TableHeaderCell>Anexos</TableHeaderCell>
                <TableHeaderCell>Rec.</TableHeaderCell>
                <TableHeaderCell>Pend.</TableHeaderCell>
                {me.role.includes('ADMIN') && <TableHeaderCell>&nbsp;</TableHeaderCell>}
                {me.role.includes('ADMIN') && <TableHeaderCell>Msg.</TableHeaderCell>}
                <TableHeaderCell style={{ textAlign: 'right' }}>
                  {me.role.includes('ADMIN') ? (
                    <SortLabel label='SLA' headerField='remainingSla' currentField={field} currentAscOrDesc={ascOrDesc} onChange={o => setQuery({ o, p: undefined })} />
                  ) : (
                    <>SLA</>
                  )}
                </TableHeaderCell>
                {me.role.includes('ADMIN') && <TableHeaderCell style={{ whiteSpace: 'nowrap', width: 108 }}>Limite</TableHeaderCell>}
                <TableHeaderCell>Status</TableHeaderCell>
                {/* <TableHeaderCell title='Resultado'>Res.</TableHeaderCell> */}
                {/* <TableHeaderCell>Preço</TableHeaderCell> */}
                {me.role.includes('ADMIN') && <TableHeaderCell style={{ whiteSpace: 'nowrap', width: 34 }}></TableHeaderCell>}
                <TableHeaderCell></TableHeaderCell>
              </TableHeaderRow>
              {items.map(item => (
                <TableRow key={item.id} active={activeId === item.id} onMouseOver={() => setActiveId(item.id)} css={item.mainOrderId ? css`font-weight: 300; color: hsla(174, 59%, 29%, 0.6);` : (item.secondaryOrders ? css`font-weight: 600;` : null) }>
                  {me.role.includes('ADMIN') && <TableCell data-title=''><Flex ai='center'><Checkbox checked={selectedIds.includes(Number(item.id))} onChange={checked => handleSelectChange(Number(item.id), checked)} /></Flex></TableCell>}
                  <TableCell data-title='Criada'><FormatDateTime locale='pt-br' short>{item.createdAt}</FormatDateTime></TableCell>
                  <TableCell data-title='Realizado'><FormatUtcDate>{item.examinedAt}</FormatUtcDate></TableCell>
                  {me.role.includes('ADMIN') && <TableCell data-title='Unidade'>{item.unit.name}</TableCell>}
                  <TableCell data-title='Modalidade'>{item.ExamModality}</TableCell>
                  <TableCell data-title='Especialidade'>{item.ExamSpecialty}</TableCell>
                  <TableCell data-title='Exame'>{item.exam?.name}</TableCell>
                  <TableCell data-title='Paciente'><HumanPatientName>{item.PatientName}</HumanPatientName></TableCell>
                  {/* <TableCell data-title='Sexo'>{item.PatientGender}</TableCell> */}
                  {/* <TableCell data-title='Idade'>{item.PatientAge}</TableCell> */}
                  <TableCell data-title='Anexos'>
                    <Box cursor='pointer' ta='center' onClick={() => setPreviewAttachments((item.attachments || []).map(attachment => ({ src: replaceAttachmentUrl(attachment.url) })))}>
                      <Text underline>{item.attachments && item.attachments.length && item.attachments.length > 0 ? item.attachments.length : ''}</Text>
                    </Box>
                  </TableCell>
                  <TableCell data-title='Reconstrução'>{humanRecon({ order: item, role: me.role, onClick: () => { if (me.role.includes('ADMIN')) handleReconstructClick(item) } })}</TableCell>
                  <TableCell data-title='Pendências' style={{ textAlign: 'center' }}><Link to={`/${BASE_URL}/${item.id}/pendencias${window.location.search}`}>{humanPendencies(item.OpenPendenciesQty, item.ClosedPendenciesQty, item.pendenciesMinutes)}</Link></TableCell>
                  {me.role.includes('ADMIN') && <TableCell data-title='Idade da pendência'>{(new Date(item.createdAt) > new Date('2021-01-11T03:00:00Z') && item.pendenciesMinutes) ? humanDuration(item.pendenciesMinutes) : ''}</TableCell>}
                  {me.role.includes('ADMIN') && <TableCell data-title='Mensagens'>{humanMessages(item)}</TableCell>}
                  <TableCell data-title='SLA' style={{ textAlign: 'right' }}>{humanSla(item)}</TableCell>
                  {me.role.includes('ADMIN') && <TableCell data-title='Limite'><FormatDateTime short locale='pt-br'>{item.limitAt}</FormatDateTime></TableCell>}
                  <TableCell data-title='Status' style={{ whiteSpace: 'nowrap' }}><Status order={item} status={item.status} role={me.role} /></TableCell>
                  {/* <TableCell data-title='Resultado'>{item.availableAt && item.examinationCode ? <a href={`https://app.telerison.com/resultado/${item.examinationCode}`} target='_blank' style={{ display: 'inline-block', marginTop: 6 }}><DocumentIcon /></a> : '' }</TableCell> */}
                  {/* <TableCell data-title='Preço'>{item.price}</TableCell> */}
                  {me.role.includes('ADMIN') && <TableCell data-title='Médico'>
                    {item.assignedTo ? (
                      <>
                        {item.assignedTo.avatar ? (
                          <Box css={css`position: relative; width: 32px; height: 32px; border-radius: 50%; overflow: hidden; border: 1px solid hsla(0, 0%, 0%, 0.2);`}>
                            <Tooltip tooltip={item.assignedTo.name} offset={8} immediate delay={0}><img src={item.assignedTo.avatar} alt='' draggable='false' css={css`width: 100%; height: 100%;`} /></Tooltip>
                          </Box>
                        ) : (
                          <Tooltip tooltip={item.assignedTo.name} offset={8} immediate delay={0}>
                            {item.assignedTo.isGroup ? (
                              <Box bg={colorByName(item.assignedTo.name)} css={css`position: relative; width: 32px; height: 32px; border-radius: 50%; overflow: hidden; display: flex; align-items: center; justify-content: center; font-size: 12px; font-weight: 700; color: white; border: 1px solid hsl(0deg 0% 46%); box-shadow: hsl(0deg 0% 38%) 1px 1px 0px, hsl(0deg 0% 32%) 2px 2px 0px;`}>{avatarLetters(item.assignedTo.name)}</Box>
                            ) : (
                              <Box bg='grey200' css={css`position: relative; width: 32px; height: 32px; border-radius: 50%; overflow: hidden; display: flex; align-items: center; justify-content: center; font-size: 12px; font-weight: 700; color: hsla(0, 0%, 0%, 0.5);`}>{avatarLetters(item.assignedTo.name)}</Box>
                            )}

                          </Tooltip>
                        )}
                      </>
                    ) : (
                      <Box cursor='pointer' onClick={() => handleAssignModal(item.id)} css={css`position: relative; width: 32px; height: 32px; border-radius: 50%; overflow: hidden; border: 1px solid hsla(0, 0%, 0%, 0.2);`} />
                    )}
                  </TableCell>}
                  <TableCell width={40} lineHeight={0}>
                    {me.role !== 'READONLY' && (
                      <PopOver placement='left' modifiers={{ preventOverflow: { enabled: false }, hide: { enabled: false } }} render={() => (
                        <Menu>
                          <MenuItem to={`/${BASE_URL}/${item.id}${window.location.search}`}>Detalhes</MenuItem>
                          {me.role.includes('ADMIN') && !item.OpenPendenciesQty && <MenuItem to={`/${BASE_URL}/${item.id}/nova-pendencia${window.location.search}`}>Nova pendência</MenuItem>}
                          {(!!item.OpenPendenciesQty || !!item.ClosedPendenciesQty) && <MenuItem to={`/${BASE_URL}/${item.id}/pendencias${window.location.search}`}>Pendências</MenuItem>}
                          {me.role.includes('ADMIN') && <MenuItem to={`/${BASE_URL}/${item.id}/mensagens${window.location.search}`}>Mensagens</MenuItem>}
                          {!item.assignedTo && !item.signedAt && <MenuItem to={`/${BASE_URL}/${item.id}/editar${window.location.search}`}>Editar</MenuItem>}
                          {me.role.includes('ADMIN') && !item.assignedTo && <MenuItem onClick={() => handleAssignModal(item.id)}>Atribuir...</MenuItem>}
                          {!item.assignedTo && <MenuItem onClick={() => handleChangeExamModal(item.id)}>Alterar exame...</MenuItem>}
                          {!item.signedAt && <MenuItem onClick={() => handleAddExamModal(item.id)}>Adicionar exame...</MenuItem>}
                          {me.role.includes('ADMIN') && item.assignedTo && !item.signedAt && <MenuItem onClick={() => handleUnassign(item.id)}>Remover atribuição</MenuItem>}
                          {me.role.includes('ADMIN') && item.availableAt && !item.examinationUnderCorrectionAt && <MenuItem onClick={() => handleCorrection(item.id)}>Autorizar correção</MenuItem>}
                          {me.role.includes('ADMIN') && item.signedAt && !item.availableAt && <MenuItem onClick={() => handleMakeAvailable(item.id)}>Liberar agora</MenuItem>}
                          {me.role.includes('ADMIN') && !item.assignedTo && !item.signedAt && <MenuItem onClick={() => { handleDelete(item.id) }}>Excluir</MenuItem>}
                        </Menu>
                      )}>
                        <Flex cursor='pointer'><MoreIcon /></Flex>
                      </PopOver>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>

          <Box textAlign='center'>
            <Pagination onPageChange={handlePageChange} currentPage={page} pageCount={pageCount} />
          </Box>
        </>
      )}

      {items.length === 0 && <EmptyList />}

      <Modal top={128} boxShadow='none' backdropColor='transparent' bg='transparent' maxWidth={400} shown={assignModalOpen} onCancel={() => setAssignModalOpen(false)}>
        {({ onRequestClose }) => (
          <Assign doctors={doctors} selectedId={selectedId} onRequestClose={onRequestClose} onSelect={option => handleAssign(option, onRequestClose)} />
        )}
      </Modal>

      <Modal top={128} boxShadow='none' backdropColor='transparent' bg='transparent' maxWidth={400} shown={changeExamModalOpen} onCancel={() => { setChangeExamModalOpen(false) }}>
        {({ onRequestClose }) => (
          <ChangeExam id={activeId} onRequestClose={onRequestClose} onSelect={handleUpdateOrderExam} />
        )}
      </Modal>

      <Modal top={128} boxShadow='none' backdropColor='transparent' bg='transparent' maxWidth={400} shown={addExamModalOpen} onCancel={() => { setAddExamModalOpen(false) }}>
        {({ onRequestClose }) => (
          <ChangeExam id={activeId} onRequestClose={onRequestClose} onSelect={handleAddOrderExam} />
        )}
      </Modal>

      <Modal shown={!!pendenciesMatch} onCancel={() => { refetch(); history.push(`/${BASE_URL}${window.location.search}`) }}>
        {({ onRequestClose }) => (
          <Pendencies id={pendenciesMatch && pendenciesMatch.params && pendenciesMatch.params.id} onRequestClose={onRequestClose} />
        )}
      </Modal>

      <Modal shown={!!newPendencyMatch} onCancel={() => { refetch(); history.push(`/${BASE_URL}${window.location.search}`) }}>
        {({ onRequestClose }) => (
          <NewPendency id={newPendencyMatch && newPendencyMatch.params && newPendencyMatch.params.id} onRequestClose={onRequestClose} />
        )}
      </Modal>

      <Modal shown={!!messagesMatch} onCancel={() => { refetch(); history.push(`/${BASE_URL}${window.location.search}`) }}>
        {({ onRequestClose }) => (
          <Messages id={messagesMatch && messagesMatch.params && messagesMatch.params.id} onRequestClose={onRequestClose} />
        )}
      </Modal>

      <Viewer scalable={false} disableMouseZoom={true} showTotal={false} zoomSpeed={1} visible={previewAttachments && previewAttachments.length && previewAttachments.length > 0} onClose={() => setPreviewAttachments([]) } images={previewAttachments} />

      {selectedIds.length > 0 &&
        <Portal>
          <Box width='-webkit-fill-available' p={3} m={[0, 4]} css={css`position: fixed; overflow: auto; bottom: 0; box-shadow: 0px 2px 8px hsla(0, 0%, 0%, 0.15); border-radius: 8px; background-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? props.theme.colors.grey200 : 'white'};`}>
            <Box pb={2} pl={2}>{selectedIds.length} {'selecionada(s)'}</Box>
            <Box pb={2} pl={2}><strong>Atribuir selecionada(s) para</strong> (as solicitações já atribuídas não serão alteradas):</Box>
            <Flex>
              {doctors.filter(item => !item.id.includes('g')).map(item => (
                <Avatar key={item.id} onClick={() => handleBatchAssign(item.id)} imageUrl={item.avatar} name={item.name} customCss={css`margin-right: 8px; cursor: pointer;`} />
              ))}
            </Flex>
            {/* <LinkButton to={`/${BASE_URL}/nova-solicitacao/${selectedIds.join('-').replace(/\[(.+?)\]/, '$1')}${window.location.search}`}>Criar solicitação</LinkButton> */}
          </Box>
        </Portal>
      }
    </>
  )
}

const ListQuery = ({ doctors, messages, from, to, page, search, orderBy, modality, specialty, unit, pendency, reconstruction, status, assignedTo }) => {
  const variables = { limit: PAGE_SIZE, offset: (page - 1) * PAGE_SIZE, messages, from, to, search, orderBy, modality, specialty, unit, pendency, reconstruction, status, assignedTo }
  const { loading, error, data, refetch } = useQuery(LIST, { fetchPolicy: 'network-only', variables })
  if (loading) return <Spin />
  if (error) return error.message
  return <List doctors={doctors} data={data} refetch={refetch} />
}

export default ListQuery
