import React, { useState, useMemo } from 'react'
import { css } from 'styled-components/macro'
import { gql, useQuery } from '@apollo/client'
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params'
import { Link } from 'react-router-dom'

import Spin from '../../components/Spin'
import Text from '../../components/Text'
import { Flex, Box } from '../../components/FlexBox'
import Tooltip from '../../components/Tooltip'
import PopOver from '../../components/PopOver'
import { Menu, MenuItem } from '../../components/Menu'
import { MoreIcon } from '../../components/Icons'
import { SortLabel, TableContainer, Table, TableHeaderRow, TableHeaderCell, TableRow, TableCell } from '../../components/Table'
import { FormatDateTime } from '../../components/Format'
import Pagination from '../../components/Pagination'
import { useMessageContext } from '../../contexts/MessageContext'
import useLocalStorage from '../../hooks/useLocalStorage'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import PortalAwareItem from '../../components/PortalAwareItem'
import { SettingsIcon, DragIndicatorIcon } from '../../components/Icons'
import Checkbox from '../../components/Checkbox'

import { reorder, orderBy, searchFor } from '../../utils'

const LIST = gql`
  query {
    myMessages {
      id
      createdAt
      body
      to
      by
      conversation
      OrderId
    }
  }
`

const PAGE_SIZE = 30
const INITIAL_ORDER = 'createdAt desc'

function List({ refetch, data: { myMessages: items } }) {

  // const history = useHistory()

  const [{ o: order, p: page, q: search }, setQuery] = useQueryParams({ o: withDefault(StringParam, INITIAL_ORDER), p: withDefault(NumberParam, 1), q: StringParam })

  // const filteredItems = useMemo(() => searchFor(items, search, 'name'), [items, search])
  const filteredItems = items

  const pageCount = Math.ceil(filteredItems.length > 0 ? filteredItems.length / PAGE_SIZE : 0)
  const offset = (page ? page - 1 : 0) * PAGE_SIZE

  let [field, ascOrDesc] = order ? order.split(' ') : ['', '']

  const paginatedItems = useMemo(() => orderBy(filteredItems, field, ascOrDesc).slice(offset, offset + PAGE_SIZE), [items, field, ascOrDesc, page, search])

  const { message } = useMessageContext()

  const [activeId, setActiveId] = useState(null)

  // ---------------- COLUMNS ----------------

  const initialColumns = [
    { name: 'by', label: 'De', nowrap: true, sortable: false },
    { name: 'to', label: 'Para', nowrap: true, sortable: false },
    { name: 'body', label: 'Mensagem', nowrap: false, sortable: false },
    { name: 'conversation', label: 'Comentários', nowrap: true, sortable: false },
    { name: 'seemore', label: '', nowrap: true, sortable: false },
    // { name: 'color', label: 'Cor', nowrap: true, sortable: true },
    // { name: 'users', label: 'Usuários', nowrap: false, sortable: false },
  ]

  const tds = {
    by: (item, config) => <TableCell key='by' data-title='De' style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.by.name}</TableCell>,
    to: (item, config) => <TableCell key='to' data-title='Para' style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.to.name}</TableCell>,
    body: (item, config) => <TableCell key='body' data-title='Mensagem' style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.body}</TableCell>,
    conversation: (item, config) => <TableCell key='conversation' data-title='Comentários' style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>
      {item.conversation.length} comentário(s)
    </TableCell>,
    seemore: (item, config) => <TableCell key='seemore' data-title='Comentários' style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}><Link to={`/solicitacoes?q=${item.OrderId}`}><Text underline>Ver Solicitação</Text></Link></TableCell>,
    // color: (item, config) => <TableCell key='color' data-title='Cor' style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.color}</TableCell>,
    // users: (item, config) => <TableCell key='users' data-title='Usuários' style={{ whiteSpace: config.nowrap ? 'nowrap' : null, paddingRight: config.paddingRight ? config.paddingRight : null }}>{item.users.map(item => item.name).join(', ')}</TableCell>,
  }

  // const [columns, setColumns] = useLocalStorage('unitsColumns', initialColumns)
  const [columns, setColumns] = useState(initialColumns)
  const [hiddenColumns, setHiddenColumns] = useLocalStorage('sectorsHiddenColumns', [])

  React.useEffect(() => {
    const handleVisibilityChange = e => {
      if (typeof document.hidden !== 'undefined' && !document.hidden) {
        refetch()
      }
    }
    if (typeof document.addEventListener !== 'undefined' && typeof document.hidden !== 'undefined') {
      document.addEventListener('visibilitychange', handleVisibilityChange, false)
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange)
      }
    }
  }, [])

  const handleDragEnd = result => {
    if (!result.destination) return
    if (result.destination.index === result.source.index) return
    const reorderedColumns = reorder(columns, result.source.index, result.destination.index)
    setColumns(reorderedColumns)
  }

  const handleChangeHiddenColumns = (columnName, checked) => {
    if (checked) {
      setHiddenColumns(currentHiddenColumns => {
        return currentHiddenColumns.filter(item => item !== columnName)
      })
    } else {
      setHiddenColumns(currentHiddenColumns => {
        return [...currentHiddenColumns, columnName]
      })
    }
  }

  // ---------------- COLUMNS END ----------------

  const handlePageChange = ({ selected }) => {
    if (selected) {
      setQuery({ p: (selected + 1) })
    } else {
      setQuery({ p: 1 })
      setActiveId(null)
    }
  }

  return (
    <>
      <Flex alignItems='center' justifyContent='space-between'>
        <Box>
          {(filteredItems.length > 0) && <>{`Total: ${filteredItems.length}`}</>}
        </Box>
        <Box py={1}>
          <PopOver render={() => (
            <Menu css={css`max-height: calc(100vh - 58px); overflow-y: scroll;`}>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId='list'>
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {columns.map((item, index) => (
                        <Draggable draggableId={item.name} index={index} key={item.name}>
                          {(provided, snapshot) => (
                            <PortalAwareItem innerRef={provided.innerRef} provided={provided} snapshot={snapshot}>
                              <div style={{ marginRight: 8 }} {...provided.dragHandleProps}><DragIndicatorIcon height={16} /></div>
                              <MenuItem disableHover pl={0}>
                                <Checkbox label={item.label} checked={!hiddenColumns.includes(item.name)} onChange={checked => handleChangeHiddenColumns(item.name, checked)} />
                              </MenuItem>
                            </PortalAwareItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Menu>
          )}>
            <Tooltip tooltip='Exibir/ocultar e reordenar colunas'><SettingsIcon height={16} style={{ cursor: 'pointer' }} /></Tooltip>
          </PopOver>
        </Box>
      </Flex>

      <TableContainer count={filteredItems.length}>
        <Table>
          <TableHeaderRow>
            <TableHeaderCell style={{ whiteSpace: 'nowrap', width: 140 }}>Criado</TableHeaderCell>
            {columns.filter(item => !hiddenColumns.includes(item.name)).map(item => (
              <TableHeaderCell key={item.label} width={item.width ? item.width : null} style={{ whiteSpace: item.nowrap ? 'nowrap' : null, paddingRight: item.paddingRight ? item.paddingRight : null }}>
                {item.sortable ? (<SortLabel label={item.label} headerField={item.name} currentField={field} currentAscOrDesc={ascOrDesc} onChange={o => setQuery({ o, p: 1 })} />) : item.label}
              </TableHeaderCell>
            ))}
            {/* <TableHeaderCell></TableHeaderCell> */}
          </TableHeaderRow>
          {paginatedItems.map(item => (
            <TableRow key={item.id} active={activeId === item.id} onMouseOver={() => setActiveId(item.id)}>
              <TableCell data-title='Criado'><FormatDateTime locale='pt-br'>{item.createdAt}</FormatDateTime></TableCell>
              {columns.filter(td => !hiddenColumns.includes(td.name)).map(td => tds[td.name](item, td))}
              {/* <TableCell width={40} lineHeight={0}>
                <PopOver placement='left' modifiers={{ preventOverflow: { enabled: false }, hide: { enabled: false } }} render={() => (
                  <Menu>
                    <MenuItem to={`/solicitacoes?q=${item.OrderId}`}>Ver solicitação</MenuItem>
                  </Menu>
                )}>
                  <Flex cursor='pointer'><MoreIcon /></Flex>
                </PopOver>
              </TableCell> */}
            </TableRow>
          ))}
        </Table>
      </TableContainer>

      <Box textAlign='center'>
        <Pagination onPageChange={handlePageChange} currentPage={page} pageCount={pageCount} />
      </Box>
    </>
  )
}

const ListQuery = () => {
  const { loading, error, data, refetch } = useQuery(LIST, { fetchPolicy: 'network-only' })
  if (loading) return <Spin />
  if (error) return error.message
  return <List data={data} refetch={refetch} />
}

export default ListQuery
