import React from 'react'
import { Field } from 'formik'

import { Box } from '../../components/FlexBox'
import Label from '../../components/Label'
import Button from '../../components/Button'
import FieldInput from '../../components/FieldInput'
import FieldSwitch from '../../components/FieldSwitch'
import useFocusOnLoad from '../../hooks/useFocusOnLoad'

function Form({ handleSubmit }) {

  useFocusOnLoad('name')

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label required>Nome</Label>
        <Field width='100%' name='name' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Label required>Modalidade</Label>
        <Field width='100%' name='modality' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Label required>Especialidade</Label>
        <Field width='100%' name='specialty' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Label required>Parte do Corpo</Label>
        <Field width='100%' name='bodyPart' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Label required>Árvore (laudo)</Label>
        <Field width='100%' name='examinationTree' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Label>Tem reconstrução?</Label>
        <Field name='hasReconstruction' component={FieldSwitch} />
        {/* <Switch checked={values.isComparative} onChange={checked => setFieldValue('isComparative', checked)} /> */}
      </Box>

      <Box mb={3}>
        <Button type='submit'>Salvar</Button>
      </Box>

    </form>
  )
}

export default Form
