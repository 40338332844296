import { desaturate, lighten } from 'polished'

// const materialColorsBlue = [
//   'hsla(231, 48%, 48%, 1)',
//   'hsla(231, 44%, 94%, 1)',
//   'hsla(232, 45%, 84%, 1)',
// ]

// const materialColorsGreen1 = [
//   'hsla(88, 50%, 53%, 1)',
//   'hsla(88, 52%, 94%, 1)',
//   'hsla(88, 51%, 86%, 1)',
// ]

// const materialColorsOrange = [
//   'hsla(36, 100%, 50%, 1)',
//   'hsla(37, 100%, 94%, 1)',
//   'hsla(36, 100%, 85%, 1)',
//   'hsla(37, 100%, 94%, 1)',
//   'hsla(36, 100%, 85%, 1)'
// ]

const materialColorsGreen = [
  'hsla(174, 100%, 29%, 1)',
  'hsla(177, 41%, 91%, 1)',
  'hsla(175, 41%, 79%, 1)',
  'hsla(174, 42%, 65%, 1)',
  'hsla(174, 42%, 51%, 1)'
]

const colors = materialColorsGreen

const themes = {
  // default: {
  //   primaryFontSize: '13px',
  //   primaryLineHeight: '1',
  //   primaryFontFamily: 'Inter',
  //   secondaryFontFamily: 'Inter',
  //   primaryFontWeight: 400,
  //   primaryTextColor: 'hsl(211, 20%, 43%)',
  //   bodyBgColor: 'white',
  //   colors: {
  //     grey100: 'hsl(210 20% 96% / 1)',
  //     grey200: 'hsl(201 19% 86% / 1)',
  //     primary100: 'hsl(210 20% 96% / 1)',
  //     primary: 'hsl(207 73% 57% / 1)', // 'hsl(211 61% 43% / 1)'
  //   }
  // },
  // material: {
  //   primaryFontSize: '13px',
  //   primaryLineHeight: '1',
  //   primaryFontFamily: 'Inter',
  //   secondaryFontFamily: 'Inter',
  //   primaryFontWeight: 400,
  //   primaryTextColor: 'hsl(232 25% 41% / 1)',
  //   bodyBgColor: 'white',
  //   colors: {
  //     grey100: 'hsl(231 44% 94% / 1)',
  //     grey200: 'hsl(232 45% 84% / 1)',
  //     primary100: 'hsl(210 20% 96% / 1)',
  //     primary: 'hsl(231 48% 48% / 1)',
  //   }
  // },
  default: {
    primaryFontSize: '13px',
    primaryLineHeight: '1',
    primaryFontFamily: 'Inter',
    secondaryFontFamily: 'Inter',
    primaryFontWeight: 400,
    colors: {
      primaryText: desaturate(0.4, colors[0]),
      bodyBg: 'white',
      grey100: lighten(0.03, desaturate(0.2, colors[1])),
      grey200: desaturate(0.2, colors[2]),
      grey300: desaturate(0.2, colors[3]),
      grey400: desaturate(0.2, colors[4]),
      primary100: colors[1],
      primary200: colors[2],
      primary300: colors[3],
      primary400: colors[4],
      primary: colors[0],
    }
  },
}

export default themes
