import React from 'react'
import { Formik } from 'formik'
import { gql, useMutation } from '@apollo/client'

import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

// CHANGE
const LIST = gql`
  query {
    units {
      id
      name
      shortname
    }
  }
`

// CHANGE
const CREATE = gql`
  mutation ($name: String!, $shortname: String) {
    createUnit(name: $name, shortname: $shortname) {
      id
      name
      shortname
    }
  }
`

const validationSchema = yup.object().shape({
  name: yup.string().required(),
})

function New({ onRequestClose }) {

  const { message } = useMessageContext()
  const [createUnit] = useMutation(CREATE) // CHANGE

  const initialValues = { name: '', shortname: '' }

  const handleSubmit = async (variables, actions) => {

    const update = (cache, { data: { createUnit } }) => { // CHANGE
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, units: [createUnit, ...cacheList.units] } }) // CHANGE
    }

    try {
      await createUnit({ variables, update }) // CHANGE
      message(`Unidade adicionada com sucesso!`) // CHANGE
      actions.resetForm()
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }

  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Nova Unidade</SubTitle></Flex>{/* // CHANGE */}
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
}

export default New
