import React from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'

import Spin from '../../components/Spin'
import Button from '../../components/Button'
import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import { FormatDateTime } from '../../components/Format'

const LIST = gql`
  query ($OrderId: ID!) {
    pendencies(OrderId: $OrderId) {
      id
      description
      by
      closedAt
      closeInfo
      conversation
      createdAt
    }
    users {
      id
      name
    }
  }
`

const ADD_CONVERSATION = gql`
  mutation ($id: ID!, $text: String) {
    addConversation(id: $id, text: $text)
  }
`

const CLOSE_PENDENCY = gql`
  mutation ($id: ID!, $text: String) {
    closePendency(id: $id, text: $text)
  }
`

const TextareaWithButtom = ({ onAdd }) => {
  const [text, setText] = React.useState('')

  const handleSubmit = (value) => {
    onAdd(value)
    setText('')
  }

  return (
    <Flex ai='center'>
      <Box mr={2}><textarea value={text} onChange={e => setText(e.target.value)} /></Box>
      <Box><Button onClick={() => handleSubmit(text)}>Adicionar</Button></Box>
    </Flex>
  )
}

function Pendencies({ data: { pendencies, users }, refetch, onRequestClose }) {

  const [closePendency] = useMutation(CLOSE_PENDENCY)
  const [addConversation] = useMutation(ADD_CONVERSATION)

  const humanBy = (item, closed = false) => {
    if (closed) {
      if (item && item.closeInfo && item.closeInfo.by && item.closeInfo.by.name) return item.closeInfo.by.name
      return ''
    }
    if (item && item.by && item.by.name) {
      return item.by.name
    }
    return ''
  }

  const handleClosePendency = async id => {
    const text = prompt('Digite como a pendência foi resolvida:')
    if (!text || !text.trim()) return false
    try {
      await closePendency({ variables: { id, text } })
      refetch()
    } catch(error) {
      console.error(error)
    }
  }

  const handleAddConversation = async id => {
    const text = prompt('Digite o comentário:')
    if (!text || !text.trim()) return false
    try {
      await addConversation({ variables: { id, text } })
      refetch()
    } catch(error) {
      console.error(error)
    }
  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Pendências</SubTitle></Flex>
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        {pendencies.map(item => (
          <Box mb={5} key={item.id}>
            <Box mr={2} mb={1}><FormatDateTime locale='pt'>{item.createdAt}</FormatDateTime> - <strong>Criada</strong> por {humanBy(item)}</Box>
            <Box mb={3}>Motivo: {item.description}</Box>
            <Box ml={3}>
              {item.conversation.map((c, index) => (
                <Box mb={3} key={index}>
                  <Box mb={1}><FormatDateTime locale='pt'>{c.at}</FormatDateTime> - <strong>Comentada</strong> por {humanBy(c)}</Box>
                  <Box>{c.text}</Box>
                </Box>
              ))}
              {item.closedAt && (
                <>
                  <Box><FormatDateTime locale='pt'>{item.closedAt}</FormatDateTime> - <strong>Resolvida</strong> por {humanBy(item, true)}</Box>
                  {item.closeInfo && item.closeInfo.text && (<Box mt={2}>{item.closeInfo.text}</Box>)}
                </>
              )}
            </Box>
            {!item.closedAt && (
              <Flex mt={3}>
                <Box mr={3}>
                  <Button onClick={() => handleAddConversation(item.id)}>Adicionar comentário</Button>
                </Box>
                <Box>
                  <Button onClick={() => handleClosePendency(item.id)}>Resolver pendência</Button>
                </Box>
              </Flex>
            )}
          </Box>
        ))}

      </Box>
    </Flex>
  )
}

const PendenciesQuery = ({ id, onRequestClose }) => {
  const { loading, error, data, refetch } = useQuery(LIST, { variables: { OrderId: id }, fetchPolicy: 'cache-and-network' })
  if (loading) return <Spin />
  if (error) return error.message
  return <Pendencies data={data} refetch={refetch} onRequestClose={onRequestClose} />
}

export default PendenciesQuery
