import React from 'react'
import { gql, useQuery } from '@apollo/client'
import Spin from './Spin'

export const ME = gql`
  query {
    me {
      id
      name
      email
      role
      gender
      preferences
    }
  }
`

const WithMe = ({ children }) => {
  const { loading, error, data } = useQuery(ME)
  if (loading) return <Spin />
  if (error) return error.message
  if (process.env.NODE_ENV === 'production' && data.me && window && window.analytics && window.analytics.user) {
    window.analytics.user.set({ userId: data.me.id, displayName: data.me.name, email: data.me.email })
  }
  return children({ data })
}

export default WithMe
