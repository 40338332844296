import React, { useState, useRef, useEffect } from 'react'
import { lighten } from 'polished'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { useMergeRefs } from 'use-callback-ref'
import { useSpring, a } from 'react-spring'
import { createPortal } from 'react-dom'

const Portal = ({ children }) => createPortal(children, document.getElementById('portal-root'))

const PopperContainer = styled.div`

  padding-top: 8px;
  box-shadow: 0px 2px 8px hsl(0 0% 0% / 0.15);
  font-size: 13px;
  background-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? lighten(0.1, props.theme.colors.grey200) : 'hsla(0, 0%, 90%, 1)'};
  border-radius: 4px;

  /* text-align: center; */

  /* box-shadow: hsl(0 0% 0% / 0.2) 0px 4px 24px; */
  /* max-width: 640px; */
  /* font-size: 13px; */
  /* color: hsl(0 0% 84% / 1); */
  /* background: linear-gradient(136.61deg, hsl(240 5% 20% / 1) 13.72%, hsl(240 8% 18% / 1) 74.3%); */
  /* border-radius: 8px; */

  z-index: 2001;

  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;

  min-width: 240px;
  max-width: 420px;
  max-height: calc(100vh - 8px);

  overflow: scroll;

  /* padding: 4px 0px;
  font-size: 13px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 24px;
  background: linear-gradient(136.61deg, rgb(49, 49, 54) 13.72%, rgb(43, 43, 50) 74.3%);
  color: rgb(214, 214, 214); */
`

const ContextMenu = ({ children, render, placement = 'right-start' }) => {

  const ref = useRef()

  const [open, setOpen] = useState(false)

  // const [referenceElement, setReferenceElement] = useState(null)
  const [, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  // const [arrowElement, setArrowElement] = useState(null)
  const [arrowElement] = useState(null)

  const [xy, setXy] = useState([0, 0])

  const virtualReference = React.useMemo(() => ({
    getBoundingClientRect() {
      return {
        top: xy[1],
        left: xy[0],
        bottom: xy[1],
        right: xy[0],
        width: 0,
        height: 0,
      }
    },
  }), [xy])

  const { styles, attributes } = usePopper(virtualReference, popperElement, {
    placement,
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }, { name: 'offset', options: { offset: [0, 4] } }],
  })

  const springStyle = useSpring(open ? { opacity: 1, immediate: true, config: { duration: 100 } } : { opacity: 0, immediate: true, config: { duration: 100 } })

  const wrapperRef = useRef(null)

  const handleContextMenu = e => {
    e.preventDefault()
    setXy([e.clientX, e.clientY])
    setOpen(true)
    // if (ref.current.contains(e.target)) return setOpen(current => !current)
  }

  const handleClick = e => {
    if (e.target.id === 'MenuItem') return setTimeout(() => { setOpen(false) }, 100)
    // if (ref.current.contains(e.target)) return setOpen(current => !current)
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) return setOpen(false)
  }

  useEffect(() => {
    // https://codesandbox.io/s/outside-alerter-hooks-lmr2y
    document.addEventListener('click', handleClick)
    // document.addEventListener('contextmenu', handleContextMenu)
    return () => {
      document.removeEventListener('click', handleClick)
      // document.removeEventListener('contextmenu', handleContextMenu)
    }
  }, [])

  return (
    <>
      {React.cloneElement(children, { ref: useMergeRefs([ref, setReferenceElement]), onContextMenu: handleContextMenu })}
      {/* <div ref={useMergeRefs([ref, setReferenceElement])} onContextMenu={handleContextMenu}>{children}</div> */}
      {open &&
        <Portal>
          <a.div style={springStyle}>
            <div ref={wrapperRef}>
              <PopperContainer ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                {/* <div ref={setArrowElement} style={styles.arrow} id='arrow' /> */}
                {render({ onRequestClose: () => setOpen(false) })}
              </PopperContainer>
            </div>
          </a.div>
          <div style={{ position: 'fixed', zIndex: 2000, top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'transparent', outline: 'none', tabIndex: -1 }} />
        </Portal>
      }
    </>
  )

}

export default ContextMenu
