import React from 'react'
import { Formik } from 'formik'
import { gql, useMutation } from '@apollo/client'

import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ModalHeader from '../../components/ModalHeader'
import { SubTitle } from '../../components/Title'
import yup from '../../validation'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

const LIST = gql`
  query {
    studies {
      id
      PatientName
    }
  }
`

const CREATE = gql`
  mutation ($PatientName: String!) {
    createStudy(PatientName: $PatientName) {
      id
      PatientName
    }
  }
`

const validationSchema = yup.object().shape({
  PatientName: yup.string().required(),
})

function New({ onRequestClose }) {

  const { message } = useMessageContext()
  const [createStudy] = useMutation(CREATE)

  const initialValues = { PatientName: '' }

  const handleSubmit = async (variables, actions) => {

    const update = (cache, { data: { createStudy } }) => {
      const cacheList = cache.readQuery({ query: LIST })
      cache.writeQuery({ query: LIST, data: { ...cacheList, studies: [createStudy, ...cacheList.studies] } })
    }

    try {
      await createStudy({ variables, update })
      message(`Estudo criado com sucesso!`)
      actions.resetForm()
    } catch(error) {
      actions.setSubmitting(false)
      console.error(error)
      message(error.message.replace('GraphQL error: ', ''))
    }

  }

  return (
    <Flex flexDirection='column' width='100%'>
      <ModalHeader>
        <Flex><SubTitle>Novo Estudo</SubTitle></Flex>
        <Flex cursor='pointer' onClick={onRequestClose}><CloseIcon /></Flex>
      </ModalHeader>
      <Box p={4} overflow='auto'>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>

      </Box>
    </Flex>
  )
}

export default New
